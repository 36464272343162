import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useQuery, useQueries } from 'react-query';
import axios from './api/setup';

import { getAccessToken } from './api/auth';
import { SensorContext, JsonTabContext } from './utils/context';
import Sidebar from './components/Sidebar';
import Mobilebar from './components/Mobilebar';
import Header from './components/Header';
import Login from './pages/Login';
import getRoutes from './routes';
import useJsonTab from './hooks/useJsonTab';

import { randomIntBetween } from './utils/functions';

const Layout = styled.div`
  flex: 1;
  display: flex;
`;

const Page = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.background};
`;

function App() {
  const [isAuth, setIsAuth] = useState(
    () => Boolean(getAccessToken()) || false
  );
  const [startSensorArray, setStartSensorArray] = useState([]);
  const [sensorId, setSensorId] = useState(null);
  const foundSensors = useRef([]);

  useEffect(() => {
    if (isAuth) {
      // Reset found sensors and current sensor
      foundSensors.current = [];
      setSensorId(null);
    }
  }, [isAuth]);

  const allSensors = useQuery('getAllSensors', () => axios.get('/sensors'), {
    enabled: isAuth,
  });

  const currentSensor = useQuery(
    ['getSingleSensor', sensorId],
    () => axios.get(`/sensors/${sensorId}`),
    {
      enabled: Boolean(sensorId),
      retry: 0,
    }
  );

  const querySensors = useQueries(
    startSensorArray.map((user, index) => {
      return {
        queryKey: ['getStartSensors', startSensorArray[index]],
        queryFn: () =>
          axios.get(`/sensors/${startSensorArray[index]}`).then((response) => {
            const shouldAddSensor = 
              response?.data?.device_type !== 'LOW_RESOLUTION_METER'
              ? response?.data?.connection_state === 'connected' : true;

            if (!sensorId &&
              shouldAddSensor &&
              !foundSensors.current?.includes(startSensorArray[index]) // No duplicates
            ) {
              foundSensors.current = [...foundSensors.current, startSensorArray[index]];
            }
          }),
        enabled: startSensorArray.length > 0 && !sensorId,
        retry: 0,
      };
    })
  );

  const [isMobilebarOpen, setIsMobilebarOpen] = useState(false);
  const openMobilebar = () => {
    setIsMobilebarOpen(true);
  };
  const closeMobilebar = () => {
    setIsMobilebarOpen(false);
  };

  const resetSensorId = () => {
    setSensorId(null);
  }

  const [isJsonTabOpen, handleOpenCloseJsonTab] = useJsonTab(true);

  const location = useLocation();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const allSensorsData = allSensors && allSensors.data && allSensors.data.data;
  useEffect(() => {
    if (allSensorsData && !sensorId) {
      const sensorIdArray = getTenRandomSensorIds(allSensorsData);
      setStartSensorArray(sensorIdArray);
    }
  }, [allSensorsData, sensorId]);

  const getTenRandomSensorIds = (sensorIds) => {
    const sensorIdArray = [];
    for (let count = 0; count < 10; count++) {
      const sensorId = sensorIds[Math.floor(Math.random() * sensorIds.length)];
      sensorIdArray.push(sensorId);
    }
    return sensorIdArray;
  };

  const allFinished = querySensors.every(query => !query.isLoading);

  useEffect(() => {
    if (allFinished && !sensorId) {
      if (foundSensors?.current?.length) {
        const sensorCode = randomIntBetween(0, foundSensors.current.length-1);
        setSensorId(foundSensors.current[sensorCode]);
      }
    }
  }, [allFinished, sensorId]);

  return (
    <Layout>
      <SensorContext.Provider
        value={{
          id: sensorId,
          body: currentSensor,
          isoLocaleCode:
            currentSensor.data && currentSensor.data.data.locale.isoLocaleCode,
          timeZoneId:
            currentSensor.data && currentSensor.data.data.locale.timeZoneId,
          type: currentSensor.data?.data?.device_type,
        }}
      >
        {location.pathname !== '/login' && sm && <Sidebar />}
        {location.pathname !== '/login' && !sm && (
          <Mobilebar
            isMobilebarOpen={isMobilebarOpen}
            closeMobilebar={closeMobilebar}
            isLoading={allSensors.isLoading}
          />
        )}
        <JsonTabContext.Provider
          value={{ isJsonTabOpen, handleOpenCloseJsonTab }}
        >
          <Page>
            {location.pathname !== '/login' && (
              <Header
                openMobilebar={openMobilebar}
                sensors={allSensors.data && allSensors.data.data}
                selectSensor={setSensorId}
                resetSensor={resetSensorId}
                searching={!allFinished && !sensorId}
                sensorId={sensorId}
              />
            )}
            <Switch>
              <Route
                path="/login"
                render={(props) => (
                  <Login {...props} isAuth={isAuth} setIsAuth={setIsAuth} />
                )}
              />
              {isAuth && getRoutes(allSensors).map((route) => route)}
              <Redirect to="/login" />
            </Switch>
          </Page>
        </JsonTabContext.Provider>
      </SensorContext.Provider>
    </Layout>
  );
}

export default App;
