import { useEffect, useMemo } from 'react';
import { gaugeChart } from 'gauge-chart';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

const colors = ['#56ADD9', '#F1A000', '#CC0000'];

const StyledPaper = styled(Paper)`
  padding: 10px;
  height: 176px;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

function Chart({ value, percentage, thresholds }) {
  const CHART_WIDTH = 300;

  const options = useMemo(() => {
    return {
      hasNeedle: true,
      needleColor: 'gray',
      arcColors: colors,
      needleUpdateSpeed: 0,
      arcPadding: 4,
      arcPaddingColor: 'white',
      arcLabelFontSize: 13,
      rangeLabelFontSize: 15,

      arcDelimiters: [
        (thresholds[1] / thresholds[3]) * 100,
        (thresholds[2] / thresholds[3]) * 100,
      ],
      arcLabels: [thresholds[1].toString(), thresholds[2].toString()],
      rangeLabel: ['0', thresholds[3].toString()],
      centralLabel: `${value} W`,
    };
  }, [thresholds, value]);

  useEffect(() => {
    const gaugeChartRef = document.querySelector('#gaugeArea');
    if (gaugeChartRef) {
      gaugeChart(gaugeChartRef, CHART_WIDTH, options).updateNeedle(percentage);
    }

    return () => (gaugeChartRef.innerHTML = null);
  }, [options, percentage]);

  return (
    <StyledPaper>
      <div id="gaugeArea"></div>
    </StyledPaper>
  );
}

Chart.propTypes = {
  value: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  thresholds: PropTypes.array.isRequired,
};

export default Chart;
