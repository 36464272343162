import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRounded from '@material-ui/icons/ArrowForwardRounded';

function DateArrow({
  dir,
  disabled = false,
  onClick,
  color = 'rgba(0, 0, 0, 0.54)'
}) {
  return (
    <IconButton className="noprint" aria-label={`date-${dir === 'backward' ? 'retreat' : 'advance'}`} disabled={disabled} onClick={onClick}>
      {dir === 'backward'
        ? <ArrowBackRounded htmlColor={disabled ? 'inherit' : color} />
        : <ArrowForwardRounded htmlColor={disabled ? 'inherit' : color} />
      }
    </IconButton>
  )
}

DateArrow.propTypes = {
  dir: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default DateArrow;
