import { useState, useEffect, useCallback } from 'react';

function useJsonTab(initialValue = true) {
  const [isJsonTabOpen, setIsJsonTabOpen] = useState(
    localStorage.getItem('jsontab')
      ? JSON.parse(localStorage.getItem('jsontab'))
      : true
  );

  const handleOpenCloseJsonTab = () => {
    setIsJsonTabOpen(!isJsonTabOpen);
  };

  const persistJsonTabState = useCallback(() => {
    localStorage.setItem('jsontab', JSON.stringify(isJsonTabOpen));
  }, [isJsonTabOpen]);

  useEffect(() => {
    window.addEventListener('beforeunload', persistJsonTabState);

    return () => {
      window.removeEventListener('beforeunload', persistJsonTabState);
    };
  }, [persistJsonTabState]);

  return [isJsonTabOpen, handleOpenCloseJsonTab];
}

export default useJsonTab;
