import dissatisfiedIcon from '../../images/scores/dissatisfied.png';
import neutralIcon from '../../images/scores/neutral.png';
import satisfiedIcon from '../../images/scores/satisfied.png';
import unavailableIcon from '../../images/scores/unavailable.png';

import speedometer0Img from '../../images/scores/speedometer-0.png';
import speedometer12Img from '../../images/scores/speedometer-12,5.png';
import speedometer24Img from '../../images/scores/speedometer-24.png';
import speedometer25Img from '../../images/scores/speedometer-25.png';
import speedometer50Img from '../../images/scores/speedometer-50.png';
import speedometer74Img from '../../images/scores/speedometer-74.png';
import speedometer75Img from '../../images/scores/speedometer-75.png';
import speedometer87Img from '../../images/scores/speedometer-87,5.png';
import speedometer100Img from '../../images/scores/speedometer-100.png';

function scoreColor(score) {
  if (score === undefined) {
    return '#B9B9B9';
  }

  if (score > 24) {
    if (score > 74) {
      return '#6AB42D';
    }

    return  '#FF7300';
  }

  return '#E30100';
}

function scoreIcon(score) {
  if (score === undefined) {
    return unavailableIcon;
  }

  if (score > 24) {
    if (score > 74) {
      return satisfiedIcon;
    }

    return neutralIcon;
  }

  return dissatisfiedIcon;
}

function speedometerImg(score) {
  // Low
  if (score < 25) {
    if (score <= 17) {
      if (score <= 9) {
        return speedometer0Img;
      }
      return speedometer12Img;
    }
    return speedometer24Img;
  }

  // Mid
  if (score < 75) {
    if (score <= 59) {
      if (score <= 43) {
        return speedometer25Img;
      }
      return speedometer50Img;
    }
    return speedometer74Img;
  }

  // High
  if (score <= 92) {
    if (score <= 84) {
      return speedometer75Img;
    }
    return speedometer87Img;
  }

  return speedometer100Img;
}

function uniqueRecommendations(recommendations) {
  if (recommendations) {
    const unique = recommendations.filter((obj, index) => {
      return (
        index === recommendations.findIndex((o) => obj.recommendation_key === o.recommendation_key) &&
        obj.recommendation_key !== 'NO_RECOMMENDATION'
      );
    });
    return unique;
  }

  return null;
}

export { scoreIcon, scoreColor, speedometerImg, uniqueRecommendations };
