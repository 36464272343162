import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

const StyledPaper = styled(Paper)`
  margin: ${({ margin }) => `${margin?.vertical ?? 0}px ${margin?.horizontal ?? 0}px`};
  ${({ margin }) => margin?.top !== undefined && `margin-top: ${margin.top}px`};
  ${({ margin }) => margin?.right !== undefined && `margin-right: ${margin.right}px`};
  ${({ margin }) => margin?.bottom !== undefined && ` margin-bottom: ${margin.bottom}px`};
  ${({ margin }) => margin?.left !== undefined && `margin-left: ${margin.left}px`};

  @media print {
    margin-top: 5px;
  }
`;

function PaperWrapper({ children, margin }) {
  return (
    <StyledPaper margin={margin}>
      {children}
    </StyledPaper>
  )
}

PaperWrapper.propTypes = {
  margin: PropTypes.shape({
    horizontal: PropTypes.number,
    vertical: PropTypes.number,
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
};

export default PaperWrapper;
