import React from 'react';
import PropTypes from 'prop-types';

import { brightenColor, labelDirection, shouldLowerYPosition } from './utils';

function HighlightBar({
  highlights,
  ...props
}) {
  const { fill, x, y, width, height, payload } = props; // Bar props
  const { key, values, brightness } = highlights;

  const fillColor = brightness ? brightenColor(payload[brightness.key], fill, brightness) : fill;

  // Should we highlight this key based on payload?
  const shouldHighlightThisKey = key && payload[key];
  const {
    color: highlightColor,
    icon: highlightIcon
  } = values?.[shouldHighlightThisKey] || {}; // Both either undefined or valid

  if (highlightIcon) {
    const { index } = props;
    const { indices } = highlights;
    const labelDir = labelDirection(index, indices);

    const thinBarWidth = width*0.12;
    const rectXPosition = (index > 0 || indices.total < 10) ? x + thinBarWidth : x;
    const thinBar = {
      x: (index > 0 || indices.total < 10) ? x + thinBarWidth/2 : x + width - thinBarWidth,
      width: thinBarWidth,
    };
    
    const icon = {
      x: thinBar.x - highlightIcon.size/2 + (highlightIcon?.offset?.x || 0),
      y: highlightIcon.size + (highlightIcon?.offset?.y || 0),
    };

    const labelFontSize = Math.min(indices.total < 10 ? 11 : width*(indices.total*0.15)/6.5, 12);
    const labelLines = highlightIcon?.label?.split('\n');
    const label = {
      lines: labelLines,
      
      x: labelDir !== 'none' 
      ? (labelDir === 'right' ? thinBar.x - 4 + thinBar.width :  thinBar.x - 14 + thinBar.width)
      : icon.x - labelLines?.length * 2,
      y: icon.y + highlightIcon.size*1.7 + (shouldLowerYPosition(index, indices, labelDir) ? 50 : 0),
      
      fontSize: labelFontSize,
      shouldAppear: indices.total < 10 ? width >= 70 : (labelDir !== 'none' && labelFontSize > 8),
      direction: labelDir === 'left' ? 'rtl' : 'ltr',
    };

    return (
      <svg>
        {/* Regular bar + thin bar + icon + label */}
        <rect x={rectXPosition} y={y} width={width - thinBar.width} height={height} stroke="none" fill={highlightColor || fillColor}/>
        <line x1={thinBar.x} y1={y + height} x2={thinBar.x} y2={highlightIcon.size*1.8} stroke={highlightColor || fillColor} strokeWidth={thinBar.width}/>
        
        <svg x={icon.x} y={icon.y}>
          {highlightIcon.element}
        </svg>

        {highlightIcon.label && label.shouldAppear && (
          <text
            fontSize={label.fontSize}
            fontWeight={500}
            fill={'#222'}
            direction={label.direction}
          >
            {label.lines.map((text, index) => (
              <tspan
                key={`icon-label-${index}`}
                x={label.x}
                y={label.y + (index*14)}
              >
                {`⠀${text}`}
              </tspan>
            ))}
          </text>
        )}
      </svg>
    )
  }

  return (
    <svg>
      <rect x={x} y={y} width={width} height={height} stroke="none" fill={highlightColor || fillColor}/>
    </svg>
  );
};

HighlightBar.propTypes = {
  highlights: PropTypes.shape({
    key: PropTypes.string,
    indices: PropTypes.shape({
      total: PropTypes.number.isRequired,
      indicesApart: PropTypes.number.isRequired,
      minIndex: PropTypes.number.isRequired,
      maxIndex: PropTypes.number.isRequired,
    }),
    values: PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({
        color: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          size: PropTypes.number.isRequired,
          element: PropTypes.elementType.isRequired,
          offset: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
          }),
          label: PropTypes.string,
        }),
      }),
    }),
    // Brightness scale (optional)
    brightness: PropTypes.shape({
      key: PropTypes.string.isRequired,
      min: PropTypes.number,
      max: PropTypes.number.isRequired,
      mode: PropTypes.string,
      maxFactor: PropTypes.number,
    }),
  }),
  props: PropTypes.object,
};

export default HighlightBar;
