import { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { startOfDay } from 'date-fns';
import CurrencyFormatter from 'currency-formatter';

import { SensorContext } from '../../utils/context';
import usePageUnavailable from '../../hooks/usePageUnavailable';
import Unavailable from '../../components/Unavailable';
import ContentWrapper from '../../components/ContentWrapper';
import DateArrow from '../../components/DateArrow';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import DatePicker from '../../components/DatePicker';
import Card from '../../components/Card';
import ApplianceBars from '../../components/ApplianceBars';
import CenteredLoader from '../../components/CenteredLoader';
import {
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  MINIMUM_DATE_ERROR_MESSAGE,
  DISAGGREGATION_YESTERDAY_ERROR_MESSAGE,
  SENSOR_ID_PLACEHOLDER,
  NO_DATA_FOUND_ERROR_MESSAGE,
  APPLIANCES,
} from '../../utils/constants';
import { sortAppliancesDescending } from '../../utils/functions';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const CardContainer = styled(Grid)`
  margin-bottom: 10px;
`;

function DisaggregationDay() {
  const sensor = useContext(SensorContext);
  const isPageUnavailable = usePageUnavailable();
  const [selectedDate, handleDateChange] = useState(
    moment().toDate()
  );
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const sensorId = sensor.id || SENSOR_ID_PLACEHOLDER;
  const formattedDate = moment(selectedDate).format('yyyy-MM-DD');

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    if (
      startOfDay(selectedDate) >
      startOfDay(moment().toDate())
    ) {
      setIsValid(false);
      return setValidationError(DISAGGREGATION_YESTERDAY_ERROR_MESSAGE);
    }

    if (
      sensor.body &&
      sensor.body.data &&
      sensor.body.data.data.first_event &&
      startOfDay(selectedDate) <
        startOfDay(moment(sensor.body.data.data.first_event).toDate())
    ) {
      setIsValid(false);
      return setValidationError(
        MINIMUM_DATE_ERROR_MESSAGE(
          moment(sensor.body.data.data.first_event).format('DD MMM yyyy')
        )
      );
    }

    setIsValid(true);
    return setValidationError('');
  }, [selectedDate, sensor.id, sensor]);

  const response = useQuery(
    ['disaggregationDay', sensorId, formattedDate],
    () => axios.get(`/sensors/${sensorId}/disag/day?date=${formattedDate}`),
    {
      enabled: isValid,
      retry: 0,
    }
  );

  const getData = (data) => {
    const keys = Object.keys(data && data.data && data.data.consumption);
    const consumption = Object.values(
      data && data.data && data.data.consumption
    );
    const cost = Object.values(data && data.data && data.data.cost);
    const consumptionPercentage = Object.values(
      data && data.data && data.data.consumption_percentage
    );
    const costPercentage = Object.values(
      data && data.data && data.data.cost_percentage
    );

    const dataArray = keys.map((item, index) => {
      return {
        title: APPLIANCES[keys[index]].label,
        color: APPLIANCES[keys[index]].color,
        consumption: consumption[index],
        consumptionPercentage: consumptionPercentage[index],
        cost: cost[index],
        costPercentage: costPercentage[index],
      };
    });

    return sortAppliancesDescending(dataArray);
  };

  const disableForward = moment().isSame(selectedDate, 'day');

  const updateDate = (dir) => {
    if (dir === 'backwards') {
      handleDateChange(old => moment(old).subtract(1, 'day').toDate());
    } else {
      handleDateChange(old => moment(old).add(1, 'day').toDate());
    }
  }

  if (isValid && isPageUnavailable) {
    return <Unavailable pageName={isPageUnavailable} />
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle
          filter={
            <div>
              <DateArrow
                dir='backward'
                disabled={response.isLoading}
                onClick={() => updateDate('backwards')}
              />
              <DatePicker
                autoOk
                id="date-picker"
                label="Date"
                value={selectedDate}
                onChange={handleDateChange}
                disableFuture
                variant="inline"
                format="dd MMM yyyy"
                error={Boolean(validationError)}
                last="true"
              />
              <DateArrow
                dir='forward'
                disabled={response.isLoading || disableForward}
                onClick={() => updateDate('forwards')}
              />
            </div>
          }
        >
          Disaggregation by day
        </PageTitle>
        <Typography paragraph>
          Returns the total consumption by appliances for a specific day.
        </Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {!validationError && response.isSuccess && (
          <CardContainer container spacing={2}>
            <Card
              xs={12}
              sm={6}
              title="Consumption"
              data={`${new Intl.NumberFormat(sensor.isoLocaleCode).format(
                response.data.data.total_consumption
              )} kWh`}
            />
            <Card
              xs={12}
              sm={6}
              title="Cost"
              data={CurrencyFormatter.format(response.data.data.total_cost, {
                locale: sensor.isoLocaleCode,
              })}
            />
          </CardContainer>
        )}
        {!validationError && response.isSuccess && (
          <ApplianceBars data={getData(response.data)} />
        )}
        {response.isError && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${sensorId}/disag/day?date=${formattedDate}`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default DisaggregationDay;
