import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import StraightenRoundedIcon from '@material-ui/icons/StraightenRounded';
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded';
import HotelRoundedIcon from '@material-ui/icons/HotelRounded';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import WavesIcon from '@material-ui/icons/Waves';
import BatteryCharging80RoundedIcon from '@material-ui/icons/BatteryCharging80Rounded';
import DisabledIcon from '@material-ui/icons/CancelRounded';
import LoopIcon from '@material-ui/icons/LoopRounded';

import { NO_DATA_FOUND_ERROR_MESSAGE } from '../../utils/constants';
import { SensorContext } from '../../utils/context';
import ContentWrapper from '../../components/ContentWrapper';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import { SENSOR_ID_PLACEHOLDER, AGE_RANGES } from '../../utils/constants';
import { capitalizeFirstLetter } from '../../utils/functions';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

const Detail = styled.div`
  display: flex;
  flex-direction: ${props => props.column ? 'column' : 'row'};
  padding: ${props => props.noSpace ? 0 : '4px 0'};

  > svg {
    margin-right: 4px;
  }
`;

const SubDetail = styled(Detail)`
  padding-left: 20px;
`;

const Options = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding: 0 8px;
  border: 1px solid #bbb;
  border-radius: 8px;
`;

const EnergyOptions = styled(Options)`
  margin-left: 0;

  @media screen and (max-width: 1035px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HomeOptions = styled(Options)`
  padding: 0 12px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
`;

const HomeOption = styled(Option)`
  margin-right: 8px;
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.selected ? '#36873a' : '#eee'};
  border: 1px solid ${props => props.selected ? '#27632a' : '#ccc'};
`;

const OptionLabel = styled(Typography)`
  color: ${props => props.selected ? 'inherit' : '#999'};
  font-size: 0.9rem;
`;

const HomeTypeLabel = styled(OptionLabel)`
  margin-left: 4px;
`;

const Ruler = styled(StraightenRoundedIcon)`
  transform: rotate(90deg);
`;

const icons = {
  'HOME': <HomeRoundedIcon />,
  'COMMERCIAL': <WorkRoundedIcon />,
  'OFFICE': <WorkRoundedIcon />,
  'INDUSTRY': <HomeWorkIcon />,
};

function PropertyDetails() {
  const sensor = useContext(SensorContext);
  const [propertyDetails, setPropertyDetails] = useState(null);

  const response = useQuery(
    ['propertyDetails', sensor.id],
    () => axios.get(`/sensors/${id}/metadata/property`),
    {
      enabled: Boolean(sensor.id),
      retry: 0,
      onSuccess: response => {
        if (!response.data) return setPropertyDetails(null);

        setPropertyDetails(response.data);
      }
    }
  );

  const id = sensor.id || SENSOR_ID_PLACEHOLDER;
  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle>View property details</PageTitle>
        {response.isError && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
        {response.isSuccess && propertyDetails && (
          <Details>
            <Detail>
              {icons[propertyDetails.property_type]}
              <Typography data-testid="property-type-label">{capitalizeFirstLetter(propertyDetails.property_type)}</Typography>
              {propertyDetails.property_type === 'HOME' && (
                <HomeOptions>
                  <HomeOption>
                    <Circle selected={propertyDetails.home_type === 'HOUSE'} />
                    <HomeTypeLabel selected={propertyDetails.home_type === 'HOUSE'}>House</HomeTypeLabel>
                  </HomeOption>
                  <HomeOption>
                    <Circle selected={propertyDetails.home_type === 'FLAT'} />
                    <HomeTypeLabel selected={propertyDetails.home_type === 'FLAT'}>Flat</HomeTypeLabel>
                  </HomeOption>
                </HomeOptions>
              )}
            </Detail>
            {propertyDetails.home_usage_type !== undefined && (
              <Detail>
                <LoopIcon />
                <Typography>{ capitalizeFirstLetter(propertyDetails.home_usage_type) }</Typography>
              </Detail>
            )}
            {propertyDetails.home_size !== undefined && (
              <Detail>
                <Ruler />
                <Typography>{ propertyDetails.home_size } m²</Typography>
              </Detail>
            )}
            {propertyDetails.people_amount !== undefined && (
              <Detail column>
                <Detail noSpace>
                  <PeopleOutlineRoundedIcon />
                  <Typography>{ propertyDetails.people_amount } people</Typography>
                </Detail>
                {propertyDetails.people
                  .filter(detail => detail.amount > 0)
                  .map((detail) => 
                    <SubDetail key={`age-range-${detail.age_range}`}>
                      {AGE_RANGES[detail.age_range].icon} {detail.amount} {AGE_RANGES[detail.age_range].label}
                    </SubDetail>
                  )
                }
              </Detail>
            )}
            {propertyDetails.people_amount_in_day_time !== undefined && (
              <Detail column>
                <Detail noSpace>
                  <PeopleOutlineRoundedIcon />
                  <Typography>{ propertyDetails.people_amount_in_day_time } people during the day</Typography>
                </Detail>
              </Detail>
            )}
            {propertyDetails.bedroom_amount !== undefined && (
              <Detail>
                <HotelRoundedIcon />
                <Typography>{ propertyDetails.bedroom_amount} bedrooms</Typography>
              </Detail>
            )}
            {propertyDetails.power_generator_source !== undefined && (
              <>
                <Detail>
                  <FlashOnIcon />
                  <Typography>Generates power</Typography>
                </Detail>
                <Detail noSpace={true}>
                  <EnergyOptions>
                    <Option>
                      <WbSunnyIcon htmlColor={propertyDetails.power_generator_source === 'SOLAR' ? '#199fbd' : '#aaa'} style={{ transform: 'scale(0.7)'}}  />
                      <OptionLabel selected={propertyDetails.power_generator_source === 'SOLAR'}>Solar</OptionLabel>
                    </Option>
                    <Option>
                      <InvertColorsIcon htmlColor={propertyDetails.power_generator_source === 'HYDROELECTRICITY' ? '#199fbd' : '#aaa'} style={{ transform: 'scale(0.7)'}}  />
                      <OptionLabel selected={propertyDetails.power_generator_source === 'HYDROELECTRICITY'}>Hydroelectricity</OptionLabel>
                    </Option>
                    <Option>
                      <WavesIcon htmlColor={propertyDetails.power_generator_source === 'WIND' ? '#199fbd' : '#aaa'} style={{ transform: 'scale(0.7)'}}  />
                      <OptionLabel selected={propertyDetails.power_generator_source === 'WIND'}>Wind</OptionLabel>
                    </Option>
                    <Option>
                      <BatteryCharging80RoundedIcon htmlColor={propertyDetails.power_generator_source === 'OTHER' ? '#199fbd' : '#aaa'} style={{ transform: 'scale(0.7)'}}  />
                      <OptionLabel selected={propertyDetails.power_generator_source === 'OTHER'}>Other</OptionLabel>
                    </Option>
                    <Option>
                      <DisabledIcon htmlColor={propertyDetails.power_generator_source === 'NONE' ? '#199fbd' : '#aaa'} style={{ transform: 'scale(0.7)'}}  />
                      <OptionLabel selected={propertyDetails.power_generator_source === 'NONE'}>No</OptionLabel>
                    </Option>
                  </EnergyOptions>
                </Detail>
              </>
            )}
          </Details>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${id}/metadata/property`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default PropertyDetails;
