import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ContentWrapper from '../../components/ContentWrapper';
import PageTitle from '../../components/PageTitle';

const StyledListItem = styled(ListItem)`
  padding-top: 0;
  padding-bottom: 0;
`;

function Disaggregation() {
  return (
    <ContentWrapper>
      <PageTitle>Disaggregation</PageTitle>
      <Typography>
        Here are the appliances or family of appliances we are able to
        disaggregate so far:
      </Typography>
      <List>
        <StyledListItem dense>
          <ListItemText primary="- Air conditioner" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Dishwasher" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Electric boiler" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Electric heating" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Electric oven" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Electric shower" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Electric stove" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Fridge" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Freezer" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Tumble dryer" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Washing machine" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Kettle" />
        </StyledListItem>
      </List>
      <Typography paragraph>
        Although, depending on the appliances you have at home, some appliances
        that have a very similar electrical signature might be merged in the
        report. For instance, electric oven and electric stove are displayed as
        cooking.
      </Typography>
      <Typography>
        Depending on whether the user fills out the information page inside of
        the app or through the monthly report or not we will display the
        appliances in the following way:
      </Typography>
      <List>
        <StyledListItem dense>
          <ListItemText primary="- Air conditioner" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Cooking" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Heating" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Dishwasher" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Electric boiler" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Electric shower" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Fridge &amp; freezer" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Tumble dryer" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Kettle" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText
            primary="- Standby: this model represents all the appliances that are always on
          or that have a standby mode (computer monitors, Wifi, etc.)"
          />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText
            primary="- Lighting &amp; entertainment: this model represents the lights of a
            house and some appliances like TV"
          />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText primary="- Washing machine" />
        </StyledListItem>
        <StyledListItem dense>
          <ListItemText
            primary="- Others: this model represents the consumption that cannot be
          associated to a proper model"
          />
        </StyledListItem>
      </List>
      <Typography>
        Appliances with consumption percentage smaller than 1% in the specified
        period will NOT be shown in the returned results (their consumption will
        be computed in the "others" category). For this reason, some appliances
        estimations may diverge slightly depending on the date range the results
        were retrieved (i.e. querying every day of a month individually may
        diverge slightly from retrieving the results of the whole month at
        once).
      </Typography>
    </ContentWrapper>
  );
}

export default Disaggregation;
