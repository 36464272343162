import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const StyledPaper = styled(Paper)`
  min-height: 120px;
  padding: 15px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledData = styled(Typography)`
  color: #388e3c;
  align-self: center;
`;

const StyledBlank = styled.div`
  height: 23px;
`;

const StyledDetails = styled(Typography)`
  align-self: flex-end;
`;

function Card({ title, data, details = null, ...props }) {
  return (
    <Grid item {...props}>
      <StyledPaper>
        <Typography>{title}</Typography>
        <StyledData variant="h4">{data}</StyledData>
        {details ? <StyledDetails>{details}</StyledDetails> : <StyledBlank />}
      </StyledPaper>
    </Grid>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  details: PropTypes.string,
};

export default Card;
