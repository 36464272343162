import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const StyledCircularProgress = styled(CircularProgress)`
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
`;

export default StyledCircularProgress;
