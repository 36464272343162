import { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { startOfDay } from 'date-fns';
import CurrencyFormatter from 'currency-formatter';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { SensorContext } from '../../utils/context';
import usePageUnavailable from '../../hooks/usePageUnavailable';
import Unavailable from '../../components/Unavailable';
import ContentWrapper from '../../components/ContentWrapper';
import DateArrow from '../../components/DateArrow';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import DatePicker from '../../components/DatePicker';
import Graph from '../../components/Graph';
import CenteredLoader from '../../components/CenteredLoader';
import {
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  MINIMUM_DATE_ERROR_MESSAGE,
  DISAGGREGATION_YESTERDAY_ERROR_MESSAGE,
  SENSOR_ID_PLACEHOLDER,
  NO_DATA_FOUND_ERROR_MESSAGE,
  APPLIANCES,
} from '../../utils/constants';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const StyledSelect = styled(Select)`
  width: 100px;
  margin-bottom: 10px;
`;

function DisaggregationThirtyMinutes() {
  const sensor = useContext(SensorContext);
  const isPageUnavailable = usePageUnavailable();
  const [selectedDate, handleDateChange] = useState(
    moment().toDate()
  );
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const sensorId = sensor.id || SENSOR_ID_PLACEHOLDER;
  const formattedDate = moment(selectedDate).format('yyyy-MM-DD');

  const [time, setTime] = useState('00:00');
  const handleChange = (event) => {
    setTime(event.target.value);
  };

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    if (
      startOfDay(selectedDate) >
      startOfDay(moment().toDate())
    ) {
      setIsValid(false);
      return setValidationError(DISAGGREGATION_YESTERDAY_ERROR_MESSAGE);
    }

    if (
      sensor.body &&
      sensor.body.data &&
      sensor.body.data.data.first_event &&
      startOfDay(selectedDate) <
        startOfDay(moment(sensor.body.data.data.first_event).toDate())
    ) {
      setIsValid(false);
      return setValidationError(
        MINIMUM_DATE_ERROR_MESSAGE(
          moment(sensor.body.data.data.first_event).format('DD MMM yyyy')
        )
      );
    }

    setIsValid(true);
    return setValidationError('');
  }, [selectedDate, sensor.id, sensor]);

  const response = useQuery(
    ['disaggregationThirtyMinutes', sensor.id, formattedDate],
    () =>
      axios.get(`/sensors/${sensorId}/disag/day/30min?date=${formattedDate}`),
    {
      enabled: isValid,
      retry: 0,
      onSuccess: response => {
        const initialHour = Object.keys(response.data.estimations)?.[0] ?? '00:00';
        setTime(initialHour);
      }
    }
  );

  const getGraphData = (data, time) => {
    const applianceKeys =
      data && data.data && Object.keys(data.data.estimations[time].consumption);

    const appliances = applianceKeys.map((applianceKey) => {
      return {
        x: APPLIANCES[applianceKey].label,
        Consumption: data.data.estimations[time].consumption[applianceKey],
        Cost: data.data.estimations[time].cost[applianceKey],
      };
    });

    return sortAppliancesDescending(appliances);
  };

  const sortAppliancesDescending = (appliances) => {
    const sortedAppliances = [...appliances];
    sortedAppliances.sort((a, b) => {
      if (a.x > b.x) {
        return 1;
      }
      if (a.x < b.x) {
        return -1;
      }
      return 0;
    });

    const standby = sortedAppliances.find(
      (appliance) => appliance.x === 'Standby'
    );
    const standbyIndex = sortedAppliances.findIndex(
      (appliance) => appliance.x === 'Standby'
    );
    if (standbyIndex > -1) {
      sortedAppliances.splice(standbyIndex, 1);
      sortedAppliances.push(standby);
    }

    const others = sortedAppliances.find(
      (appliance) => appliance.x === 'Others'
    );
    const othersIndex = sortedAppliances.findIndex(
      (appliance) => appliance.x === 'Others'
    );
    if (othersIndex > -1) {
      sortedAppliances.splice(othersIndex, 1);
      sortedAppliances.push(others);
    }

    return sortedAppliances;
  };

  const disableForward = moment().isSame(selectedDate, 'day');

  const updateDate = (dir) => {
    if (dir === 'backwards') {
      handleDateChange(old => moment(old).subtract(1, 'day').toDate());
    } else {
      handleDateChange(old => moment(old).add(1, 'day').toDate());
    }
  }

  if (isValid && isPageUnavailable) {
    return <Unavailable pageName={isPageUnavailable} />
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle
          filter={
            <div>
              <DateArrow
                dir='backward'
                disabled={response.isLoading}
                onClick={() => updateDate('backwards')}
              />
              <DatePicker
                autoOk
                id="date-picker"
                label="Date"
                value={selectedDate}
                onChange={handleDateChange}
                disableFuture
                variant="inline"
                format="dd MMM yyyy"
                error={Boolean(validationError)}
                last="true"
              />
              <DateArrow
                dir='forward'
                disabled={response.isLoading || disableForward}
                onClick={() => updateDate('forwards')}
              />
            </div>
          }
        >
          Disaggregation by 30 min
        </PageTitle>
        <Typography paragraph>
          Returns the total consumption by appliances for a specific day with a
          30 minutes precision.
        </Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {!validationError && response.isSuccess && (
          <FormControl>
            <InputLabel id="time">Time</InputLabel>
            <StyledSelect
              labelId="time"
              id="time"
              value={time}
              onChange={handleChange}
            >
              {Object.keys(response.data.data.estimations).map((time) => (
                <MenuItem key={time} value={time}>
                  {time}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        )}
        {!validationError && response.isSuccess && time && (
          <Graph
            data={getGraphData(response.data, time)}
            y1="Consumption"
            y2="Cost"
            y1UnitFormatter={(value) =>
              `${new Intl.NumberFormat(sensor.isoLocaleCode).format(value)} kWh`
            }
            y2UnitFormatter={(value) =>
              CurrencyFormatter.format(parseFloat(value), {
                locale: sensor.isoLocaleCode,
              })
            }
            xAxisHeight={105}
          />
        )}
        {response.isError && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${sensorId}/disag/day/30min?date=${formattedDate}`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default DisaggregationThirtyMinutes;
