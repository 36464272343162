import { useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import EuroIcon from '@material-ui/icons/Euro';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import KitchenIcon from '@material-ui/icons/Kitchen';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import StarIcon from '@material-ui/icons/StarsRounded';
import SubjectIcon from '@material-ui/icons/Subject';
import NavigationItem from './NavigationItem';
import MUIList from '@material-ui/core/List';

import { SensorContext } from '../../../utils/context';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #388e3c;
  padding: 10px 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #348337;
    border-radius: 3px;
  }
`;

export const List = styled(MUIList)`
  color: #fff;
  font-size: 13px;
`;

export const SubHeader = styled.p`
  margin: 0;
  padding: 16px 28px 4px 28px;
`;

const navigationItems = [
  {
    title: 'Sensors',
    icon: <CollectionsBookmarkIcon />,
    links: [
      { title: 'List all sensors', url: '/sensors' },
      { title: 'View single sensor', url: '/single-sensor' },
    ],
  },
  {
    title: 'Cost and Consumptions',
    icon: <AccountBalanceWalletIcon />,
    disabledForSensorType: { LOW_RESOLUTION_METER: true },
    links: [
      { title: 'Overview by day', url: '/cost-consumption/overview-day', disabledForSensorType: { LOW_RESOLUTION_METER: true } },
      {
        title: 'Overview by thirty minutes',
        url: '/cost-consumption/overview-thirty-minutes',
        disabledForSensorType: { LOW_RESOLUTION_METER: true },
      },
      {
        title: 'Breakdown by period',
        url: '/cost-consumption/breakdown-period',
        disabledForSensorType: { LOW_RESOLUTION_METER: true },
      },
      {
        title: 'Breakdown by day',
        url: '/cost-consumption/breakdown-day',
        disabledForSensorType: { LOW_RESOLUTION_METER: true },
      },
      {
        title: 'Live consumption',
        url: '/cost-consumption/live-consumption',
        disabledForSensorType: { LOW_RESOLUTION_METER: true, HIGH_RESOLUTION_METER: true },
      },
      {
        title: 'Carbon footprint',
        url: '/cost-consumption/carbon-footprint',
        disabledForSensorType: { LOW_RESOLUTION_METER: true },
      },
    ],
  },
  {
    title: 'Tariffs',
    icon: <EuroIcon />,
    disabledForSensorType: { LOW_RESOLUTION_METER: true, HIGH_RESOLUTION_METER: true },
    links: [{ title: 'View tariff', url: '/tariffs', disabledForSensorType: { LOW_RESOLUTION_METER: true, HIGH_RESOLUTION_METER: true } }],
  },
  {
    title: 'Property',
    icon: <AccountBalanceIcon />,
    links: [{ title: 'View property details', url: '/property-details' }],
  },
  {
    title: 'Appliances',
    icon: <KitchenIcon />,
    links: [{ title: 'View property appliances', url: '/appliances' }],
  },
  {
    title: 'Disaggregation',
    icon: <BlurOnIcon />,
    main: '/disaggregation',
    links: [
      { title: 'Day', url: '/disaggregation/day', disabledForSensorType: { LOW_RESOLUTION_METER: true } },
      { title: 'Period', url: '/disaggregation/period', disabledForSensorType: { LOW_RESOLUTION_METER: true } },
      {
        title: 'Previous day - 30 min',
        url: '/disaggregation/thirty-minutes',
        disabledForSensorType: { LOW_RESOLUTION_METER: true },
      },
      { title: 'Appliance cycles', url: '/disaggregation/cycles' },
      { title: 'Weekly', url: '/disaggregation/weekly', disabledForSensorType: { VOLTAWARE_SENSOR: true, HIGH_RESOLUTION_METER: true } },
      { title: 'Monthly', url: '/disaggregation/monthly', disabledForSensorType: { VOLTAWARE_SENSOR: true, HIGH_RESOLUTION_METER: true } },
    ],
  },
  {
    title: 'VoltaScore',
    icon: <StarIcon />,
    links: [{ title: 'Monthly', url: '/voltascore/monthly' }],
  },
  {
    title: 'Reports',
    icon: <SubjectIcon />,
    links: [{ title: 'Monthly', url: '/reports/monthly' }],
  },
];

function Navigation({ closeMobilebar = null, isSidebarOpen = null }) {
  const sensor = useContext(SensorContext);
  const initialState = Object.fromEntries(
    navigationItems
      .map((item) => [item.title, false])
  );

  const reducer = (state, action) => {
    let newState = { ...state };
    switch (action.type) {
      case 'open':
        Object.keys(newState).forEach((key) => (newState[key] = false));
        return { ...newState, [action.payload]: true };
      case 'close':
        Object.keys(newState).forEach((key) => (newState[key] = false));
        return { ...newState };
      default:
        return state;
    }
  };

  const [navigationState, dispatch] = useReducer(reducer, initialState);

  return (
    <Wrapper>
      <List
        component="div"
        aria-labelledby="navigation-list"
        subheader={
          (closeMobilebar || isSidebarOpen) && (
            <SubHeader>HTTP Services API</SubHeader>
          )
        }
      >
        {navigationItems.map((item) => (
          <NavigationItem
            key={item.title}
            sensorType={sensor.type}
            disabled={!!item.disabledForSensorType?.[sensor.type]}
            title={item.title}
            icon={item.icon}
            links={item.links}
            main={item.main}
            closeMobilebar={closeMobilebar}
            isSidebarOpen={isSidebarOpen}
            isItemOpen={navigationState[item.title]}
            handleOpenItem={() =>
              dispatch({ type: 'open', payload: item.title })
            }
            handleCloseItem={() => dispatch({ type: 'close' })}
          />
        ))}
      </List>
    </Wrapper>
  );
}

Navigation.propTypes = {
  closeMobilebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
};

export default Navigation;
