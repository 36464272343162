import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Pie,
  Cell,
  PieChart,
  ResponsiveContainer,
} from 'recharts';
import green from '@material-ui/core/colors/green';

import { SensorContext } from '../../utils/context';

import { StyledPaper, Wrapper, Inner, DetailsText } from './styles';

const Details = ({ totalConsumption, consumptionFormatter }) => {
  if (totalConsumption === undefined) {
    return null;
  }

  return (
    <svg width="100%" height="100%">
      <DetailsText>
        <tspan fill={'#666'} textAnchor="middle" x={"50%"} y={"44%"}>Total</tspan>
        {totalConsumption !== undefined && (
          <tspan fill={green[600]} textAnchor="middle" x={"50%"} y={"56%"}>{consumptionFormatter(totalConsumption)}</tspan>
        )}
      </DetailsText>
    </svg>
  )
};

function DonutChart({ data, dataKey, margin }) {
  const sensor = useContext(SensorContext);

  return (
    <StyledPaper margin={margin}>
      <Wrapper>
        <Inner>
          <ResponsiveContainer>
            <PieChart>
              <Pie data={data.appliances} dataKey={dataKey} startAngle={90} endAngle={-360} minAngle={5} innerRadius="56%" outerRadius="95%" cx="50%" cy="50%">
                {
                  data.appliances.map((entry, index) => <Cell key={`donutchart-slice-${index}`} fill={entry.color}/>)
                }
              </Pie>
              {Details({
                totalConsumption: data.totalConsumption,
                consumptionFormatter: (value) =>
                  `${new Intl.NumberFormat(sensor.isoLocaleCode).format(Math.trunc(value))} kWh`,
              })}
            </PieChart>
          </ResponsiveContainer>
        </Inner>
      </Wrapper>
    </StyledPaper>
  );
}

DonutChart.propTypes = {
  data: PropTypes.shape({
    appliances: PropTypes.array,
    totalConsumption: PropTypes.number.isRequired,
    totalCost: PropTypes.number,
  }).isRequired,
  dataKey: PropTypes.string.isRequired,
  margin: PropTypes.shape({
    horizontal: PropTypes.number,
    vertical: PropTypes.number,
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
};

export default DonutChart;
