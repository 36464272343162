import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const SectionLabel = styled(Typography)`
  color: ${green[700]};
`;

export const MonthFullDescription = styled.span`
  display: none;
`;

export const Calendar = styled.div`
  position: relative;

  svg {
    font-size: 6rem;
    color: #3a3a3a;
  }

  & > div {
    position: absolute;
    top: calc(50% - 5px);
    left: 50%;
    transform: translateX(-50%);

    p {
      font-weight: 800;
      font-size: 1.6rem;
      color: #3a3a3a;
      user-select: none;
    }
  }
`;

export const Section = styled.section`
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: ${({ margin }) => `${margin?.vertical ?? 15}px ${margin?.horizontal ?? 0}px`};
  ${({ margin }) => margin?.top !== undefined && `margin-top: ${margin.top}px`};
  ${({ margin }) => margin?.right !== undefined && `margin-right: ${margin.right}px`};
  ${({ margin }) => margin?.bottom !== undefined && ` margin-bottom: ${margin.bottom}px`};
  ${({ margin }) => margin?.left !== undefined && `margin-left: ${margin.left}px`};

  
  @media (min-width: 600px) {
    ${({ width }) => width && `width: ${width};`}
    margin: ${({ lgmargin }) => `${lgmargin?.vertical ?? 15}px ${lgmargin?.horizontal ?? 0}px`};
    ${({ lgmargin }) => lgmargin?.top !== undefined && `margin-top: ${lgmargin.top}px`};
    ${({ lgmargin }) => lgmargin?.right !== undefined && `margin-right: ${lgmargin.right}px`};
    ${({ lgmargin }) => lgmargin?.bottom !== undefined && ` margin-bottom: ${lgmargin.bottom}px`};
    ${({ lgmargin }) => lgmargin?.left !== undefined && `margin-left: ${lgmargin.left}px`};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px), print {
    flex-direction: row;
    justify-content: ${({ rowJustify }) => rowJustify ? rowJustify : 'center'};
  }
`;

export const MonthConsumption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 1.2rem;
    color: #444;

    &:last-child {
      font-size: 1.1rem;
    }
  }
`;

export const BoldGreenText = styled(Typography)`
  line-height: 1;
  font-size: 4rem;
  font-weight: 800;
  color: ${green[600]};

  &:nth-of-type(2) {
    font-size: 1.6rem;
    font-weight: 400;
  }
`;

export const PreviousConsumption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 100;
    font-size: 0.9rem;
    color: #999;

    &.previous-consumption-value {
      font-size: 4rem;
      font-weight: 800;
      color: #5a5a5a;
      line-height: 1;

      &.delta {
        color: ${green[600]};

        &.red {
          font-size: 3rem;
          line-height: 1.5;
          color: #cc0000;
        }
      }

      & + p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1;
      }
    }
  }
`;

const activityColors = {
  minimum: '#57add9',
  average: '#f2a100',
  maximum: '#cc0000',
};

export const Activity = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 1.2rem;
    color: #999;

    &.numbers {
      font-size: 2rem;
      font-weight: 800;
      color: ${({ type }) => activityColors[type]};

      span {
        padding-left: 12px;
        font-size: 1.8rem;
        font-weight: 400;
      }
    }
  }

  img {
    width: 150px;
  }
`;

export const WeekdaysWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 1.1rem;
    color: #999;

    &:last-of-type {
      font-size: 1.8rem;
      font-weight: 800;
      color: #555;

      span {
        font-size: 1.1rem;
        font-weight: 100;
        color: #777;
      }
    }
  }
`;

export const Weekdays = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`;

export const Weekday = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ highlight }) => highlight ? green[600] : '#ccc'};
  font-size: 1rem;
  line-height: 1;
  font-weight: 800;
  color: #fff;
  user-select: none;
`;

export const Row = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};
  align-items: center;

  margin: ${({ margin }) => `${margin?.vertical ?? 0}px ${margin?.horizontal ?? 0}px`};
  ${({ margin }) => margin?.top !== undefined && `margin-top: ${margin.top}px`};
  ${({ margin }) => margin?.right !== undefined && `margin-right: ${margin.right}px`};
  ${({ margin }) => margin?.bottom !== undefined && ` margin-bottom: ${margin.bottom}px`};
  ${({ margin }) => margin?.left !== undefined && `margin-left: ${margin.left}px`};

  @media (min-width: 600px) {
    ${({ width }) => width && `width: ${width};`}
    margin: ${({ lgmargin }) => `${lgmargin?.vertical ?? 0}px ${lgmargin?.horizontal ?? 0}px`};
    ${({ lgmargin }) => lgmargin?.top !== undefined && `margin-top: ${lgmargin.top}px`};
    ${({ lgmargin }) => lgmargin?.right !== undefined && `margin-right: ${lgmargin.right}px`};
    ${({ lgmargin }) => lgmargin?.bottom !== undefined && ` margin-bottom: ${lgmargin.bottom}px`};
    ${({ lgmargin }) => lgmargin?.left !== undefined && `margin-left: ${lgmargin.left}px`};
  }
`;

export const VoltaStar = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 4px;
  
  & > div {
    width: 100%;
    height: 90%;
    background-color: ${({ color }) => color};
  }
`;

export const VoltaScoreOverall = styled(Typography)`
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif !important;
  font-size: 1.15rem;
  color: #313B49;
  line-height: initial;

  strong {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-size: 1.8rem;
    font-weight: 900;
    margin-right: 2px;
  }
`;

export const VoltaScoreAppliancesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 55%;
  padding-top: 15px;
`;

export const VoltaScoreApplianceWrapper = styled(Row)`
  max-width: 300px;
  width: 100%;
  padding-bottom: 15px;

  & > div {
    display: flex;
    align-items: center;
    width: 100%;

    img {
      width: 34px;
      margin-right: 10px;
    }

    p {
      font-size: 0.9rem;
    }
  }

  & > p {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-weight: 600;
    color: ${({ color }) => color};
  }
`;

export const RankPercentage = styled(Row)`
  margin: 20px 0 0;
  justify-content: center;
  flex-wrap: wrap;

  & > div {
    display: flex;
    align-items: center;
    margin-right: 40px;

    p {
      font-family: Roboto, "Helvetica Neue", Arial, sans-serif !important;
      font-size: 16px;
      
      &:first-child {
        font-weight: 600;
        color: ${({ color }) => color};
      }

      &:last-child {
        font-weight: 900;
        color: #FFFFFF;
        background-color: ${({ color }) => color};
        padding: 2px 8px;
        border-radius: 4px;
        margin-left: 10px;
      }
    }
  }

  & > p {
    font-size: 14px;
    font-style: italic;
    color: #545454;
  }
`;

export const CarbonFootprintText = styled(Typography)`
  margin: 10px 0;
  color: #777;
  line-height: 1.3;

  span {
    color: #cc0400;
  }
`;

export const Trees = styled.div`
  display: flex;
  justify-content: center;

  img {
    padding: 2px;
  }
`;
