import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import Paper from '@material-ui/core/Paper';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Typography from '@material-ui/core/Typography';
import CurrencyFormatter from 'currency-formatter';

import { SensorContext } from '../../utils/context';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
`;

const BarRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Bar = styled.div`
  height: 15px;
  border-radius: 2px;
  width: ${({ percentage }) => `${percentage}%`};
  background: ${({ color }) => color};
  opacity: ${({ variant }) => (variant ? '0.5' : '1')};
  max-width: 80%;
  margin-right: 10px;
`;

const Icon = styled.img`
  width: 34px;
  margin-right: 5px;
`;

const StyledTypography = styled(Typography)``;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledPaper = styled(Paper)`
  opacity: 0;
  margin: ${({ margin }) => `${margin?.vertical ?? 0}px ${margin?.horizontal ?? 0}px`};
  ${({ margin }) => margin?.top !== undefined && `margin-top: ${margin.top}px`};
  ${({ margin }) => margin?.right !== undefined && `margin-right: ${margin.right}px`};
  ${({ margin }) => margin?.bottom !== undefined && ` margin-bottom: ${margin.bottom}px`};
  ${({ margin }) => margin?.left !== undefined && `margin-left: ${margin.left}px`};

  animation: 0.66s linear ${fadeIn};
  animation-fill-mode: forwards;
`;

function ApplianceBars({ data, margin }) {
  const sensor = useContext(SensorContext);

  return (
    <StyledPaper margin={margin}>
      {data.map((item) => (
        <Item key={item.title}>
          <Title>
            {item.icon && <Icon src={item.icon} alt={item.title} />}
            <StyledTypography>{item.title}</StyledTypography>
          </Title>
          <BarRow>
            <Bar
              percentage={item.consumptionPercentage}
              color={item.color}
              border={green[500]}
              className="adjust-color"
            />
            <Typography variant="caption">
              {new Intl.NumberFormat(sensor.isoLocaleCode).format(
                item.consumption
              )}{' '}
              kWh ({item.consumptionPercentage}%)
            </Typography>
          </BarRow>
          {item.cost !== undefined && (
            <BarRow>
              <Bar
                percentage={item.costPercentage}
                color={item.color}
                border={red[500]}
                variant
                className="adjust-color"
              />
              <Typography variant="caption">
                {CurrencyFormatter.format(item.cost, {
                  locale: sensor.isoLocaleCode,
                }) + ' '}
                ({item.costPercentage}
                %)
              </Typography>
            </BarRow>
          )}
        </Item>
      ))}
    </StyledPaper>
  );
}

ApplianceBars.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ApplianceBars;
