import styled from 'styled-components';
import { DatePicker } from '@material-ui/pickers';

const StyledDatePicker = styled(DatePicker)`
  width: 100px;
  margin-bottom: 5px;
  margin-right: ${(props) => props.last ? 0 : 20}px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export default StyledDatePicker;
