import { useContext } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import { SensorContext } from '../../utils/context';
import ContentWrapper from '../../components/ContentWrapper';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import { SENSOR_ID_PLACEHOLDER } from '../../utils/constants';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

function SingleSensor() {
  const sensor = useContext(SensorContext);
  const id = sensor.id || SENSOR_ID_PLACEHOLDER;

  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle>View single sensor</PageTitle>
        <Typography>
          Returns sensor's hardware and firmware configuration.
        </Typography>
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${id}`}
        response={
          sensor && sensor.body && sensor.body.data && sensor.body.data.data
        }
        isLoading={sensor.body.isLoading}
        isError={sensor.body.isError}
        isSuccess={sensor.body.isSuccess}
        isIdle={sensor.body.isIdle}
      />
    </Wrapper>
  );
}

export default SingleSensor;
