
import React from 'react'; 
import PropTypes from 'prop-types';
import { ReferenceLine } from 'recharts';

const CustomLabel = (props) => {
  const { viewBox, value, offset } = props;

  return (
    <text fontSize={12} fontWeight={500} x={viewBox.width} y={viewBox.y} fill={'#222'} dx={offset?.x || 0} dy={offset?.y || 0}>
      {value}
    </text>
  );
};

function HorizontalLine({ axisId, data }) {
  return (
    <ReferenceLine
      yAxisId={axisId}
      y={data.value}
      label={(props) =>
        <CustomLabel {...props}
          value={data.label.text || ''}
          offset={data.label.offset}
        />
      }
      stroke={data.color}
      strokeDasharray={data.dash || ''}
      strokeWidth={2}
    />
  );
}

HorizontalLine.propTypes = {
  axisId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    color: PropTypes.string.isRequired,
    label: PropTypes.shape({
      text: PropTypes.string.isRequired,
      offset: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      }),
    }),
    dash: PropTypes.string,
  }).isRequired,
};

export default HorizontalLine;
