import Typography from '@material-ui/core/Typography';

import ContentWrapper from '../ContentWrapper';
import PageTitle from '../PageTitle';

function Unavailable({ pageName = 'Unavailable' }) {
  return (
    <ContentWrapper>
      <PageTitle>{pageName}</PageTitle>
      <Typography paragraph>
        This page is not available for your sensor type.
      </Typography>
    </ContentWrapper>
  );
}

export default Unavailable;
