import styled from 'styled-components';
import PropTypes from 'prop-types';

import Menu from '../Menu';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  z-index: 2000;
  display: flex;
  left: ${({ isMobilebarOpen }) => isMobilebarOpen && '0'};
  transition: left 0.2s;
`;

const Panel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100vw;
  height: 100vh;
  background: #fff;
  opacity: 0.6;
`;

function Mobilebar({ isMobilebarOpen, closeMobilebar }) {
  return (
    <>
      <Wrapper isMobilebarOpen={isMobilebarOpen}>
        <Menu closeMobilebar={closeMobilebar} />
      </Wrapper>
      {isMobilebarOpen && <Panel onClick={closeMobilebar} />}
    </>
  );
}

Mobilebar.propTypes = {
  isMobilebarOpen: PropTypes.bool.isRequired,
  closeMobilebar: PropTypes.func.isRequired,
};

export default Mobilebar;
