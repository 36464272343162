import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import green from '@material-ui/core/colors/green';
import Typography from '@material-ui/core/Typography';

import { SensorContext } from '../../utils/context';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
`;

const BarRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Bar = styled.div`
  height: 15px;
  border-radius: 2px;
  width: ${({ percentage }) => `${percentage}%`};
  background: ${({ color }) => color};
  opacity: ${({ variant }) => (variant ? '0.5' : '1')};
  max-width: 80%;
  margin-right: 10px;
`;

const Description = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledTitle = styled(Typography)``;

const StyledTotalCycles = styled(Typography)`
  color: #999;
  font-size: 0.85rem;
  font-style: italic;
`;

const CyclesContainer = styled.div``;

const Cycle = styled.div`
  display: flex;
`;

const Connector = styled.div`
  position: relative;
  border: 1px dashed #e0e0e0;
  margin: 0 4px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Dot = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #868686;
  transform: translateX(-50%);
  left: 50%;
`;

const Info = styled(Typography)`
  line-height: 2rem;
  margin-left: 5px;
  font-weight: 400;
  color: #666;
`;

function Cycles({ data, showConsumption }) {
  const sensor = useContext(SensorContext);

  return (
    <Paper>
      {data.map((item) => (
        <Item key={item.applianceKey}>
          <Description>
            <StyledTitle>{item.title}</StyledTitle>
            <StyledTotalCycles>{item.totalCycles} cycle{item.totalCycles > 1 ? 's' : ''}</StyledTotalCycles>
          </Description>
          <BarRow>
            <Bar
              percentage={item.totalConsumptionPercent}
              color={item.color}
              border={green[500]}
            />
            <Typography variant="caption">
              {new Intl.NumberFormat(sensor.isoLocaleCode).format(
                item.totalConsumption
              )}{' Wh '}({item.totalConsumptionPercent}%)
            </Typography>
          </BarRow>
          <CyclesContainer>
            {item.cycles.map(cycle => (
              <Cycle key={cycle.key}>
                <Connector>
                  <Dot />
                </Connector>
                <Info variant="subtitle2">
                  {cycle.startingTime} to {cycle.endingTime}{showConsumption && ` (${new Intl.NumberFormat(sensor.isoLocaleCode).format(cycle.consumption)} Wh)`}
                </Info>
              </Cycle>
            ))}
          </CyclesContainer>
        </Item>
      ))}
    </Paper>
  );
}

Cycles.propTypes = {
  data: PropTypes.array.isRequired,
  showConsumption: PropTypes.bool,
};

export default Cycles;
