export const getAccessToken = () => {
  const token = localStorage.getItem('access_token');
  return token;
};

export const setAccessToken = (token) => {
  localStorage.setItem('access_token', token);
};

export const removeAccessToken = () => {
  localStorage.removeItem('access_token');
};
