import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import axios from '../../api/setup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { setAccessToken, removeAccessToken } from '../../api/auth';
import {
  INCORRECT_CREDENTIALS_ERROR_MESSAGE,
  SERVER_ERROR_MESSAGE,
} from '../../utils/constants';
import { decrypt } from '../../utils/functions';
import logo from '../../images/login-logo.png';

const Wrapper = styled(Grid)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 250px;
`;

const Logo = styled.img`
  border-radius: 4px;
  width: 120px;
`;

const Form = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

function Login({ setIsAuth }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const location = useLocation();

  const mutation = useMutation(({ username, password }) =>
    axios
      .post('/auth/token', {
        grant_type: 'client_credentials',
        client_id: username,
        client_secret: password,
      })
      .then((response) => {
        setAccessToken(response.data.access_token);
        setIsAuth(true);
        history.push('/');
      })
      .finally(() => {
        // Should only be reached if staying on this page after attempt (error)
        const authQuery = new URLSearchParams(location.search).get("x") || new URLSearchParams(location.search).get("y");
        if (typeof window !== 'undefined' && authQuery) {
          window.location.href = window.location.href.replace(/\?.+/i, '');
        }
      })
  );
  const { mutate } = mutation;

  useEffect(() => {
    removeAccessToken();
    setIsAuth(false);

    const userNameQuery = new URLSearchParams(location.search).get("x");
    const passwordQuery = new URLSearchParams(location.search).get("y");

    if (!userNameQuery || !passwordQuery) {
      return;
    }

    try {
      const userNameDecrypted = decrypt(userNameQuery);
      const passwordDecrypted = decrypt(passwordQuery);

      if (userNameDecrypted && passwordDecrypted) {
        mutate({ username: userNameDecrypted, password: passwordDecrypted });
      } else {
        throw new Error();
      }
    } catch (_) {
      if (typeof window !== 'undefined') {
        window.location.href = window.location.href.replace(/\?.+/i, '');
      }
    }

  }, [setIsAuth, location, mutate]);

  const errorMessage =
    mutation.error &&
    mutation.error.response &&
    mutation.error.response.status === 401
      ? INCORRECT_CREDENTIALS_ERROR_MESSAGE
      : SERVER_ERROR_MESSAGE;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (username === '' || password === '') {
      return;
    }
    mutation.mutate({ username, password });
  };

  return (
    <Wrapper container spacing={1}>
      <Grid item>
        <Logo src={logo} alt="Voltaware" />
      </Grid>
      <Grid item>
        <Typography variant="h5" color="primary">
          Services API
        </Typography>
      </Grid>
      <Grid item>
        {mutation.isError && (
          <Typography variant="subtitle2" color="error">
            {errorMessage}
          </Typography>
        )}
      </Grid>
      <Form component="form" container spacing={1} onSubmit={handleSubmit}>
        <Grid item>
          <TextField
            placeholder="Username"
            value={username}
            onChange={(event) => setUsername(event.target.value.trim())}
            disabled={mutation.isLoading}
            fullWidth
            variant="outlined"
            size="small"
            autoFocus
          />
        </Grid>
        <Grid item>
          <TextField
            type="password"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value.trim())}
            disabled={mutation.isLoading}
            fullWidth
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={mutation.isLoading}
          >
            Login
          </Button>
        </Grid>
      </Form>
    </Wrapper>
  );
}

Login.propTypes = {
  setIsAuth: PropTypes.func.isRequired,
};

export default Login;
