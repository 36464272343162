import axios from 'axios';
import { getAccessToken } from './auth';

const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST,
});

instance.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json';

  const isAuth = Boolean(getAccessToken());
  if (isAuth) {
    config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      window.location.pathname !== '/login' &&
      process.env.NODE_ENV !== 'test'
    ) {
      window.location.pathname = '/login';
    }
    return Promise.reject(error);
  }
);

export default instance;
