import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  & * {
    font-family: 'Ubuntu', 'Inter', sans-serif !important;
    font-size: 1rem;
    color: #545454;
    padding: 0;
    margin: 0;
  }
`;

export const Title = styled.strong`
  font-size: 1.6rem;
  font-weight: 700;
  color: #242424;
  padding: 10px 0 5px;
`;

export const SubTitle = styled.span`
  line-height: 30px;
  font-size: 1.5rem;
  font-style: italic;
  color: #242424;
  padding-bottom: 10px;

  span {
    font-size: 1.5rem;
    font-style: italic;
  }
`;

export const Value = styled.div`
  font-size: 1.1rem;
  font-weight: 700;
  span {
    font-size: 1.1rem;
    font-weight: 700;
    padding-right: 2px;
    color: ${props => props.color || '#242424'};
  }
`;

export const ScoreDataWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  max-width: 50%;
`;


export const ScoreData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px 10px;
`;

export const RecommendationsTitle = styled.div`
  display: flex;
  align-items: flex-end;

  img {
    width: 40px;
  }

  strong {
    padding-left: 4px;
    font-size: 1.4rem;
    color: #242424;
    line-height: 26px;
  }
`;

export const Recommendations = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 20px 10px 10px;
  max-width: 70%;
  margin: 0 auto;
`;

export const Recommendation = styled.div`
  display: flex;
  align-items: start;
  width: 350px;
  margin-right: 30px;
  margin-bottom: 15px;

  img {
    min-width: 60px;
    margin-right: 10px;
  }

  div {
    display: flex;
    flex-direction: column;

    p {
      padding-top: 5px;
      text-align: justify;
    }
  }
`;
