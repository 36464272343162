import styled, { keyframes } from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const StyledPaper = styled(Paper)`
  padding: 10px 15px;
  min-height: 450px;
  overflow: hidden;

  margin: ${({ margin }) => `${margin?.vertical ?? 0}px ${margin?.horizontal ?? 0}px`};
  ${({ margin }) => margin?.top !== undefined && `margin-top: ${margin.top}px`};
  ${({ margin }) => margin?.right !== undefined && `margin-right: ${margin.right}px`};
  ${({ margin }) => margin?.bottom !== undefined && ` margin-bottom: ${margin.bottom}px`};
  ${({ margin }) => margin?.left !== undefined && `margin-left: ${margin.left}px`};

  @media (max-width: 600px) {
    padding: 0;
  }

  @media print {
    margin-top: 5px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const fadeIn = keyframes`
  0% {opacity: 0;}
  50% {opacity: 0;}
  100% {opacity: 1;}
`;

export const DetailsText = styled.text`
  opacity: 0;
  background-color: blue;
  animation: ${fadeIn} forwards 2s;

  @media print {
    animation: none;
    opacity: 1;
  }

  tspan:first-child {
    font-size: 40px;
    font-weight: 500;

    @media (max-width: 600px) {
      font-size: 36px;
    }
  }

  tspan:nth-child(2) {
    font-size: 32px;
    font-weight: 800;

    @media (max-width: 600px) {
      font-size: 24px;
    }
  }
`;