import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import App from './App';
import { GlobalStyle, MuiTheme, theme } from './styles';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();
setLogger({
  log: () => {},
  warn: () => {},
  error: () => {},
});

ReactDOM.render(
  <Router>
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <GlobalStyle />
      <MuiThemeProvider theme={MuiTheme}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  </Router>,
  document.getElementById('root')
);

reportWebVitals();
