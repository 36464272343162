import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Paper from '@material-ui/core/Paper';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/yellow';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { SensorContext } from '../../../utils/context';

const offPeakColor = green[300];
const peakColor = orange[700];

const StyledPaper = styled(Paper)`
  padding: 45px 45px 20px 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Bar = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  position: relative;
`;

const Segment = styled.div`
  flex: ${({ $range }) => $range || 1};
  background: ${offPeakColor};
  ${({ $type }) => $type === 'off-peak' && `background: ${offPeakColor};`}
  ${({ $type }) => $type === 'peak-time' && `background: ${peakColor};`}
  position: relative;
`;

const Label = styled.span`
  position: absolute;
  bottom: -35px;
  ${({ $start }) => $start === true && `left: -35px;`}
  ${({ $end }) => $end === true && `right: -12px;`}
  font-size: 0.8em;
  transform: rotate(-50deg);
`;

const Arrow = styled.div.attrs(({ $progress }) => ({
  style: {
    left: `${$progress}%`,
  },
}))`
  position: absolute;
  top: -27px;

  margin: 0 -17px 0 -17px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35px;
`;

const ArrowLabel = styled.span`
  margin-bottom: -14px;
  font-size: 0.8em;
`;

const ArrowIcon = styled(ArrowDropDownIcon)`
  color: ${({ $fill }) => $fill};
`;

const Legend = styled.div`
  display: flex;
  width: 170px;
  justify-content: space-between;
  margin-top: 50px;
`;

const LegendRow = styled.div`
  display: flex;
  align-items: center;
`;

const LegendColor = styled.div`
  background: ${({ $fill }) => $fill};
  height: 10px;
  width: 15px;
  margin-right: 10px;
`;
const LegendText = styled.div`
  color: ${({ $fill }) => $fill};
`;

function TariffBar({ schedule }) {
  const { timeZoneId } = useContext(SensorContext);

  const [localTime, setLocalTime] = useState(
    moment().tz(timeZoneId) ? moment().tz(timeZoneId).format('hh:mm:ss') : ''
  );

  useEffect(() => {
    const intervalId = setInterval(
      () =>
        setLocalTime(() =>
          moment().tz(timeZoneId)
            ? moment().tz(timeZoneId).format('hh:mm:ss')
            : ''
        ),
      1000
    );
    return () => clearInterval(intervalId);
  }, [timeZoneId]);

  const sortFromTimeDescending = (schedule) => {
    const sortedSchedule = [...schedule];
    sortedSchedule.sort((a, b) => {
      if (
        moment.duration(a.from).asSeconds() >
        moment.duration(b.from).asSeconds()
      ) {
        return 1;
      }
      if (
        moment.duration(a.from).asSeconds() <
        moment.duration(b.from).asSeconds()
      ) {
        return -1;
      }
      return 0;
    });

    return sortedSchedule;
  };

  const getArrowColor = (localTime, schedule) => {
    let color = offPeakColor;

    sortFromTimeDescending(schedule).find((item) => {
      if (
        moment.duration(localTime).asSeconds() <
          moment.duration(item.to).asSeconds() &&
        item.key === 'off-peak'
      ) {
        color = offPeakColor;
        return true;
      }
      if (
        moment.duration(localTime).asSeconds() <
          moment.duration(item.to).asSeconds() &&
        item.key === 'peak-time'
      ) {
        color = peakColor;
        return true;
      }
      return false;
    });

    return color;
  };

  return (
    <StyledPaper>
      <Bar>
        {sortFromTimeDescending(schedule).map((item, index) => (
          <Segment
            key={item.from}
            $range={
              moment.duration(item.to).asSeconds() -
              moment.duration(item.from).asSeconds()
            }
            $type={item.key}
          >
            <Label $start>{item.from}</Label>
            {index === schedule.length - 1 && <Label $end>23:59:59</Label>}
          </Segment>
        ))}
        {localTime && (
          <Arrow
            $progress={
              (moment.duration(localTime).asSeconds() /
                moment.duration('24:00:00').asSeconds()) *
              100
            }
          >
            <ArrowLabel>{localTime}</ArrowLabel>
            <ArrowIcon
              fontSize="large"
              $fill={getArrowColor(localTime, schedule)}
            />
          </Arrow>
        )}
      </Bar>
      <Legend>
        <LegendRow>
          <LegendColor $fill={offPeakColor} />{' '}
          <LegendText $fill={offPeakColor}>Off-peak</LegendText>
        </LegendRow>
        <LegendRow>
          <LegendColor $fill={peakColor} />{' '}
          <LegendText $fill={peakColor}>Peak</LegendText>
        </LegendRow>
      </Legend>
    </StyledPaper>
  );
}

TariffBar.propTypes = {
  schedule: PropTypes.array.isRequired,
};

export default TariffBar;
