import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  Label,
} from 'recharts';
import Paper from '@material-ui/core/Paper';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CurrencyFormatter from 'currency-formatter';

import { SensorContext } from '../../utils/context';

import CustomTooltip from './Custom/Tooltip';
import CustomLegend from './Custom/Legend';
import CustomBar from './Custom/Bar';
import HorizontalLine from './Custom/HorizontalLine';

const StyledPaper = styled(Paper)`
  overflow: hidden;
  padding: 10px 15px;
  min-height: ${({ height }) => `${height}px`};

  margin: ${({ margin }) => `${margin?.vertical ?? 0}px ${margin?.horizontal ?? 0}px`};
  ${({ margin }) => margin?.top !== undefined && `margin-top: ${margin.top}px`};
  ${({ margin }) => margin?.right !== undefined && `margin-right: ${margin.right}px`};
  ${({ margin }) => margin?.bottom !== undefined && ` margin-bottom: ${margin.bottom}px`};
  ${({ margin }) => margin?.left !== undefined && `margin-left: ${margin.left}px`};

  @media print {
    margin-top: 5px;
  }
`;

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 5px;
`;

const StyledCheckBox = styled(Checkbox)`
  color: ${({ $boxColor }) => $boxColor} !important;
`;

const AverageLine = ({ yAxisId, average }) => {
  if (!average) {
    return null;
  }

  // Recharts workaround to display custom components: use function notation
  return HorizontalLine({ axisId: yAxisId, data: average });
}

function HorizontalGraph({
  data,
  y1,
  y2,
  y1UnitFormatter,
  y2UnitFormatter,
  y1Color = green[500],
  y1FillColor = green[500],
  y2Color = red[500],
  y2FillColor = red[500],
  yAxisTopPadding = 15,
  interval = 0,
  xAxisHeight = 100,
  margin,
  height = 450,
  highlights,
}) {
  const sensor = useContext(SensorContext);

  const [y1Active, setY1Active] = useState(true);
  const [y2Active, setY2Active] = useState(!!y2);

  const bothYAxisData = !!y1 && !!y2;

  const y1Highlights = highlights?.y1Highlights;
  const y2Highlights = highlights?.y2Highlights;

  const handleToggleY1 = () => {
    if (y1Active === true && y2Active === false) {
      return;
    }
    setY1Active(!y1Active);
  };

  const handleToggleY2 = () => {
    if (y2Active === true && y1Active === false) {
      return;
    }
    setY2Active(!y2Active);
  };

  return (
    <StyledPaper margin={margin} height={height}>
      {bothYAxisData && (
        <StyledFormGroup row>
          <FormControlLabel
            control={
              <StyledCheckBox
                $boxColor={y1Color}
                checked={y1Active}
                onChange={handleToggleY1}
                name={y1}
              />
            }
            label={y1}
          />
          <FormControlLabel
            control={
              <StyledCheckBox
                $boxColor={y2Color}
                checked={y2Active}
                onChange={handleToggleY2}
                name={y2}
              />
            }
            label={y2}
          />
        </StyledFormGroup>
      )}
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div
          style={{
            width: '100%',
            height: bothYAxisData ? '90%' : '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <ResponsiveContainer>
            <BarChart
              data={data}
              barSize={70}
              margin={{ top: bothYAxisData ? 15 : 30, left: 20, right: bothYAxisData ? 30 : 90, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="x"
                tickLine={false}
                angle={-70}
                interval={interval}
                height={xAxisHeight}
                allowDataOverflow={true}
                textAnchor="end"
                tick={{ width: 90 }}
              />
              <YAxis
                yAxisId="left"
                stroke={y1Color}
                padding={{ top: yAxisTopPadding }}
                tickFormatter={(value) =>
                  new Intl.NumberFormat(sensor.isoLocaleCode).format(value)
                }
              >
                {y1Active && <Label position="top">kWh</Label>}
              </YAxis>
              {y2 && (
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  stroke={y2Color}
                  padding={{ top: yAxisTopPadding }}
                  tickFormatter={(value) =>
                    new Intl.NumberFormat(sensor.isoLocaleCode).format(value)
                  }
                >
                  {y2Active && (
                    <Label position="top">
                      {CurrencyFormatter.format(0, {
                        locale: sensor.isoLocaleCode,
                      })
                        .replace('0,00', '')
                        .replace('0.00', '')}
                    </Label>
                  )}
                </YAxis>
              )}
              <Tooltip
                content={<CustomTooltip colors={[y1Active && y1Color, y2Active && y2Color]} />}
                isAnimationActive={false}
                formatter={(value, name) => {
                  if (name === y1) {
                    return y1UnitFormatter(value);
                  } else if (name === y2) {
                    return y2UnitFormatter(value);
                  }
                  return value;
                }}
              />
              {y1Active &&
                // Recharts workaround to display custom components: use function notation
                CustomBar({
                  yAxisId: "left",
                  dataKey: y1,
                  fill: y1FillColor,
                  highlights: highlights?.y1Highlights,
                  yAxisTopPadding: yAxisTopPadding,
                })
              }
              {y2 && y2Active &&
                CustomBar({
                  yAxisId: "right",
                  dataKey: y2,
                  fill: y2FillColor,
                  highlights: highlights?.y2Highlights,
                  yAxisTopPadding: yAxisTopPadding,
                })
              }
              
              {!(bothYAxisData && y1Active && y2Active) &&
                AverageLine({ 
                  yAxisId: y1Active ? 'left' : 'right',
                  average: y1Active ? y1Highlights?.average : y2Highlights?.average
                })
              }
              <Legend content={<CustomLegend colors={[y1Active && y1Color, y2Active && y2Color]} />} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </StyledPaper>
  );
}

HorizontalGraph.propTypes = {
  data: PropTypes.array.isRequired,
  y1: PropTypes.string.isRequired,
  y2: PropTypes.string,
  y1UnitFormatter: PropTypes.func.isRequired,
  y2UnitFormatter: PropTypes.func,
  y1Color: PropTypes.string,
  y1FillColor: PropTypes.string,
  y2Color: PropTypes.string,
  y2FillColor: PropTypes.string,
  interval: PropTypes.number,
  xAxisHeight: PropTypes.number,
  margin: PropTypes.shape({
    horizontal: PropTypes.number,
    vertical: PropTypes.number,
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  highlights: PropTypes.shape({
    y1Highlights: PropTypes.shape({
      // Key and values [required]
      key: PropTypes.string,
      indices: PropTypes.shape({
        total: PropTypes.number.isRequired,
        indicesApart: PropTypes.number.isRequired,
        minIndex: PropTypes.number.isRequired,
        maxIndex: PropTypes.number.isRequired,
      }),
      values: PropTypes.shape({
        [PropTypes.string]: PropTypes.shape({
          color: PropTypes.string.isRequired,
          icon: PropTypes.shape({
            size: PropTypes.number.isRequired,
            element: PropTypes.elementType.isRequired,
            offset: PropTypes.shape({
              x: PropTypes.number,
              y: PropTypes.number,
            }),
          }),
        }),
      }),
      average: PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
        color: PropTypes.string.isRequired,
        label: PropTypes.shape({
          text: PropTypes.string.isRequired,
          offset: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
          }),
        }),
        dash: PropTypes.string,
      }),
      brightness: PropTypes.shape({
        key: PropTypes.string.isRequired,
        min: PropTypes.number,
        max: PropTypes.number.isRequired,
        mode: PropTypes.string,
        maxFactor: PropTypes.number,
      }),
    }),
    y2Highlights: PropTypes.shape({
      key: PropTypes.string,
      indices: PropTypes.shape({
        total: PropTypes.number.isRequired,
        indicesApart: PropTypes.number.isRequired,
        minIndex: PropTypes.number.isRequired,
        maxIndex: PropTypes.number.isRequired,
      }),
      values: PropTypes.shape({
        [PropTypes.string]: PropTypes.shape({
          color: PropTypes.string.isRequired,
          icon: PropTypes.shape({
            size: PropTypes.number.isRequired,
            element: PropTypes.elementType.isRequired,
            offset: PropTypes.shape({
              x: PropTypes.number,
              y: PropTypes.number,
            }),
          }),
        }),
      }),
      average: PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
        color: PropTypes.string.isRequired,
        label: PropTypes.shape({
          text: PropTypes.string.isRequired,
          offset: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
          }),
        }),
        dash: PropTypes.string,
      }),
      brightness: PropTypes.shape({
        key: PropTypes.string.isRequired,
        min: PropTypes.number,
        max: PropTypes.number.isRequired,
        mode: PropTypes.string,
        maxFactor: PropTypes.number,
      }),
    }),
  }),
};

export default HorizontalGraph;
