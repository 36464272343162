import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MUIList from '@material-ui/core/List';
import MUIListItem from '@material-ui/core/ListItem';
import MUIListItemText from '@material-ui/core/ListItemText';
import MUIListItemIcon from '@material-ui/core/ListItemIcon';

export const NestedListItem = styled(MUIListItem)`
  padding-left: 70px;
  padding-right: 30px;
  padding: 4px 30px 4px 70px;
  opacity: 0.7;
  transition: background 0.2s, opacity 0.2s;

  &:hover,
  &.active {
    background: ${({ $isDisabled }) => $isDisabled ? 'rgba(54, 137, 58, 0.8)' : '#36893a'};
    opacity: 1;
  }
`;

export const List = styled(MUIList)`
  color: #fff;
  font-size: 13px;
  ${(props) => props.$isSidebarOpen === false && 'display: none;'}
`;

export const ListItem = styled(MUIListItem)`
  padding-left: ${({ $isSidebarOpen }) => (!$isSidebarOpen ? '15px' : '30px')};
  padding-right: 30px;

  &:hover,
  &.active {
    background: ${({ $isDisabled }) => $isDisabled ? 'rgba(52, 131, 55, 0.8)' : '#348337'};
  }
  & .MuiListItemIcon-root,
  .MuiSvgIcon-root {
    color: #fff;
    opacity: ${({ $isDisabled }) => $isDisabled ? 0.4 : 0.7};
  }
  transition: background 0.2s, opacity 0.2s, padding 0.2s;
`;

export const ListItemIcon = styled(MUIListItemIcon)`
  min-width: 38px;
`;

export const ListItemText = styled(MUIListItemText)`
  & > span {
    font-size: 13px;
    opacity: ${({ $isDisabled }) => $isDisabled ? 0.4 : 0.7};
  }
`;

export const PopoverWrapper = styled.div`
  padding: 5px 0;
  display: flex;
  flex-direction: column;

  & > a {
    font-size: 0.9em;
  }
`;

function NavigationItem({
  sensorType,
  disabled,
  title,
  icon,
  links,
  main = null,
  closeMobilebar = null,
  isSidebarOpen = null,
  isItemOpen,
  handleOpenItem,
  handleCloseItem,
}) {
  const handleClick = (event) => {
    if (isSidebarOpen === false) {
      handleMenuOpen(event);
      return;
    }
    if (isItemOpen === true) {
      handleCloseItem();
    }
    if (isItemOpen === false) {
      handleOpenItem();
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <>
      <ListItem
        component={main ? NavLink : 'button'}
        onClick={handleClick}
        title={title}
        to={main}
        exact={main ? true : null}
        $isSidebarOpen={closeMobilebar || isSidebarOpen}
        $isDisabled={disabled}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        {(closeMobilebar || isSidebarOpen) && <ListItemText primary={title} $isDisabled={disabled} />}
        {isItemOpen
          ? (closeMobilebar || isSidebarOpen) && <ExpandLess fontSize="small" />
          : (closeMobilebar || isSidebarOpen) && (
              <ExpandMore fontSize="small" />
            )}
      </ListItem>
      <Collapse in={isItemOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding $isSidebarOpen={isSidebarOpen}>
          {links.map((link) => {
            if (sensorType === 'VOLTAWARE_SENSOR' && link.disabledForSensorType?.VOLTAWARE_SENSOR) {
              return null;
            }
            return (
              <NestedListItem
                key={link.url}
                component={NavLink}
                to={link.url}
                onClick={closeMobilebar}
                $isDisabled={!!link.disabledForSensorType?.[sensorType]}
              >
                <ListItemText primary={link.title} $isDisabled={!!link.disabledForSensorType?.[sensorType]} />
              </NestedListItem>
            );
          })}
        </List>
      </Collapse>
      <Popover
        id="popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <PopoverWrapper>
          {links.map((link) => (
            <MenuItem
              key={link.url}
              to={link.url}
              component={NavLink}
              onClick={handleMenuClose}
            >
              {link.title}
            </MenuItem>
          ))}
        </PopoverWrapper>
      </Popover>
    </>
  );
}

NavigationItem.propTypes = {
  sensorType: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.element,
  links: PropTypes.array,
  main: PropTypes.string,
  closeMobilebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  isItemOpen: PropTypes.bool.isRequired,
  handleOpenItem: PropTypes.func.isRequired,
  handleCloseItem: PropTypes.func.isRequired,
};

export default NavigationItem;
