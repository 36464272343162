import { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { startOfDay } from 'date-fns';
import CurrencyFormatter from 'currency-formatter';

import { SensorContext } from '../../utils/context';
import usePageUnavailable from '../../hooks/usePageUnavailable';
import Unavailable from '../../components/Unavailable';
import ContentWrapper from '../../components/ContentWrapper';
import DateArrow from '../../components/DateArrow';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import DatePicker from '../../components/DatePicker';
import Card from '../../components/Card';
import Graph from '../../components/Graph';
import CenteredLoader from '../../components/CenteredLoader';
import {
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  MINIMUM_DATE_ERROR_MESSAGE,
  SENSOR_ID_PLACEHOLDER,
  NO_DATA_FOUND_ERROR_MESSAGE,
} from '../../utils/constants';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const CardContainer = styled(Grid)`
  margin-bottom: 10px;
`;

function BreakdownDay() {
  const sensor = useContext(SensorContext);
  const isPageUnavailable = usePageUnavailable();
  const [selectedDate, handleDateChange] = useState(moment().toDate());
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const sensorId = sensor.id || SENSOR_ID_PLACEHOLDER;
  const formattedDate = moment(selectedDate).format('yyyy-MM-DD');

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    if (
      sensor.body &&
      sensor.body.data &&
      sensor.body.data.data.first_event &&
      startOfDay(selectedDate) <
        startOfDay(moment(sensor.body.data.data.first_event).toDate())
    ) {
      setIsValid(false);
      return setValidationError(
        MINIMUM_DATE_ERROR_MESSAGE(
          moment(sensor.body.data.data.first_event).format('DD MMM yyyy')
        )
      );
    }

    setIsValid(true);
    return setValidationError('');
  }, [selectedDate, sensor.id, sensor]);

  const response = useQuery(
    ['breakdownDay', sensor.id, formattedDate],
    () =>
      axios.get(
        `/sensors/${sensorId}/stats/breakdown/day?date=${formattedDate}`
      ),
    {
      enabled: isValid,
      retry: 0,
    }
  );

  const getGraphData = (data) => {
    const dataArray = data && data.data && data.data.breakdownDescriptions;
    return (
      dataArray &&
      dataArray.map((item) => {
        const parsedTime = moment(startOfDay(new Date()))
          .add(60 * item.period, 'minutes')
          .format('HH:mm:ss');
        return {
          x: parsedTime,
          Consumption: item.consumption.actualRaw,
          Cost: item.cost.actualRaw,
        };
      })
    );
  };

  const disableForward = moment().isSame(selectedDate, 'day');

  const updateDate = (dir) => {
    if (dir === 'backwards') {
      handleDateChange(old => moment(old).subtract(1, 'day').toDate());
    } else {
      handleDateChange(old => moment(old).add(1, 'day').toDate());
    }
  }

  if (isValid && isPageUnavailable) {
    return <Unavailable pageName={isPageUnavailable} />
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle
          filter={
            <div>
              <DateArrow
                dir='backward'
                disabled={response.isLoading}
                onClick={() => updateDate('backwards')}
              />
              <DatePicker
                autoOk
                id="date-picker"
                label="Date"
                value={selectedDate}
                onChange={handleDateChange}
                disableFuture
                variant="inline"
                format="dd MMM yyyy"
                error={Boolean(validationError)}
                last="true"
              />
              <DateArrow
                dir='forward'
                disabled={response.isLoading || disableForward}
                onClick={() => updateDate('forwards')}
              />
            </div>
          }
        >
          Breakdown by day
        </PageTitle>
        <Typography paragraph>
          View cost and consumption breakdown metrics for a specific day.
        </Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {!validationError && response.isSuccess && (
          <CardContainer container spacing={2}>
            <Card
              xs={12}
              sm={6}
              title="Consumption"
              data={`${new Intl.NumberFormat(sensor.isoLocaleCode).format(
                response.data.data.consumption.actualRaw
              )} kWh`}
            />
            <Card
              xs={12}
              sm={6}
              title="Cost"
              data={CurrencyFormatter.format(
                response.data.data.cost.actualRaw,
                {
                  locale: sensor.isoLocaleCode,
                }
              )}
            />
          </CardContainer>
        )}
        {!validationError && response.isSuccess && (
          <Graph
            data={getGraphData(response.data)}
            y1="Consumption"
            y2="Cost"
            y1UnitFormatter={(value) =>
              `${new Intl.NumberFormat(sensor.isoLocaleCode).format(value)} kWh`
            }
            y2UnitFormatter={(value) =>
              CurrencyFormatter.format(parseFloat(value), {
                locale: sensor.isoLocaleCode,
              })
            }
            interval={2}
            xAxisHeight={70}
          />
        )}
        {response.isError && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${sensorId}/stats/breakdown/day?date=${formattedDate}`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default BreakdownDay;
