import moment from 'moment';
import CurrencyFormatter from 'currency-formatter';

import busyHouseImg from '../../images/busy-house.png';
import sleepingHouseImg from '../../images/sleeping-house.png';

import { sortAppliancesDescending } from '../../utils/functions';
import { LOW_RES_APPLIANCES, APPLIANCES } from '../../utils/constants';


/**
* Clamp delta
* Maps $delta from a certain threshold's value to a specific color/label.
*/
export const clampDelta = (delta) => {
  const thresholds = [
   {value: 40, color: '#309647', label: `TOP ${delta}%` },
   {value: 50, color: '#F2A100', label: `TOP ${delta}%` },
   {value: 100, color: '#CC0000', label: `BOTTOM ${101-delta}%` },
  ];
  for (let i=0; i<thresholds.length; i++) {
    if (delta <= thresholds[i].value) {
      return thresholds[i];
    }
  }
 }
/**
* Clamp value
* Maps $num from a certain threshold's decimal to a specific percent.
*/
export const clampValue = (num) => {
 const thresholds = [
  // {decimal: 100, percent: 90},
  {decimal: 80, percent: 66},
  {decimal: 50, percent: 50},
  {decimal: 20, percent: 33},
  {decimal: 0, percent: 0},
 ];
 for (let i=0; i<thresholds.length; i++) {
   if (num >= thresholds[i].decimal) {
     return thresholds[i].percent;
   }
 }
}

export const voltaColor = (score) => {
  if (score < 2.5) {
    return { img: 'RED', color: '#CC0000'};
  }
  if (score < 3.0) {
    return { img: 'ORANGE', color: '#F2A100'};
  }

  return { img: 'GREEN', color: '#6AB42D'};
}

export const getChartData = (response, sensor) => {
  const daysArray = sensor.type === 'LOW_RESOLUTION_METER' ? response?.data?.day_by_day_metrics?.days : response?.data?.days_detail;

  if (!daysArray?.length) {
    return null;
  }
  
  let data = null, highlights = null, disaggregation = null;
  if (sensor.type === 'LOW_RESOLUTION_METER') {
    const weekends = {};
    data = daysArray.map((item) => {
      const day = moment(item.day).format('Do');
      if (item.weekend) {
        weekends[day] = { color: '#999999' };
      }
      return {
        x: day,
        Consumption: item.total_consumption,
      };
    });

    const min = response?.data?.day_by_day_metrics?.min_month_consumption;
    const max = response?.data?.day_by_day_metrics?.max_month_consumption;

    const minIndex = daysArray.findIndex(item => item.day === min?.day);
    const maxIndex = daysArray.findIndex(item => item.day === max?.day);

    // Create statistical min/max and default to computed data if min/max is not
    // available in the payload
    const statisticalMinMax = {
      minIndex: minIndex > -1 ? minIndex : daysArray.reduce((min, curr, index) => curr.total_consumption < daysArray[min].total_consumption ? index : min, 0),
      maxIndex: maxIndex > -1 ? maxIndex : daysArray.reduce((max, curr, index) => curr.total_consumption > daysArray[max].total_consumption ? index : max, 0),
      average: response?.data?.day_by_day_metrics?.average_month_consumption || daysArray.reduce((acc, curr) => curr.total_consumption+acc, 0)/daysArray.length,
    };

    statisticalMinMax.indicesApart = min?.day && max?.day ? (Math.max(statisticalMinMax.minIndex, statisticalMinMax.maxIndex) - Math.min(statisticalMinMax.minIndex, statisticalMinMax.maxIndex) - 1) : 1;

    highlights = {
      y1Highlights: {
        ...((min?.day || max?.day) && {
          key: 'x',
          indices: {
            total: daysArray.length,
            indicesApart: statisticalMinMax.indicesApart,
            minIndex: statisticalMinMax.minIndex,
            maxIndex: statisticalMinMax.maxIndex,
          },
          values: {
            ...weekends,
            ...(max?.day && {
              [moment(max.day).format('Do')]: {
                color: '#cc0000',
                icon: {
                  size: 35,
                  element: (
                    <image
                      href={busyHouseImg} width={50} height={50} />
                  ),
                  offset: {
                    x: -8,
                    y: -4,
                  },
                  label: `Max Day\n${moment(max.day).format('MMM Do')}\n${new Intl.NumberFormat(sensor.isoLocaleCode).format(max.total_consumption.toFixed(2))} kWh`,
                }
              },
            }),
            ...(min?.day && {
              [moment(min.day).format('Do')]: {
                color: '#57add9',
                icon: {
                  size: 35,
                  element: (
                    <image
                      href={sleepingHouseImg} width={50} height={50} />
                  ),
                  offset: {
                    x: 1,
                    y: -4,
                  },
                  label: `Min Day\n${moment(min.day).format('MMM Do')}\n${new Intl.NumberFormat(sensor.isoLocaleCode).format(min.total_consumption.toFixed(2))} kWh`,
                }
              },
            }),
          },
        }),
        average: {
          value: statisticalMinMax.average,
          color: '#f2a100',
          label: {
            text: `Avg: ${new Intl.NumberFormat(sensor.isoLocaleCode).format(statisticalMinMax.average.toFixed(2))} kWh`,
            offset: { x: 85, y: 5 },
          },
          dash: '5, 5'
        },
      },
    };

    const disaggregationMetrics = response?.data?.disaggregation_metrics;
    if (disaggregationMetrics?.appliances?.length) {
      const appliances = disaggregationMetrics.appliances;
      const totalConsumption = disaggregationMetrics.total_consumption ?? 0;
      
      let missingPercentPoints = 100;
      const dataArray = appliances
        .map((item) => { // Initial iteration to find percent remainders
          const remainder = item.consumption/(totalConsumption||1)*100;
          const percentage = parseInt(remainder);
          missingPercentPoints -= percentage;
          
          return {
            ...item,
            remainder: remainder-percentage,
            percentage: percentage,
          }
        })
        .sort((a, b) => b.remainder-a.remainder) // Sort by biggest to smallest
        .map((item, index, arr) => { // Fix percentage so that they add up to 100%
          let consumptionPercentage = item.percentage;

          if (missingPercentPoints > 0) {
            if (!arr?.[index+1]?.percentage) {
              consumptionPercentage += missingPercentPoints;
              missingPercentPoints = 0;
            } else {
              consumptionPercentage += 1;
              missingPercentPoints -= 1;
            }
          }
          return {
            key: item.appliance_key,
            title: LOW_RES_APPLIANCES[item.appliance_key]?.label ?? item.appliance_key,
            color: LOW_RES_APPLIANCES[item.appliance_key]?.color ?? LOW_RES_APPLIANCES.others.color,
            icon: LOW_RES_APPLIANCES[item.appliance_key]?.icon ?? LOW_RES_APPLIANCES.others.icon,
            consumption: +((item.consumption/1000).toFixed(2)),
            consumptionPercentage: consumptionPercentage,
          }
        });

      disaggregation = {
        appliances: sortAppliancesDescending(dataArray),
        totalConsumption: parseInt(Math.round(totalConsumption/1000)),
      };
    }
  } else {
    data = daysArray.map((item) => {
      const { cost, consumption } = item.total;
      return {
        x: moment(item.date).format('Do'),
        Consumption: consumption.actualRaw,
        Cost: cost.actualRaw,
      };
    });

    const keyDays = response?.data?.key_days;

    // For volta/high-res, if there's no key days then do not display highlights
    if (keyDays) {
      highlights = {
        y1Highlights: {
          key: 'x',
          values: {
            /**
             *  If chart cell's entry.x = '25th', then
             *  color = y1Highlights.values.['25th'].color
             *  icon = y1Highlights.values.['25th'].icon
             */
            [moment(keyDays.busiest_day.date).format('Do')]: {
              color: '#2d672f',
            },
            [moment(keyDays.least_active_day.date).format('Do')]: {
              color: '#b5deb6',
            },
          },
          average: {
            value: keyDays.average.consumption.actualRaw,
            color: '#f2a100',
            label: {
              text: `Avg: ${new Intl.NumberFormat(sensor.isoLocaleCode).format(keyDays.average.consumption.actualRaw.toFixed(2))} kWh`,
              offset: { x: 85, y: 5 },
            },
            dash: '5, 5'
          }
        },
        y2Highlights: {
          key: 'x',
          values: {
            [moment(keyDays.busiest_day.date).format('Do')]: {
              color: '#b8150a',
            },
            [moment(keyDays.least_active_day.date).format('Do')]: {
              color: '#f99b94',
            },
          },
          average: {
            value: keyDays.average.cost.actualRaw,
            color: '#f2a100',
            label: {
              text: `Avg: ${CurrencyFormatter.format(parseFloat(keyDays.average.cost.actualRaw.toFixed(2)), {
                locale: sensor.isoLocaleCode,
              })}`,
              offset: { x: 85, y: 5 },
            },
            dash: '5, 5'
          }
        },
      };
    }

    const disaggregationData = response?.data?.disaggregation;
    if (disaggregationData && Object.keys(disaggregationData)?.length) {
      const applianceKeys = Object.keys(disaggregationData);
      const totalConsumption = response?.data?.summary?.current?.total?.consumption?.actual ?? 0;

      const dataArray = applianceKeys.map((key) => {
        const item = disaggregationData[key];
        return {
          title: APPLIANCES[key]?.label ?? key,
          color: APPLIANCES[key]?.color ?? LOW_RES_APPLIANCES.others.color,
          consumption: +(item.consumption.toFixed(2)),
          consumptionPercentage: item.consumption_percentage,
        };
      });

      disaggregation = {
        appliances: sortAppliancesDescending(dataArray),
        totalConsumption: totalConsumption,
      };
    }
  }

  return { data, highlights, disaggregation };
}

export const getReportData = (data, sensor) => {
  let score = null;
  let totalConsumption = 0;
  let busiestDay = null;
  let leastActiveDay = null;
  let average = null;
  let carbonFootprint = null;
  
  if (sensor.type === 'LOW_RESOLUTION_METER') {
    score = data?.score && {
      overall: parseFloat(data.score.overall.toFixed(1)),
      appliances: data.score.scores?.length && data.score.scores.sort((a, b) => b.score - a.score).map((item) => 
        ({
          ...item,
          key: item.appliance_key,
          title: LOW_RES_APPLIANCES[item.appliance_key]?.label ?? item.appliance_key,
          color: LOW_RES_APPLIANCES[item.appliance_key]?.color ?? LOW_RES_APPLIANCES.others.color,
          icon: LOW_RES_APPLIANCES[item.appliance_key]?.icon ?? LOW_RES_APPLIANCES.others.icon,
          score: parseFloat(item.score.toFixed(1)),
        })
      ),
      rankPercentage: clampDelta(data.score?.rank_percentage ?? 0),
    };
    totalConsumption = data?.day_by_day_metrics?.total_month_consumption && {
      raw: parseInt(Math.round(data.day_by_day_metrics.total_month_consumption)),
      formatted: new Intl.NumberFormat(sensor.isoLocaleCode).format(parseInt(Math.round(data.day_by_day_metrics.total_month_consumption))),
    };
    busiestDay = data?.day_by_day_metrics?.max_month_consumption && {
      day: moment(data.day_by_day_metrics.max_month_consumption.day).format('ddd DD MMMM'),
      consumption: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.day_by_day_metrics.max_month_consumption.total_consumption.toFixed(2)),
    };
    leastActiveDay = data?.day_by_day_metrics?.min_month_consumption && {
      day: moment(data.day_by_day_metrics.min_month_consumption.day).format('ddd DD MMMM'),
      consumption: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.day_by_day_metrics.min_month_consumption.total_consumption.toFixed(2)),
    };
    const week = data?.day_by_day_metrics?.days.reduce((total, day) => {
      const type = day.weekend ? 'weekends' : 'weekdays';
      return {...total, [type]: total[type]+1}
    }, { weekdays: 0, weekends: 0 });
    average = data?.day_by_day_metrics?.average_month_consumption && {
      consumption: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.day_by_day_metrics.average_month_consumption.toFixed(2)),
      weekday: {
        consumption: new Intl.NumberFormat(sensor.isoLocaleCode).format(
          (data.day_by_day_metrics.total_consumed_on_week_days/week.weekdays).toFixed(2),
        ),
      },
      weekend: {
        consumption: new Intl.NumberFormat(sensor.isoLocaleCode).format(
          (data.day_by_day_metrics.total_consumed_on_weekend_days/week.weekends).toFixed(2),
        ),
      },
    };
    carbonFootprint = data?.carbon_footprint && {
      kgco2: Math.trunc(data.carbon_footprint.kgco2),
      trees: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.carbon_footprint.trees.toFixed(2)),
    };
  } else {
    totalConsumption = data?.summary?.current?.total?.consumption?.actual && {
      raw: data.summary.current.total.consumption.actual,
      formatted: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.summary.current.total.consumption.actual),
    };
    busiestDay = data?.key_days?.busiest_day && {
      day: moment(data.key_days.busiest_day.date).format('ddd DD MMMM'),
      cost: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.key_days.busiest_day.total.cost.actual),
      consumption: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.key_days.busiest_day.total.consumption.actual),
    };
    leastActiveDay = data?.key_days?.least_active_day && {
      day: moment(data.key_days.least_active_day.date).format('ddd DD MMMM'),
      cost: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.key_days.least_active_day.total.cost.actual),
      consumption: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.key_days.least_active_day.total.consumption.actual),
    };
    average = data?.key_days?.average && {
      cost: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.key_days.average.cost.actual),
      consumption: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.key_days.average.consumption.actual),
      ...(data?.key_days?.average?.weekday &&
        {
          weekday: {
            cost: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.key_days.average.weekday.cost.actualRaw.toFixed(2)),
            consumption: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.key_days.average.weekday.consumption.actualRaw.toFixed(2)),
          },
        }
      ),
      ...(data?.key_days?.average?.weekend &&
        {
          weekend: {
            cost: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.key_days.average.weekend.cost.actualRaw.toFixed(2)),
            consumption: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.key_days.average.weekend.consumption.actualRaw.toFixed(2)),
          },
        }
      ),
    };
    carbonFootprint = data?.carbon_footprint && {
      kgco2: Math.trunc(data.carbon_footprint.kgCO2),
      trees: new Intl.NumberFormat(sensor.isoLocaleCode).format(data.carbon_footprint.number_trees.toFixed(2)),
    };
  }

  return {
    score,
    totalConsumption,
    busiestDay,
    leastActiveDay,
    average,
    carbonFootprint,
  };
}
