import React from 'react';
import styled from 'styled-components';

const StyledLegend = styled.ul`
  padding: 0px;
  margin: 0px;
  text-align: center;
`;

const StyledLegendItem = styled.li`
  display: inline-block;
  margin-right: 10px;

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
  }

  span {
    color: ${({ color }) => color};
  }
`;

export default function CustomLegend({ payload, colors }) {
  return (
    <StyledLegend className="recharts-default-legend">
      {payload.map((item, index) => (
        <StyledLegendItem key={`custom-legend-item-${index}`} className={`recharts-legend-item legend-item-${index}`} color={colors?.[index] || item.color}>
          <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1">
            <title></title>
            <desc></desc>
            <path stroke="none" fill={colors?.[index] || item.color} d="M0,4h32v24h-32z" className="recharts-legend-icon"></path>
          </svg>
          <span className="recharts-legend-item-text">{item.value}</span>
        </StyledLegendItem>
      ))}
    </StyledLegend>
  );
}
