import { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import { SensorContext } from '../../utils/context';
import ContentWrapper from '../../components/ContentWrapper';
import PageTitle from '../../components/PageTitle';
import CenteredLoader from '../../components/CenteredLoader';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import {
  SENSOR_ID_PLACEHOLDER,
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  NO_DATA_FOUND_ERROR_MESSAGE,
} from '../../utils/constants';
import AirConditionerGrayIcon from '../../images/appliances/aircon-gray.svg';
import AirConditionerIcon from '../../images/appliances/aircon.svg';
import DishwasherGrayIcon from '../../images/appliances/dishwasher-gray.svg';
import DishwasherIcon from '../../images/appliances/dishwasher.svg';
import ElectricShowerGrayIcon from '../../images/appliances/electric-shower-gray.svg';
import ElectricShowerIcon from '../../images/appliances/electric-shower.svg';
import FridgeFreezerGrayIcon from '../../images/appliances/fridge-freezer-gray.svg';
import FridgeFreezerIcon from '../../images/appliances/fridge-freezer.svg';
import ElectricHeaterGrayIcon from '../../images/appliances/electric-heater-gray.svg';
import ElectricHeaterIcon from '../../images/appliances/electric-heater.svg';
import ElectricStoveGrayIcon from '../../images/appliances/electric-stove-gray.svg';
import ElectricStoveIcon from '../../images/appliances/electric-stove.svg';
import ElectricBoilerGrayIcon from '../../images/appliances/electric-boiler-gray.svg';
import ElectricBoilerIcon from '../../images/appliances/electric-boiler.svg';
import ElectricVehicleGrayIcon from '../../images/appliances/electric-vehicle-gray.svg';
import ElectricVehicleIcon from '../../images/appliances/electric-vehicle.svg';
import KettleGrayIcon from '../../images/appliances/kettle-gray.svg';
import KettleIcon from '../../images/appliances/kettle.svg';
import TumbleDryerGrayIcon from '../../images/appliances/tumble-dryer-gray.svg';
import TumbleDryerIcon from '../../images/appliances/tumble-dryer.svg';
import WashingMachineGrayIcon from '../../images/appliances/washing-machine-gray.svg';
import WashingMachineIcon from '../../images/appliances/washing-machine.svg';
import ElectricOvenGrayIcon from '../../images/appliances/electric-oven-gray.svg';
import ElectricOvenIcon from '../../images/appliances/electric-oven.svg';
import OtherGrayIcon from '../../images/appliances/other-grey.svg';
import OtherIcon from '../../images/appliances/other.svg';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledChip = styled(Chip)`
  margin-right: 10px;
  margin-bottom: 10px;
`;

function Appliances() {
  const sensor = useContext(SensorContext);
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    setIsValid(true);
    return setValidationError('');
  }, [sensor.id, sensor]);

  const response = useQuery(
    ['appliances', sensor.id],
    () => axios.get(`/sensors/${id}/metadata/appliances`),
    {
      enabled: isValid,
      retry: 0,
    }
  );

  const getDisplayArray = (appliances) => {
    const displayObject = {
      AC: {
        label: 'Air Conditioner',
        quantity: 0,
        grayIcon: AirConditionerGrayIcon,
        icon: AirConditionerIcon,
      },
      DISHWASHER: {
        label: 'Dishwasher',
        quantity: 0,
        grayIcon: DishwasherGrayIcon,
        icon: DishwasherIcon,
      },
      ELECTRIC_BOILER: {
        label: 'Electric Boiler',
        quantity: 0,
        grayIcon: ElectricBoilerGrayIcon,
        icon: ElectricBoilerIcon,
      },
      ELECTRIC_HEATING: {
        label: 'Electric Heating',
        quantity: 0,
        grayIcon: ElectricHeaterGrayIcon,
        icon: ElectricHeaterIcon,
      },
      ELECTRIC_OVEN: {
        label: 'Electric Oven',
        quantity: 0,
        grayIcon: ElectricOvenGrayIcon,
        icon: ElectricOvenIcon,
      },
      ELECTRIC_SHOWER: {
        label: 'Electric Shower',
        quantity: 0,
        grayIcon: ElectricShowerGrayIcon,
        icon: ElectricShowerIcon,
      },
      ELECTRIC_STOVE: {
        label: 'Electric Stove',
        quantity: 0,
        grayIcon: ElectricStoveGrayIcon,
        icon: ElectricStoveIcon,
      },
      ELECTRIC_VEHICLE: {
        label: 'Electric Vehicle',
        quantity: 0,
        grayIcon: ElectricVehicleGrayIcon,
        icon: ElectricVehicleIcon,
      },
      FRIDGE: {
        label: 'Fridge & Freezer',
        quantity: 0,
        grayIcon: FridgeFreezerGrayIcon,
        icon: FridgeFreezerIcon,
      },
      GAS_HEATING: {
        label: 'Gas Heating',
        quantity: 0,
        grayIcon: ElectricHeaterGrayIcon,
        icon: ElectricHeaterIcon,
      },
      KETTLE: {
        label: 'Kettle',
        quantity: 0,
        grayIcon: KettleGrayIcon,
        icon: KettleIcon,
      },
      TUMBLE_DRYER: {
        label: 'Tumble Dryer',
        quantity: 0,
        grayIcon: TumbleDryerGrayIcon,
        icon: TumbleDryerIcon,
      },
      WASHING_MACHINE: {
        label: 'Washing Machine',
        quantity: 0,
        grayIcon: WashingMachineGrayIcon,
        icon: WashingMachineIcon,
      },
      OTHERS: {
        label: 'Others',
        quantity: 0,
        grayIcon: OtherGrayIcon,
        icon: OtherIcon,
      },
    };

    appliances.map((item) => {
      if (!displayObject[item.key] && item.amount) {
        return (displayObject.OTHERS.quantity += item.amount);
      }

      if (displayObject[item.key] && item.amount) {
        return (displayObject[item.key].quantity += item.amount);
      }

      return null;
    });

    return Object.values(displayObject);
  };

  const id = sensor.id || SENSOR_ID_PLACEHOLDER;
  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle>View property appliances</PageTitle>
        <Typography paragraph>View property appliances.</Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isError && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {response.isSuccess && (
          <ChipWrapper>
            {getDisplayArray(response.data.data.appliances).map((item) => {
              const mainHeatingSource = item.label.includes('(main)');
              return <StyledChip
                key={item.label}
                avatar={
                  <Avatar>
                    {item.quantity && item.quantity > 0 ? (
                      <img src={item.icon} alt="icon" />
                    ) : (
                      <img src={item.grayIcon} alt="icon" />
                    )}
                  </Avatar>
                }
                label={`${item.label}${mainHeatingSource ? '' : ` ${item.quantity || 0}`}`}
                color={
                  item.quantity && item.quantity > 0 ? 'primary' : 'default'
                }
              />;
            })}
          </ChipWrapper>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${id}/metadata/appliances`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default Appliances;
