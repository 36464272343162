import React from 'react';
import styled from 'styled-components';

const StyledTooltip = styled.div`
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;

  & > p {
    margin: 0;
  }

  & > ul {
    padding: 0;
    margin: 0;
  }
`;

const StyledTooltipItem = styled.li`
  display: block;
  padding-top: 4px;
  padding-bottom: 4px;
  color: ${({ color }) => color};
`;

export default function CustomTooltip({ active, payload, label, colors, formatter, separator }) {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip className="recharts-default-tooltip">
        <p className="recharts-tooltip-label">{label}</p>
        <ul className="recharts-tooltip-item-list">
          {payload.map((item, index) => (
            <StyledTooltipItem key={`tooltip-item-${index}`} className="recharts-tooltip-item" color={colors?.[index] || item.color}>
              <span className="recharts-tooltip-item-name">{item.name}</span>
              <span className="recharts-tooltip-item-separator">{separator}</span>
              <span className="recharts-tooltip-item-value">{formatter ? formatter(item.value, item.name) : item.value}</span>
              <span className="recharts-tooltip-item-unit"></span>
            </StyledTooltipItem>
          ))}
        </ul>
      </StyledTooltip>
    );
  }

  return null;
};
