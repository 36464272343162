import ChildFriendlyRoundedIcon from '@material-ui/icons/ChildFriendlyRounded';
import FaceIcon from '@material-ui/icons/Face';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';

import AirConditioningIcon from '../images/appliances/color/air-conditioning.svg';
import CookingIcon from '../images/appliances/color/cooking.svg';
import ElectricVehicleIcon from '../images/appliances/color/electric-vehicle.svg';
import FridgeIcon from '../images/appliances/color/fridge.svg';
import HeatingIcon from '../images/appliances/color/heating.svg';
import DishwasherIcon from '../images/appliances/color/dishwasher.svg';
import KettleIcon from '../images/appliances/color/kettle.svg';
import LightsAndElectronicsIcon from '../images/appliances/color/lights-and-electronics.svg';
import OthersIcon from '../images/appliances/color/others.svg';
import StandbyIcon from '../images/appliances/color/standby-always-on.svg';
import WashingAppliancesIcon from '../images/appliances/color/washing-appliances.svg';
import BoilerIcon from '../images/appliances/color/boiler.svg';
import TumbleDryerIcon from '../images/appliances/color/tumble-dryer.svg';
import CookingRadiatorIcon from '../images/appliances/color/cooking-radiator.svg';

export const INCORRECT_CREDENTIALS_ERROR_MESSAGE =
  'Username or password is incorrect';
export const SERVER_ERROR_MESSAGE = 'An error occurred, please try again later';
export const SENSOR_NOT_SELECTED_ERROR_MESSAGE = 'Sensor not selected';
export const MINIMUM_DATE_ERROR_MESSAGE = (date) =>
  `Minimum date is sensor's first event: ${date}`;
export const TO_DATE_AFTER_FROM_DATE_ERROR_MESSAGE =
  'To Date must be after From Date';
export const DISAGGREGATION_YESTERDAY_ERROR_MESSAGE =
  'Disaggregation data is available up to yesterday';
export const DISAGGREGATION_SAME_DAY_ERROR_MESSAGE =
  'If you want data for one day, go to Disaggregation by Day screen instead';
export const MONTH_RANGE_ERROR_MESSAGE =
  'The dates must be in a range of one month from each other';
export const DIFFERENT_MONTH_ERROR_MESSAGE =
  'The two dates must be in the same month';
export const REPORT_LAST_MONTH_ERROR_MESSAGE =
  'Monthly report is available up to last month';
export const MINIMUM_MONTH_ERROR_MESSAGE = (date) =>
  `Minimum month is sensor's first event: ${date}`;
export const NO_DATA_FOUND_ERROR_MESSAGE = 'No data found';

export const SENSOR_ID_PLACEHOLDER = '{sensor_id}';

export const MILLISECONDS_IN_A_MONTH = 2678400000;

export const APPLIANCES = {
  cooking: { label: 'Cooking Appliances', color: '#A74286' },
  heating: { label: 'Heating', color: '#816bb5' },
  heating_cooking: {
    label: 'Heating & Cooking Appliances',
    color: '#816bb5',
  },
  air_conditioner: {
    label: 'Air Conditioner',
    color: '#4f91a5',
  },
  fridge_freezer: {
    label: 'Fridge & Freezer',
    color: '#4271bd',
  },
  dishwasher: { label: 'Dishwasher', color: '#aaf2ee' },
  washing: { label: 'Washing Appliances', color: '#1c9991' },
  washing_machine: {
    label: 'Washing Machine',
    color: '#1c9991',
  },
  kettle: {
    label: 'Kettle',
    color: '#F06292',
  },
  tumble_dryer: {
    label: 'Tumble Dryer',
    color: '#a694d4',
  },
  boiler: { label: 'Boiler', color: '#136b66', value: 'BOILER' },
  electric_shower: {
    label: 'Electric Shower',
    color: '#52ccc5',
  },
  electric_vehicle: {
    label: 'Electric Vehicle',
    color: '#2C5AFF',
  },
  EV: {
    label: 'Electric Vehicle',
    color: '#2C5AFF',
  },
  standby: { label: 'Standby', color: '#57add9' },
  lighting_entertainment: {
    label: 'Lighting & Entertainment Appliances',
    color: '#ffcc00',
  },
  others: {
    label: 'Others',
    color: '#999',
  },
};

export const SCORE_RECOMMENDATIONS = {
  DEFAULT: {
    title: '',
    description: 'Energy Saving Recommendation',
    icon: StandbyIcon,
  },
  THERMOSTAT_AWAY_FROM_HEAT_AIR_CONDITIONER: {
    icon: AirConditioningIcon,
    title: "Keep your AC thermostat in a cool place",
    description: "Make sure your thermostat is away from a heating source or the sun light."
  },
  THERMOSTAT_CORRECT_TEMP_AIR_CONDITIONER: {
    icon: AirConditioningIcon,
    title: "Set your AC to an optimal temperature",
    description: "During the summer, you should set your AC between 25ºC and 27ºC. These temperatures will be comfortable for you and will help you save money."
  },
  ISOLATE_WINDOWS_AIR_CONDITIONER: {
    icon: AirConditioningIcon,
    title: "Insulation is key",
    description: "Changing your windows could help you save a lot in heating and cooling. Making sure that your house is properly insulated can make a huge difference."
  },
  SET_HOT_TEMPERATURE_BOILER: {
    icon: BoilerIcon,
    title: "Set your Boiler water to the right temperature",
    description: "To save energy and reduce environmental impact, consider setting the hot water temperature between 45°C and 55°C. This can save up to 5% on energy consumption and be more efficiently operated by the boiler."
  },
  INSTALL_CHRONOTHERMOSTAT_BOILER: {
    icon: BoilerIcon,
    title: "A boiler's chronothermostat is your ally",
    description: "To save on boiler consumption, consider installing a chronothermostat. It allows you to manage the temperature in a centralized and precise way, offering advanced functions like different temperatures for each room and boiler operating times. Modern models can provide up to 20% savings and communicate with the boiler to manage its ignition more efficiently."
  },
  BOIL_WATER_IN_KETTLE_COOKING: {
    icon: CookingIcon,
    title: "Use a kettle to boil or preheat water for cooking",
    description: "Using a kettle for boiling water is more efficient and convenient than using the stove. It offers speed and energy efficiency benefits. Kettles heat water faster than a stove, saving you time in the kitchen. They are also designed to be more energy-efficient, using less electricity compared to the energy consumed by a stove."
  },
  RIGHT_SIZE_PAN_COOKING: {
    icon: CookingIcon,
    title: "Match your pan size to the burner size",
    description: "When using electric cooktops, it's important to match the pan size to the burner size. Using a small pan on a large burner can result in significant heat loss and inefficiency. By choosing pan sizes that match the burner sizes, you can maximize the heat produced and save energy."
  },
  BATCH_COOKING: {
    icon: CookingIcon,
    title: "Batch cooking is the way to go",
    description: "Plan ahead and do a batch cooking. By using your oven to cook several things at the same time you can save a lot of energy and you won't need to cook every day of the week."
  },
  PRESSURE_COOKER_COOKING: {
    icon: CookingIcon,
    title: "Consider buying a pressure cooker or using it as often as possible",
    description: "Think about using a pressure cooker instead of a regular pot. This is more efficient and can help you save up a lot of energy. If you don't have one, you can always cover your pots to avoid the heat to escape."
  },
  SMALL_PIECES_COOKING: {
    icon: CookingIcon,
    title: "Cooking smaller food bites can help you save energy",
    description: "Did you know that by cutting your food in small pieces you can reduce the cooking time and your energy consumption"
  },
  FULL_DISHWASHER: {
    icon: DishwasherIcon,
    title: "Don't run a half-empty dishwasher",
    description: "Running a full dishwasher is more energy efficient and will reduce energy consumption over time."
  },
  USE_ECO_DISHWASHER: {
    icon: DishwasherIcon,
    title: "Use your dishwasher's ECO mode",
    description: "Using eco mode on your dishwasher is an effective way to save energy, it optimize its performance and reduce energy consumption."
  },
  CHARGE_EVENING_ELECTRIC_VEHICLE: {
    icon: ElectricVehicleIcon,
    title: "Night time is the right time to charge your EV",
    description: "Charge your Electric Vehicle during the night, this is eco-friendlier and in case you have a dual tariff it will help you save money."
  },
  OPTIMIZE_DRIVING_ELECTRIC_VEHICLE: {
    icon: ElectricVehicleIcon,
    title: "Drive your EV smoothly",
    description: "By driving smoothly and using ECO mode or adaptive cruise control, you can optimize your driving. These practices help reduce energy consumption, improve efficiency, and reduce emissions."
  },
  AVOID_HOT_FOOD_FRIDGE_FREEZER: {
    icon: FridgeIcon,
    title: "Wait for your leftover to cool down",
    description: "After cooking, wait for your food to cool down before storing it in the fridge. By avoiding storing hot dishes you will reduce your fridge consumption over time."
  },
  CHECK_TEMPERATURE_FRIDGE_FREEZER: {
    icon: FridgeIcon,
    title: "Set your fridge and freezer to the right temperature",
    description: "The optimum temperature for refrigerator operation is between 2°C and 5°C, and -18°C for freezer. As a rule of thumb, for each additional degree of refrigeration your fridge will consume 6% more electricity. An accurate temperature setting can therefore directly save you money."
  },
  MINIMIZE_OPEN_DOOR_FRIDGE_FREEZER: {
    icon: FridgeIcon,
    title: "Don't procrastinate with the fridge door open",
    description: "Spend as little time as possible with the door open as this will increase the fridge consumption and make sure you close it properly."
  },
  CHECK_RUBBER_SEAL_FRIDGE_FREEZER: {
    icon: FridgeIcon,
    title: "Keep an eye on your fridge rubber seal",
    description: "The rubber seal is used for insulation purposes and prevents heat exchange between the ambient air and the refrigerator. It is important to regularly check that the seal is fully intact and sealing tightly."
  },
  CHECK_LOCATION_FRIDGE_FREEZER: {
    icon: FridgeIcon,
    title: "Your fridge placement is important",
    description: "Never place your refrigerator next to a heat source such as an oven, hob or radiator, and avoid direct sunlight. Regardless of whether you have a freestanding appliance or a built-in one, always ensure that your refrigerator has sufficient ventilation so that the compressor does not end up running continuously."
  },
  DEFROST_FREEZER_FRIDGE_FREEZER: {
    icon: FridgeIcon,
    title: "Defrost frequently the freezer",
    description: "Defrost the freezer compartment if ice has built up. In case there is ice accumulated, your freezer will need to work harder to cool your food, wasting energy in the process."
  },
  DEFROST_FOOD_FRIDGE_FREEZER: {
    icon: FridgeIcon,
    title: "Defrost properly your food",
    description: "Defrost your food in the fridge, not only this is one of the safest way to do it but it also saves energy by cooling your fridge. Make sure to defrost your food a day in advance as it takes longer than other methods."
  },
  ISOLATE_WINDOWS_HEATING: {
    icon: HeatingIcon,
    title: "Insulate your windows",
    description: "Make sure your windows are properly insulated, this will avoid the cold to come in and will avoid heating the house longer than it should."
  },
  SETUP_THERMOSTAT_HEATING: {
    icon: HeatingIcon,
    title: "Set the right heating temperature",
    description: "During the winter, you should set your heating to 19ºC. This temperature will be comfortable and will help you save money."
  },
  BOIL_ACCORDINGLY_KETTLE: {
    icon: KettleIcon,
    title: "Boil only the water you need",
    description: "By boiling only the amount of water required, you avoid wasting energy. This helps reduce water consumption and lower your energy bill."
  },
  TEA_POT_KETTLE: {
    icon: KettleIcon,
    title: "Think about using a tea pot",
    description: "If you plan to drink several cups, we recommend you to use a tea pot, as boiling each individual cup can be more expensive."
  },
  USE_TIMER_SWITCH_LIGHTING_ENTERTAINMENT: {
    icon: LightsAndElectronicsIcon,
    title: "Consider buying a timer switch",
    description: "Lights on but no one home? Leaving your lights on so that your house doesn't look empty while you're away? Then use a timer switch to set them to come on and off. These plug straight into the wall and enable you to programme in several time slots."
  },
  USE_LED_LIGHTING_ENTERTAINMENT: {
    icon: LightsAndElectronicsIcon,
    title: "Switch to LEDs",
    description: "Save up to 85% on energy costs with LEDs, they are the most energy-efficient bulbs available, and will save you money on your energy bills as they are much cheaper to run. They do cost more to buy, but they rarely need replacing."
  },
  POWER_STRIP_STANDBY: {
    icon: StandbyIcon,
    title: "Use a power strip to avoid standby mode",
    description: "Use a power strip with switches to turn off any appliance that might go to standby mode when unused."
  },
  UNPLUG_CHARGER_STANDBY: {
    icon: StandbyIcon,
    title: "Unplug your chargers",
    description: "Did you know that even phone chargers left plugged-in can consume energy when they are not charging your device?"
  },
  FOCUS_HIGH_CONSUMPTION_STANDBY: {
    icon: StandbyIcon,
    title: "Turn off your appliances, don't let them on standby",
    description: "Focus on turning off appliances with a remote (TV, game consoles, etc.) and appliances with a continuous display. They are the most consuming appliances when in standby mode."
  },
  UPGRADE_ROUTER_STANDBY: {
    icon: StandbyIcon,
    title: "Update your router",
    description: "If your router is several years old, a newer model might offer energy-saving features or more efficient components."
  },
  USE_TIMER_STANDBY: {
    icon: StandbyIcon,
    title: "Consider using a timer switch to create daily electrical routines",
    description: "Purchase a timer so you don't have to think about plugging your appliances off. Some appliances that have Standby mode are not used during the night and this could easily save 12 precious hours of low consumption that builds up over time."
  },
  SPIN_DRIED_BEFORE_TUMBLE_DRYER: {
    icon: TumbleDryerIcon,
    title: "Select the right spin speed when washing your clothes before using the tumble dryer",
    description: "Make sure you've correctly spin-dried your clothes before using your tumble dryer, this can help you save precious drying minutes."
  },
  AVOID_TUMBLE_DRYER: {
    icon: TumbleDryerIcon,
    title: "If it's sunny, don't use the tumble dryer",
    description: "Avoid using your tumble dryer when it's sunny outside or during hot seasons. This will increase your clothes lifespan as well as help you save energy."
  },
  USE_ECO_LOW_TEMP_WASHING_MACHINE: {
    icon: WashingAppliancesIcon,
    title: "You don't need to use a very hot cycle to wash your clothes",
    description: "90% of the energy used by a washing machine is for heating up the water. If you run cycles between 30ºC and 40ºC, or in ECO mode in case your machine has it, not only you'll save energy but you will also increase your clothes lifespan."
  },
  FULL_LOAD_WASHING_MACHINE: {
    icon: WashingAppliancesIcon,
    title: "Only wash your clothes when you have a full load",
    description: "Make sure to wait for a full load before washing your clothes. This will help you run less washing cycles per week and reduce your energy consumption."
  },
};

export const LOW_RES_APPLIANCES = {
  cooking: { label: 'Cooking', color: '#A74286', icon: CookingIcon },
  washing: { label: 'Washing Appliances', color: '#1c9991', icon: WashingAppliancesIcon },
  heating: { label: 'Heating', color: '#816bb5', icon: HeatingIcon },
  heating_cooking: {
    label: 'Heating & Cooking',
    color: '#816bb5',
    icon: HeatingIcon,
  },
  cooking_radiator: {
    label: 'Cooking & Radiator',
    color: '#93579E',
    icon: CookingRadiatorIcon,
  },
  cooking_heating: {
    label: 'Heating & Cooking',
    color: '#A74286',
    icon: CookingIcon,
  },
  lights: {
    label: 'Lights & Electronics',
    color: '#ffcc00',
    icon: LightsAndElectronicsIcon,
  },
  cooling: {
    label: 'Air Conditioning',
    color: '#4f91a5',
    icon: AirConditioningIcon,
  },
  fridge: {
    label: 'Fridge',
    color: '#4271bd',
    icon: FridgeIcon,
  },
  boiler: { label: 'Boiler', color: '#136b66', value: 'BOILER', icon: BoilerIcon },
  standby: { label: 'Standby / Always-On', color: '#57add9', icon: StandbyIcon },
  electric_vehicle: {
    label: 'Electric Vehicle',
    color: '#2C5AFF',
    icon: ElectricVehicleIcon,
  },
  EV: {
    label: 'Electric Vehicle',
    color: '#2C5AFF',
    icon: ElectricVehicleIcon,
  },
  others: {
    label: 'Others',
    color: '#999',
    icon: OthersIcon,
  },
};
// 14530
export const AGE_RANGES = {
  ZERO_TO_FIVE: {
    label: 'Zero to Five',
    icon: <ChildFriendlyRoundedIcon />,
  },
  SIX_TO_EIGHTEEN: {
    label: 'Six to Eighteen',
    icon: <FaceIcon />,
  },
  ADULT: {
    label: 'Adult',
    icon: <EmojiPeopleIcon />,
  },
  PENSIONER: {
    label: 'Pensioner',
    icon: <DirectionsWalkIcon />,
  },
}

export const SENSOR_PAGES_BY_TYPE = {
  // Sensors
  '/sensors': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Sensors',
  },
  '/single-sensor': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Sensors',
  },
  // Cost and Consumptions
  '/cost-consumption/overview-day': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Overview by day',
  },
  '/cost-consumption/overview-thirty-minutes': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Overview by thirty minutes',
  },
  '/cost-consumption/breakdown-period': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Breakdown by period',
  },
  '/cost-consumption/breakdown-day': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Breakdown by day',
  },
  '/cost-consumption/live-consumption': {
    types: ['VOLTAWARE_SENSOR'],
    title: 'Live consumption',
  },
  '/cost-consumption/carbon-footprint': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Carbon Footprint',
  },
  // Tariffs
  '/tariffs': {
    types: ['VOLTAWARE_SENSOR'],
    title: 'Tariffs',
  },
  // Property
  '/property-details': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Property details',
  },
  // Appliances
  '/appliances': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Appliances',
  },
  // Disaggregation
  '/disaggregation': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Disaggregation',
  },
  '/disaggregation/day': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Disaggregation by day',
  },
  '/disaggregation/period': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Disaggregation by period',
  },
  '/disaggregation/thirty-minutes': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER'],
    title: 'Disaggregation by 30 min',
  },
  '/disaggregation/cycles': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Appliance cycles',
  },
  '/disaggregation/weekly': {
    types: ['LOW_RESOLUTION_METER'],
    title: 'Disaggregation by week',
  },
  '/disaggregation/monthly': {
    types: ['LOW_RESOLUTION_METER'],
    title: 'Disaggregation by month',
  },
  // VoltaScore
  '/voltascore/monthly': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'VoltaScore Monthly',
  },
  // Reports
  '/reports/monthly': {
    types: ['VOLTAWARE_SENSOR', 'HIGH_RESOLUTION_METER', 'LOW_RESOLUTION_METER'],
    title: 'Monthly report',
  },
};
