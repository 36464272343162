import { useState, useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CurrencyFormatter from 'currency-formatter';

import alwaysOnImg from '../../images/house-alwaysOn.gif';
import normalImg from '../../images/house-normal.gif';
import excessImg from '../../images/house-excess.gif';
import carbonFootprintImg from '../../images/carbon-footprint.png';
import treeImg from '../../images/tree.png';
import voltascoreImg from '../../images/voltascore.svg';
import { ReactComponent as VoltascoreStarEmptyGreen } from '../../images/voltascore-star-empty-green.svg';
import { ReactComponent as VoltascoreStarEmptyOrange } from '../../images/voltascore-star-empty-orange.svg';
import { ReactComponent as VoltascoreStarEmptyRed } from '../../images/voltascore-star-empty-red.svg';

import { SensorContext } from '../../utils/context';
import { capitalizeFirstLetter } from '../../utils/functions';
import ContentWrapper from '../../components/ContentWrapper';
import PaperWrapper from '../../components/PaperWrapper';
import DateArrow from '../../components/DateArrow';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import DatePicker from '../../components/DatePicker';
import CenteredLoader from '../../components/CenteredLoader';
import Graph from '../../components/Graph';
import DonutChart from '../../components/DonutChart';
import ApplianceBars from '../../components/ApplianceBars';
import { startOfMonth } from 'date-fns';
import {
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  MINIMUM_MONTH_ERROR_MESSAGE,
  REPORT_LAST_MONTH_ERROR_MESSAGE,
  SENSOR_ID_PLACEHOLDER,
  NO_DATA_FOUND_ERROR_MESSAGE,
} from '../../utils/constants';

import { getChartData, getReportData, clampValue, voltaColor } from './utils';

import {
  Wrapper,
  SectionLabel,
  MonthFullDescription,
  Calendar,
  Section,
  Container,
  MonthConsumption,
  BoldGreenText,
  PreviousConsumption,
  Activity,
  WeekdaysWrapper,
  Weekdays,
  Weekday,
  CarbonFootprintText,
  Trees,
  Row,
  VoltaStar,
  VoltaScoreOverall,
  VoltaScoreApplianceWrapper,
  VoltaScoreAppliancesContainer,
  RankPercentage,
 } from './styles';

const WeekAverage = ({week, average}) => {
  if (!average) {
    return null;
  }

  const { consumption } = average;
  const days = week === 'weekend'
    ? [
      {text: 'M', highlight: false},
      {text: 'T', highlight: false},
      {text: 'W', highlight: false},
      {text: 'T', highlight: false},
      {text: 'F', highlight: false},
      {text: 'S', highlight: true},
      {text: 'S', highlight: true},
    ]
    : [
      {text: 'M', highlight: true},
      {text: 'T', highlight: true},
      {text: 'W', highlight: true},
      {text: 'T', highlight: true},
      {text: 'F', highlight: true},
      {text: 'S', highlight: false},
      {text: 'S', highlight: false},
    ];

  return (
    <WeekdaysWrapper>
      <Weekdays>
        {days.map((day, index) => (
          <Weekday key={`${week}-item-${index}`} highlight={day.highlight}>
            {day.text}
          </Weekday>
        ))}
      </Weekdays>
      <Typography>Average {capitalizeFirstLetter(week)}</Typography>
      <Typography>{consumption}<span>kWh /day</span></Typography>
    </WeekdaysWrapper>
  );
};

const VoltaScore = ({score, locale}) => {
  const intScore = parseInt(score ?? 0, 10);
  const halfPercent = clampValue(+(((score ?? 0)-intScore).toFixed(2))*100);

  const icon = voltaColor(score ?? 0);

  const stars =
    [0, 0, 0, 0, 0]
    .fill(90, 0, intScore >= 1 ? intScore : 0)
    .fill(halfPercent, intScore >= 1 ? intScore : 0, Number(halfPercent > 0)*(intScore+1));
  
  return (
    <Row>
      <Row margin={{ left: 2, right: 4, bottom: 2 }}>
        {stars.map((fill, index) => (
          <VoltaStar key={`voltascore-star-${index}`} color={icon.color}>
            <div style={{ width: `${fill}%` }}>
              {icon.img === 'GREEN' && <VoltascoreStarEmptyGreen />}
              {icon.img === 'ORANGE' && <VoltascoreStarEmptyOrange />}
              {icon.img === 'RED' && <VoltascoreStarEmptyRed />}
            </div>
          </VoltaStar>)
        )}
      </Row>
      <VoltaScoreOverall>
        <strong style={{ color: icon.color }}>
          {new Intl.NumberFormat(locale, { minimumFractionDigits: 1 }).format(score.toFixed(1))}
        </strong>/5
      </VoltaScoreOverall>
    </Row>
  );
}

const VoltaScoreAppliance = ({ data, locale }) => {
  const icon = voltaColor(data?.score ?? 0);

  return (
    <VoltaScoreApplianceWrapper color={icon.color}>
      <div>
        <img src={data.icon} alt={data.title} />
        <Typography>{data.title}</Typography>
      </div>
      <Typography>
        {new Intl.NumberFormat(locale, { minimumFractionDigits: 1 }).format(data.score.toFixed(3))}/5
      </Typography>
    </VoltaScoreApplianceWrapper>
  );
}

const CalendarIcon = ({month}) => (
  <Calendar>
    <DateRangeIcon />
    <div>
      <Typography>{month}</Typography>
    </div>
  </Calendar>
);

function ReportMonthly() {
  const sensor = useContext(SensorContext);
  const [selectedDate, handleDateChange] = useState(
    moment().subtract(1, 'month').toDate()
  );
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const sensorId = sensor.id || SENSOR_ID_PLACEHOLDER;
  const month = moment(selectedDate).format('MM');
  const year = moment(selectedDate).format('yyyy');
  const previousMonth = moment(selectedDate).subtract(1, 'month').format('MM');
  const previousMonthYear = moment(selectedDate).subtract(1, 'month').format('yyyy');

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    if (
      startOfMonth(selectedDate) >
      startOfMonth(moment().subtract(1, 'month').toDate())
    ) {
      setIsValid(false);
      return setValidationError(REPORT_LAST_MONTH_ERROR_MESSAGE);
    }
 
    if (
      sensor.body &&
      sensor.body.data &&
      sensor.body.data.data.first_event &&
      startOfMonth(selectedDate) <
        startOfMonth(moment(sensor.body.data.data.first_event).toDate())
    ) {
      setIsValid(false);
      return setValidationError(
        MINIMUM_MONTH_ERROR_MESSAGE(
          moment(sensor.body.data.data.first_event).format('MMM yyyy')
        )
      );
    }

    setIsValid(true);
    return setValidationError('');
  }, [selectedDate, sensor.id, sensor]);

  const response = useQuery(
    ['reportMonthly', sensor.id, month, year],
    () =>
      axios.get(
        `/sensors/${sensorId}/${sensor.type === 'LOW_RESOLUTION_METER' ? 'sm/' : ''}reports/monthly?month=${month}&year=${year}`
      ),
    {
      enabled: isValid && Boolean(sensor.type),
      retry: 0,
    }
  );
  
  const responsePreviousMonth = useQuery(
    ['reportMonthly', sensor.id, previousMonth, previousMonthYear],
    () =>
      axios.get(
        `/sensors/${sensorId}/${sensor.type === 'LOW_RESOLUTION_METER' ? 'sm/' : ''}reports/monthly?month=${previousMonth}&year=${previousMonthYear}`
      ),
    {
      enabled: isValid && Boolean(sensor.type),
      retry: 0,
    }
  );

  const currentMonthFormatted = useMemo(() => {
    const data = response?.data?.data;
    const readings = sensor.type === 'LOW_RESOLUTION_METER' 
      ? data?.day_by_day_metrics?.days?.length
      : data?.days_detail?.length;
    if (!selectedDate || !response.isSuccess || !readings) {
      return null;
    }

    const {
      score,
      totalConsumption,
      busiestDay,
      leastActiveDay,
      average,
      carbonFootprint,
    } = getReportData(response.data.data, sensor);

    return {
      score: score,
      month: moment(selectedDate).format('MMM'),
      monthFull: moment(selectedDate).format('MMMM'),
      year: moment(selectedDate).format('YYYY'),
      range: `${moment(selectedDate).startOf('month').format('DD MMM')}${" - "}${moment(selectedDate).endOf('month').format('DD MMM')}`,
      dataCollection: `Data collected on ${readings}/${moment(selectedDate).endOf('month').format('DD')} days`,
      totalConsumption: totalConsumption,
      busiestDay: busiestDay,
      leastActiveDay: leastActiveDay,
      average: average,
      carbonFootprint: carbonFootprint,
    };
  }, [selectedDate, response, sensor]);

  const previousMonthFormatted = useMemo(() => {
    const readings = sensor.type === 'LOW_RESOLUTION_METER'
      ? responsePreviousMonth?.data?.data?.day_by_day_metrics?.days?.length
      : responsePreviousMonth?.data?.data?.days_detail?.length;
    if (!selectedDate || !responsePreviousMonth.isSuccess || !readings) {
      return null;
    }

    const { totalConsumption } = getReportData(responsePreviousMonth.data.data, sensor);

    return {
      month: moment(selectedDate).subtract(1, 'month').format('MMM'),
      range: `${moment(selectedDate).subtract(1, 'month').startOf('month').format('DD MMM')}${" - "}${moment(selectedDate).subtract(1, 'month').endOf('month').format('DD MMM')}`,
      dataCollection: `Data collected on ${readings}/${moment(selectedDate).subtract(1, 'month').endOf('month').format('DD')} days`,
      totalConsumption: totalConsumption,
    };
  }, [selectedDate, responsePreviousMonth, sensor]);

  const delta = currentMonthFormatted && previousMonthFormatted &&
    Math.ceil((currentMonthFormatted.totalConsumption.raw - previousMonthFormatted.totalConsumption.raw)/previousMonthFormatted.totalConsumption.raw*100);

  const monthlyData = useMemo(() => isValid && getChartData(response.data, sensor), [isValid, response.data, sensor]);

  const disableForward = moment().subtract(1, 'month').isSame(selectedDate, 'month');

  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle
          filter={
            <div className="noprint">
              <DateArrow
                dir='backward'
                disabled={response.isLoading}
                onClick={() => handleDateChange(old => moment(old).subtract(1, 'month').toDate())}
              />
              <DatePicker
                autoOk
                id="date-picker"
                label="Month"
                value={selectedDate}
                onChange={handleDateChange}
                disableFuture
                variant="inline"
                format="MMM yyyy"
                views={['year', 'month']}
                openTo="month"
                error={Boolean(validationError)}
                last="true"
                className="noprint"
              />
              <DateArrow
                dir='forward'
                disabled={response.isLoading || disableForward}
                onClick={() => handleDateChange(old => moment(old).add(1, 'month').toDate())}
              />
            </div>
          }
        >
          Monthly report&nbsp;{currentMonthFormatted && 
            <MonthFullDescription className="print">
              - {currentMonthFormatted.monthFull}/{currentMonthFormatted.year}
            </MonthFullDescription>
          }
        </PageTitle>
        <Typography paragraph className="noprint">
          Returns sumarized data for a specific month.
        </Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {(response.isError || (!validationError && !monthlyData)) && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
        {(response.isLoading || responsePreviousMonth.isLoading) && <CenteredLoader />}
        {!validationError && response.isSuccess && !responsePreviousMonth.isLoading && monthlyData?.data && (
          <>
            {currentMonthFormatted && (
              <>
                {currentMonthFormatted?.score?.overall && sensor.type === "LOW_RESOLUTION_METER" && (
                  <>
                    <SectionLabel variant="h5">VoltaScore</SectionLabel>
                    <PaperWrapper margin={{ vertical: 15 }}>
                      <Container>
                        <Section lgmargin={{ top: 15, bottom: 0 }} width="100%">
                          <img src={voltascoreImg} alt="VoltaScore" />
                          <Row lgmargin={{ vertical: 30 }} style={{ width: "55%", justifyContent: "space-between", flexWrap: "wrap" }}>
                            <Typography style={{ fontWeight: 600 }}>Your VoltaScore this month is:</Typography>
                            <VoltaScore score={currentMonthFormatted.score.overall} locale={sensor.isoLocaleCode} />
                          </Row>
                        </Section>
                      </Container>
                      <Container>
                        <Section lgmargin={{ vertical: 15, top: 0 }} width="100%">
                          <Typography style={{ fontWeight: 600, width: "55%" }}>Appliances scores:</Typography>
                          <VoltaScoreAppliancesContainer>
                            {currentMonthFormatted.score.appliances.map((data) => (
                              <VoltaScoreAppliance key={`appliance-score${data.key}`} data={data} locale={sensor.isoLocaleCode} />
                            ))}
                          </VoltaScoreAppliancesContainer>
                        </Section>
                      </Container>
                    </PaperWrapper>
                  </>
                )}
                <SectionLabel variant="h5">Total Consumption</SectionLabel>
                <PaperWrapper margin={{ vertical: 15 }}>
                  {currentMonthFormatted?.score && sensor.type === "LOW_RESOLUTION_METER" && (
                    <RankPercentage color={currentMonthFormatted.score.rankPercentage.color}>
                      <div>
                        <Typography>You are in the</Typography>
                        <Typography>{currentMonthFormatted.score.rankPercentage.label}</Typography>
                      </div>
                      <Typography>This is how efficiently you are using energy compared to similar users.</Typography>
                    </RankPercentage>
                  )}
                  <Container>
                    <Section>
                      <CalendarIcon month={currentMonthFormatted.month} />
                      <MonthConsumption>
                        <Typography>{currentMonthFormatted.range}</Typography>
                        <Typography>{currentMonthFormatted.dataCollection}</Typography>
                      </MonthConsumption>
                    </Section>
                    <Section>
                      <BoldGreenText>{currentMonthFormatted.totalConsumption.formatted}</BoldGreenText>
                      <BoldGreenText>{'\nkWh'}</BoldGreenText>
                    </Section>
                    {previousMonthFormatted && (
                      <>
                        <Section>
                          <CalendarIcon month={previousMonthFormatted.month} />
                          <MonthConsumption>
                            <Typography>{previousMonthFormatted.range}</Typography>
                            <Typography>{previousMonthFormatted.dataCollection}</Typography>
                          </MonthConsumption>
                        </Section>
                        <Section>
                          <PreviousConsumption>
                            <Typography className="previous-consumption-value">{previousMonthFormatted.totalConsumption.formatted}</Typography>
                            <Typography>{'\nkWh'}</Typography>
                          </PreviousConsumption>
                        </Section>
                      </>
                    )}
                  </Container>
                  {!!delta && (
                  <Container>
                    <Section margin={{ top: 0 }}>
                      <PreviousConsumption>
                        <Typography>
                          {delta > 0 ? 'Consumption has gone up by' : 'Consumption has dropped by'}
                        </Typography>
                        <Typography className={`previous-consumption-value delta ${delta > 0 ? 'red' : ''}`}>
                          {delta > 0 ? '+' : ''}{Math.abs(delta)}%
                        </Typography>
                      </PreviousConsumption>
                    </Section>
                  </Container>
                  )}
                </PaperWrapper>
                <SectionLabel variant="h5">Highs and Lows</SectionLabel>
                <PaperWrapper margin={{ vertical: 15 }}>
                  <Container rowJustify="space-evenly">
                    <Section lgmargin={{ left: 60 }}>
                      <Activity type="minimum">
                        <Typography>Least active day</Typography>
                        <Typography>{currentMonthFormatted.leastActiveDay.day}</Typography>
                        <img src={alwaysOnImg} alt="Least active day" />
                        <Typography className="numbers">{currentMonthFormatted.leastActiveDay.consumption}<span>kWh</span></Typography>
                      </Activity>
                    </Section>
                    <Section>
                      <Activity type="average">
                        <Typography>Daily average</Typography>
                        <img src={normalImg} alt="Average day" />
                        <Typography className="numbers">{currentMonthFormatted.average.consumption}<span>kWh</span></Typography>
                      </Activity>
                    </Section>
                    <Section lgmargin={{ right: 60 }}>
                      <Activity type="maximum">
                        <Typography>Busiest day</Typography>
                        <Typography>{currentMonthFormatted.busiestDay.day}</Typography>
                        <img src={excessImg} alt="Busiest day" />
                        <Typography className="numbers">{currentMonthFormatted.busiestDay.consumption}<span>kWh</span></Typography>
                      </Activity>
                    </Section>
                  </Container>
                  <Container rowJustify="space-around">
                    <Section>
                      <WeekAverage
                        week="weekday"
                        average={currentMonthFormatted.average?.weekday}
                      />
                    </Section>
                    <Section>
                      <WeekAverage
                        week="weekend"
                        average={currentMonthFormatted.average?.weekend}
                      />
                    </Section>
                  </Container>
                </PaperWrapper>
              </>
            )}
            <SectionLabel variant="h5">Day by Day</SectionLabel>
            {sensor.type !== 'LOW_RESOLUTION_METER'
              ? (<Graph
                data={monthlyData.data}
                y1="Consumption"
                y1UnitFormatter={(value) =>
                  `${new Intl.NumberFormat(sensor.isoLocaleCode).format(value.toFixed(2))} kWh`
                }
                y2={'Cost'}
                y2UnitFormatter={(value) => CurrencyFormatter.format(parseFloat(value), {
                  locale: sensor.isoLocaleCode,
                })}
                interval={2}
                xAxisHeight={50}
                highlights={monthlyData.highlights}
                margin={{ vertical: 15 }}
              />)
              : (
                <>
                  <Graph
                    data={monthlyData.data}
                    y1="Consumption"
                    y1UnitFormatter={(value) =>
                      `${new Intl.NumberFormat(sensor.isoLocaleCode).format(value.toFixed(2))} kWh`
                    }
                    y1Color="#222"
                    y1FillColor="#666666"
                    interval={2}
                    xAxisHeight={50}
                    height={550}
                    yAxisTopPadding={160}
                    highlights={monthlyData.highlights}
                    margin={{ vertical: 15 }}
                  />
                </>
              )
            }
            {monthlyData.disaggregation && (
              <>
                <SectionLabel variant="h5" className="page-break-before">Consumption Breakdown</SectionLabel>
                <DonutChart
                  data={monthlyData.disaggregation}
                  dataKey="consumption"
                  margin={{ vertical: 15 }}
                />
                <ApplianceBars data={monthlyData.disaggregation.appliances} margin={{ bottom: 15 }} />
              </>
            )}
            {currentMonthFormatted && (
              <>
                <SectionLabel variant="h5">Carbon Footprint</SectionLabel>
                <PaperWrapper margin={{ top: 15 }}>
                  <Container>
                    <Section>
                      <img src={carbonFootprintImg} alt="Carbon Footprint" />
                    </Section>
                    <Section>
                      <BoldGreenText>{currentMonthFormatted.carbonFootprint.kgco2}</BoldGreenText>
                      <BoldGreenText>{'\nKgCO'}<sub>2</sub></BoldGreenText>
                    </Section>
                    <Section width="25%" margin={{ top: 0 }}>
                      <CarbonFootprintText><span>{currentMonthFormatted.carbonFootprint.trees}</span> mature trees are needed to absorbe this amount of CO<sub>2</sub> in a month.</CarbonFootprintText>
                      <Trees>
                        {Array.from({length:6}).map((_, i) => <img key={`treeline1-${i}`} src={treeImg} alt="" />)}
                      </Trees>
                      <Trees>
                        {Array.from({length:6}).map((_, i) => <img key={`treeline2-${i}`} src={treeImg} alt="" />)}
                      </Trees>
                    </Section>
                  </Container>
                </PaperWrapper>
              </>
            )}
          </>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${sensorId}/${sensor.type === 'LOW_RESOLUTION_METER' ? 'sm/' : ''}reports/monthly?month=${month}&year=${year}`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default ReportMonthly;
