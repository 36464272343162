import { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import Typography from '@material-ui/core/Typography';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import CircularProgress from '@material-ui/core/CircularProgress';

import { NO_DATA_FOUND_ERROR_MESSAGE } from '../../utils/constants';
import { JsonTabContext } from '../../utils/context';

const Wrapper = styled.div`
  height: 100%;
  width: 333px;
  background: #1e1e1e;
  position: relative;
  ${({ isJsonTabOpen }) => !isJsonTabOpen && 'width: 0;'}
  transition: width 0.3s;
  & .variable-row,
  & .array-group,
  & .object-key-val {
    border: none !important;
  }

  & .object-key-val {
    padding: 10px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const AbsoluteWrapper = styled.div`
  position: fixed;
  padding-bottom: 64px;
  right: 0;
  width: 350px;
  height: 100%;
  overflow-y: auto;
  background: #1e1e1e;

  transform: ${({ isJsonTabOpen }) =>
    isJsonTabOpen ? 'translateX(0)' : 'translateX(350px)'};
  transition: transform 0.3s;

  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #388e3c;
    border-radius: 3px;
  }
`;

const Title = styled(Typography)`
  background: #388e3c;
  color: #fff;
  padding: 15px 12px;
  overflow-wrap: break-word;
`;

const JsonTitle = styled(Title)`
  background: #1e2224;
  padding: 10px 12px;
`;

const HideButton = styled.div`
  position: fixed;
  bottom: 12px;
  right: 10px;
  cursor: pointer;

  ${({ isJsonTabOpen }) => isJsonTabOpen && 'color: #fff;'}
  transition: color 0.1s;
  ${({ isJsonTabOpen }) => isJsonTabOpen && 'transform: rotate(180deg);'}
  ${({ isJsonTabOpen }) => !isJsonTabOpen && 'bottom: 7px;'}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledCircularProgress = styled(CircularProgress)`
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
`;

function JsonTab({
  endpoint,
  request = null,
  response = null,
  isLoading,
  isError,
  isSuccess,
  isIdle,
}) {
  const { isJsonTabOpen, handleOpenCloseJsonTab } = useContext(JsonTabContext);

  return (
    <Wrapper isJsonTabOpen={isJsonTabOpen}>
      <AbsoluteWrapper isJsonTabOpen={isJsonTabOpen}>
        <Title>{endpoint}</Title>
        <ContentWrapper>
          {isLoading && <StyledCircularProgress data-testid="loader" />}
          {isSuccess && request && (
            <div>
              <JsonTitle>Request body</JsonTitle>
              <ReactJson
                src={request}
                name={false}
                theme="twilight"
                displayDataTypes={false}
                displayArrayKey={false}
                displayObjectSize={false}
                collapsed={1}
              />
            </div>
          )}
          {isSuccess && response && (
            <div>
              <JsonTitle>Response body</JsonTitle>
              <ReactJson
                src={response}
                name={false}
                theme="twilight"
                displayDataTypes={false}
                displayArrayKey={false}
                displayObjectSize={false}
                collapsed={1}
              />
            </div>
          )}
          {isError && <JsonTitle>{NO_DATA_FOUND_ERROR_MESSAGE}</JsonTitle>}
          {isIdle && <JsonTitle>Please, select a sensor</JsonTitle>}
        </ContentWrapper>
      </AbsoluteWrapper>
      <HideButton
        onClick={handleOpenCloseJsonTab}
        isJsonTabOpen={isJsonTabOpen}
      >
        <MenuOpenIcon />
      </HideButton>
    </Wrapper>
  );
}

JsonTab.propTypes = {
  endpoint: PropTypes.string.isRequired,
  request: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  response: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isIdle: PropTypes.bool.isRequired,
};

export default JsonTab;
