import { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import moment from 'moment';
import { startOfMonth } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import speedometerUnavailableImg from '../../images/scores/speedometer-unavailable.png';
import leafIcon from '../../images/leaf.png';

import { SensorContext } from '../../utils/context';
import usePageUnavailable from '../../hooks/usePageUnavailable';
import Unavailable from '../../components/Unavailable';
import ContentWrapper from '../../components/ContentWrapper';
import DateArrow from '../../components/DateArrow';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import DatePicker from '../../components/DatePicker';
import CenteredLoader from '../../components/CenteredLoader';

import {
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  MINIMUM_DATE_ERROR_MESSAGE,
  SENSOR_ID_PLACEHOLDER,
  NO_DATA_FOUND_ERROR_MESSAGE,
  SCORE_RECOMMENDATIONS,
} from '../../utils/constants';

import {
  scoreColor,
  scoreIcon,
  speedometerImg,
  uniqueRecommendations,
} from './utils';

import {
  Container,
  RecommendationsTitle,
  Recommendations,
  Recommendation,
  ScoreData,
  ScoreDataWrapper,
  SubTitle,
  Title,
  Value,
} from './styles';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const CardContainer = styled(Card)`
  margin-bottom: 10px;
`;

const Separator = styled.hr`
  width: 40px;
  height: 1px;
  border: none;
  margin: 30px 0;
  background-color: #c0c0c0;
  border-radius: 10px;
`;

function VoltaScoreMonthly() {
  const sensor = useContext(SensorContext);
  const isPageUnavailable = usePageUnavailable();
  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(1, 'month').startOf('month').toDate()
  );
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const maxDate = moment().subtract(1, 'month').endOf('month').toDate();

  const sensorId = sensor.id || SENSOR_ID_PLACEHOLDER;
  const formattedMonth = moment(selectedDate).format('MM');
  const formattedYear = moment(selectedDate).format('yyyy');

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    if (
      sensor.body &&
      sensor.body.data &&
      sensor.body.data.data.first_event &&
      startOfMonth(selectedDate) <
        startOfMonth(moment(sensor.body.data.data.first_event).toDate())
    ) {
      setIsValid(false);
      return setValidationError(
        MINIMUM_DATE_ERROR_MESSAGE(
          moment(sensor.body.data.data.first_event).format('DD MMM yyyy')
        )
      );
    }

    setIsValid(true);
    return setValidationError('');
  }, [isValid, selectedDate, sensor.id, sensor]);

  const response = useQuery(
    ['voltaScoreMonthly', sensor.id, formattedMonth, formattedYear],
    () =>
      axios.get(
        `/sensors/${sensorId}/scores?year=${formattedYear}&month=${formattedMonth}`
      ),
    {
      enabled: isValid,
      retry: 0,
    }
  );

  const disableForward =
    moment(maxDate).isSame(selectedDate, 'month') &&
    moment(maxDate).isSame(selectedDate, 'year');

  const updateDate = (dir) => {
    if (dir === 'backwards') {
      setSelectedDate((old) =>
        moment(old).subtract(1, 'month').startOf('month').toDate()
      );
    } else {
      setSelectedDate((old) =>
        moment(old).add(1, 'month').startOf('month').toDate()
      );
    }
  };

  if (isValid && isPageUnavailable) {
    return <Unavailable pageName={isPageUnavailable} />;
  }

  const scoreRecommendations = uniqueRecommendations(
    response?.data?.data?.recommendations
  );

  const deltaScore =
    response?.data?.data?.overall_score &&
    response?.data?.data?.overall_score_previous_month &&
    response?.data?.data.overall_score -
      response?.data?.data.overall_score_previous_month;
  const previousMonth =
    selectedDate &&
    moment(selectedDate).startOf('day').subtract(1, 'month').toDate();

  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle
          filter={
            <div>
              <DateArrow
                dir="backward"
                disabled={response.isLoading}
                onClick={() => updateDate('backwards')}
              />
              <DatePicker
                autoOk
                id="from-date"
                label="Date"
                value={selectedDate}
                maxDate={maxDate}
                onChange={setSelectedDate}
                disableFuture
                variant="inline"
                format="MMM yyyy"
                views={['year', 'month']}
                openTo="month"
                error={Boolean(validationError)}
                last="true"
              />
              <DateArrow
                dir="forward"
                disabled={response.isLoading || disableForward}
                onClick={() => updateDate('forwards')}
              />
            </div>
          }
        >
          VoltaScore by month
        </PageTitle>
        <Typography paragraph>View monthly VoltaScore.</Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {!validationError && response.isSuccess && (
          <CardContainer>
            <Container>
              <img
                width={50}
                src={scoreIcon(response?.data?.data?.overall_score)}
                alt="VoltaScore"
              />
              {response?.data?.data?.overall_score !== undefined ? (
                <>
                  <Title data-testid="score-overall">
                    Your score is {response.data.data.overall_score}
                  </Title>
                  {![null, undefined].includes(deltaScore) && (
                    <SubTitle>
                      <span
                        style={{
                          color: deltaScore >= 0 ? '#6AB42D' : '#ED1432',
                        }}
                      >
                        {deltaScore >= 0 ? '+' : ''}
                        {deltaScore} vs{' '}
                      </span>
                      {new Intl.DateTimeFormat('en-US', {
                        timeZone: 'UTC',
                        month: 'long',
                        year: 'numeric',
                      }).format(previousMonth)}
                    </SubTitle>
                  )}

                  <p style={{ maxWidth: '50%' }}>
                    We've analysed your overall behaviour, comparing it to
                    similar homes, and reviewing your historical data.
                  </p>
                  <br />
                  <p>Here are your detailed scores:</p>
                </>
              ) : (
                <>
                  <Title>No score available</Title>
                  <p style={{ maxWidth: '50%' }}>
                    Your account might have been created too recently and for
                    this reason, you don't have a score this month.
                  </p>
                  <br />
                  <p>Please check again next month.</p>
                </>
              )}

              <ScoreDataWrapper>
                {/* P2P Score */}
                <ScoreData>
                  <img
                    width={150}
                    src={
                      response?.data?.data?.p2p?.score
                        ? speedometerImg(response.data.data.p2p.score)
                        : speedometerUnavailableImg
                    }
                    alt="P2P Score"
                  />
                  {response?.data?.data?.p2p?.score !== undefined ? (
                    <Value color={scoreColor(response.data.data.p2p.score)}>
                      <span>{response.data.data.p2p.score}</span> / 100
                    </Value>
                  ) : (
                    <Value>
                      <span>~</span> / 100
                    </Value>
                  )}
                  <strong>Similar Homes</strong>
                </ScoreData>

                {/* Historical Score */}
                <ScoreData>
                  <img
                    width={150}
                    src={
                      response?.data?.data?.historical?.score
                        ? speedometerImg(response.data.data.historical.score)
                        : speedometerUnavailableImg
                    }
                    alt="Historical Score"
                  />
                  {response?.data?.data?.historical?.score !== undefined ? (
                    <Value
                      color={scoreColor(response.data.data.historical.score)}
                    >
                      <span>{response.data.data.historical.score}</span> / 100
                    </Value>
                  ) : (
                    <Value>
                      <span>~</span> / 100
                    </Value>
                  )}
                  <strong>Historical Score</strong>
                </ScoreData>
              </ScoreDataWrapper>

              {/* Recommendations */}
              {response?.data?.data?.recommendations?.length && (
                <>
                  <Separator />
                  <RecommendationsTitle>
                    <img src={leafIcon} alt="Recommendations" />
                    <strong>Recommendations</strong>
                  </RecommendationsTitle>
                  <Recommendations>
                    {scoreRecommendations.map((recommendation, index) => {
                      const data = SCORE_RECOMMENDATIONS[
                        recommendation.recommendation_key
                      ] ?? {
                        ...SCORE_RECOMMENDATIONS.DEFAULT,
                        title: recommendation.recommendation_key,
                      };
                      return (
                        <Recommendation
                          key={`energy-saving-recommendation-${index}`}
                        >
                          <img src={data.icon} alt={recommendation.key} />
                          <div>
                            <strong>{data.title}</strong>
                            <p>{data.description}</p>
                          </div>
                        </Recommendation>
                      );
                    })}
                  </Recommendations>
                </>
              )}
            </Container>
          </CardContainer>
        )}
        {response.isError && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${sensorId}/scores?month=${formattedMonth}&year=${formattedYear}`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default VoltaScoreMonthly;
