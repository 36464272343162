import { lighten, darken } from 'polished';

import { mapRange } from '../../../../utils/functions';

export const brightenColor = (value, baseColor, brightenData) => 
  (brightenData.mode === 'darken'
  ? darken(
    mapRange(
      value,
      brightenData.min || 0, 
      brightenData.max,
      0,
      Math.min(brightenData.maxFactor, 1) || 1
    ),
    baseColor,
  ) : lighten(
    mapRange(
      value,
      brightenData.min || 0, 
      brightenData.max,
      0,
      Math.min(brightenData.maxFactor, 1) || 1
    ),
    baseColor,
  ));

export const labelDirection = (index, data) => {
  const { minIndex, maxIndex, total, indicesApart } = data;
  const lastIndex = total-1;
  const firstIndex = minIndex < maxIndex ? minIndex : maxIndex;

  if (total < 10) {
    return 'right';
  }

  let direction = 'none';
  if (indicesApart > 0) {
    direction = index < lastIndex ? 'right' : 'left';
  } else {
    if (index !== firstIndex) {
      direction = index < lastIndex ? 'right' : 'none';
    } else {
      direction = index > 0 ? 'left' : 'none';
    }
  }

  return direction;
}

export const shouldLowerYPosition = (index, data, direction) => {
  const { total, indicesApart } = data;
  const lastIndex = total-1;

  return total > 10 && indicesApart < 3 && direction !== 'none' && (index === 0 || index === lastIndex);
}
