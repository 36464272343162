import { useState, useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { startOfDay } from 'date-fns';

import { SensorContext } from '../../utils/context';
import usePageUnavailable from '../../hooks/usePageUnavailable';
import Unavailable from '../../components/Unavailable';
import ContentWrapper from '../../components/ContentWrapper';
import DateArrow from '../../components/DateArrow';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import DatePicker from '../../components/DatePicker';
import Card from '../../components/Card';
import Cycles from '../../components/Cycles';
import CenteredLoader from '../../components/CenteredLoader';
import {
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  MINIMUM_DATE_ERROR_MESSAGE,
  SENSOR_ID_PLACEHOLDER,
  NO_DATA_FOUND_ERROR_MESSAGE,
  APPLIANCES as REGULAR_APPLIANCES,
  LOW_RES_APPLIANCES,
} from '../../utils/constants';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const CardContainer = styled(Grid)`
  margin-bottom: 10px;
`;

const PAYLOAD_PROP_NAMES = {
  LOW_RESOLUTION_METER: {
    'TOTAL_CONSUMPTION': 'total_consumption',
    'APPLIANCE_KEY': 'appliance_key',
    'FROM': 'from',
    'TO': 'to',
    'ENERGY_CONSUMPTION': 'consumption',
  },
  DEFAULT: {
    'TOTAL_CONSUMPTION': 'totalConsumption',
    'APPLIANCE_KEY': 'applianceKey',
    'FROM': 'timestampMin',
    'TO': 'timestampMax',
    'ENERGY_CONSUMPTION': 'totalEnergyConsumption',
  },
};

function ApplianceCycles() {
  const sensor = useContext(SensorContext);
  const isPageUnavailable = usePageUnavailable();
  const [selectedDate, handleDateChange] = useState(
    moment().toDate()
  );
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const sensorId = sensor.id || SENSOR_ID_PLACEHOLDER;
  const formattedDate = moment(selectedDate).format('yyyy-MM-DD');

  const PAYLOAD_MAP = PAYLOAD_PROP_NAMES[sensor.type] || PAYLOAD_PROP_NAMES.DEFAULT;
  const APPLIANCES = sensor.type === 'LOW_RESOLUTION_METER' ? LOW_RES_APPLIANCES : REGULAR_APPLIANCES;

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    if (
      sensor.body &&
      sensor.body.data &&
      sensor.body.data.data.first_event &&
      startOfDay(selectedDate) <
        startOfDay(moment(sensor.body.data.data.first_event).toDate())
    ) {
      setIsValid(false);
      return setValidationError(
        MINIMUM_DATE_ERROR_MESSAGE(
          moment(sensor.body.data.data.first_event).format('DD MMM yyyy')
        )
      );
    }

    setIsValid(true);
    return setValidationError('');
  }, [selectedDate, sensor.id, sensor]);

  const response = useQuery(
    ['applianceCycles', sensorId, formattedDate],
    () => axios.get(sensor.type === 'LOW_RESOLUTION_METER' ? `/sensors/${sensorId}/sm/disag/cycles?date=${formattedDate}` : `/sensors/${sensorId}/disag/day/cycles?day=${formattedDate}`),
    {
      enabled: isValid,
      retry: 0,
    }
  );

  const overallData = useMemo(() => 
    response?.data?.data?.reduce((acc, current) => 
      ({...acc,
        totalConsumption: acc.totalConsumption + current[PAYLOAD_MAP.TOTAL_CONSUMPTION],
        totalCycles: acc.totalCycles + ((sensor.type === 'LOW_RESOLUTION_METER' ? current?.cycles?.length : current?.totalCycles) ?? 0)
      }), { totalConsumption: 0, totalCycles: 0 }) || { totalConsumption: 0, totalCycles: 0 }, [response, sensor.type, PAYLOAD_MAP]);

  const getData = (data) => {
    const dataArray = data.data.map(appliance => {
      const key = appliance[PAYLOAD_MAP.APPLIANCE_KEY]; 
      
      const cycles = appliance.cycles.map(cycle => ({
        key: `${key}-${cycle[PAYLOAD_MAP.FROM]}`,
        startingTime: moment(cycle[PAYLOAD_MAP.FROM]).format('hh:mm a'),
        endingTime: moment(cycle[PAYLOAD_MAP.TO]).format('hh:mm a'),
        consumption: cycle[PAYLOAD_MAP.ENERGY_CONSUMPTION],
        consumptionPercent: Math.round(appliance[PAYLOAD_MAP.TOTAL_CONSUMPTION] / cycle[PAYLOAD_MAP.ENERGY_CONSUMPTION] * 100).toFixed(2),
      }));

      return {
        ...appliance,
        applianceKey: key,
        title: APPLIANCES[key]?.label ?? key,
        color: APPLIANCES[key]?.color ?? APPLIANCES.others.color,
        totalConsumption: appliance[PAYLOAD_MAP.TOTAL_CONSUMPTION],
        totalConsumptionPercent: Math.round(appliance[PAYLOAD_MAP.TOTAL_CONSUMPTION] / overallData.totalConsumption * 100).toFixed(2),
        cycles,
        totalCycles: sensor.type === 'LOW_RESOLUTION_METER' ? cycles?.length ?? 0 : appliance.totalCycles,
      }
    });

    return dataArray;
  };

  const disableForward = moment().isSame(selectedDate, 'day');

  const updateDate = (dir) => {
    if (dir === 'backwards') {
      handleDateChange(old => moment(old).subtract(1, 'day').toDate());
    } else {
      handleDateChange(old => moment(old).add(1, 'day').toDate());
    }
  }

  if (isValid && isPageUnavailable) {
    return <Unavailable pageName={isPageUnavailable} />
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle
          filter={
            <div>
              <DateArrow
                dir='backward'
                disabled={response.isLoading}
                onClick={() => updateDate('backwards')}
              />
              <DatePicker
                autoOk
                id="date-picker"
                label="Date"
                value={selectedDate}
                onChange={handleDateChange}
                disableFuture
                variant="inline"
                format="dd MMM yyyy"
                error={Boolean(validationError)}
                last="true"
              />
              <DateArrow
                dir='forward'
                disabled={response.isLoading || disableForward}
                onClick={() => updateDate('forwards')}
              />
            </div>
          }
        >
          Appliance cycles
        </PageTitle>
        <Typography paragraph>
        Returns the quantity of appliance cycles that happened in a day.
        </Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {!!(!validationError && response?.data?.data?.length) && (
          <CardContainer container spacing={2}>
            <Card
              xs={12}
              sm={6}
              title="Total Consumption"
              data={`${new Intl.NumberFormat(sensor.isoLocaleCode).format(
                overallData.totalConsumption
              )} Wh`}
            />
            <Card
              xs={12}
              sm={6}
              title="Total Cycles"
              data={`${overallData.totalCycles}`}
            />
          </CardContainer>
        )}
        {!!(!validationError && response?.data?.data?.length) && (
          <Cycles data={getData(response.data)} showConsumption={sensor.type === 'LOW_RESOLUTION_METER'} />
        )}
        {!response?.data?.data?.length && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={sensor.type === 'LOW_RESOLUTION_METER' ? `GET /sensors/${sensorId}/sm/disag/cycles?date=${formattedDate}` : `GET /sensors/${sensorId}/disag/day/cycles?day=${formattedDate}`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default ApplianceCycles;
