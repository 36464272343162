import CryptoJS from 'crypto-js';

export const sortAppliancesDescending = (appliances) => {
  const sortedAppliances = [...appliances];
  sortedAppliances.sort(
    (a, b) => b.consumptionPercentage - a.consumptionPercentage
  );

  const others = sortedAppliances.find(
    (appliance) => appliance.title === 'Others'
  );
  const othersIndex = sortedAppliances.findIndex(
    (appliance) => appliance.title === 'Others'
  );
  if (othersIndex > -1) {
    sortedAppliances.splice(othersIndex, 1);
    sortedAppliances.push(others);
  }

  return sortedAppliances;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

/**
 * Map range
 * Returns the equivalent of $value from range $[inMin, inMax] in range 
 * $[outMin, outMax]
 */
export const mapRange = (value, inMin, inMax, outMin, outMax) => {
  return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
};

export const randomIntBetween = (min,max) => {
  return Math.floor(Math.random()*(max - min + 1)) + min
};

// Shallow base64 encryption/decryption
export const encrypt = (text) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};

export const decrypt = (data) => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};
