import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';

const Button = styled(MuiButton)`
  .MuiButton-label {
    text-transform: none;
  }
`;

function WeekSelector({ title, list, onSelect, disabled }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (index) => {
    setAnchorEl(null);
    if (index === selectedIndex.current) {
      return;
    }

    setSelectedIndex(index);
    if (onSelect) {
      onSelect(index);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        aria-controls="week-selector-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={title && <CalendarIcon />}
        disabled={disabled}
      >
        {title || "Menu"}
      </Button>
      <Menu
        id="week-selector-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {list && list.map((item) => (
          <MenuItem key={`list-item-${item.index}`} onClick={() => handleClose(item.index)}>
            {item.item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

WeekSelector.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

export default WeekSelector;
