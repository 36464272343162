import { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { startOfDay } from 'date-fns';
import CurrencyFormatter from 'currency-formatter';

import { SensorContext } from '../../utils/context';
import usePageUnavailable from '../../hooks/usePageUnavailable';
import Unavailable from '../../components/Unavailable';
import ContentWrapper from '../../components/ContentWrapper';
import DateArrow from '../../components/DateArrow'
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import DatePicker from '../../components/DatePicker';
import Card from '../../components/Card';
import Graph from '../../components/Graph';
import CenteredLoader from '../../components/CenteredLoader';
import {
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  MINIMUM_DATE_ERROR_MESSAGE,
  TO_DATE_AFTER_FROM_DATE_ERROR_MESSAGE,
  SENSOR_ID_PLACEHOLDER,
  MONTH_RANGE_ERROR_MESSAGE,
  MILLISECONDS_IN_A_MONTH,
  NO_DATA_FOUND_ERROR_MESSAGE,
} from '../../utils/constants';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const CardContainer = styled(Grid)`
  margin-bottom: 10px;
`;

function OverviewDay() {
  const sensor = useContext(SensorContext);
  const isPageUnavailable = usePageUnavailable();
  const [fromDate, setFromDate] = useState(
    moment().startOf('month').toDate()
  );
  const [toDate, setToDate] = useState(moment().toDate());
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const sensorId = sensor.id || SENSOR_ID_PLACEHOLDER;
  const formattedFromDate = moment(fromDate).format('yyyy-MM-DD');
  const formattedToDate = moment(toDate).format('yyyy-MM-DD');

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    if (
      sensor.body &&
      sensor.body.data &&
      sensor.body.data.data.first_event &&
      startOfDay(fromDate) <
        startOfDay(moment(sensor.body.data.data.first_event).toDate())
    ) {
      setIsValid(false);
      return setValidationError(
        MINIMUM_DATE_ERROR_MESSAGE(
          moment(sensor.body.data.data.first_event).format('DD MMM yyyy')
        )
      );
    }

    if (toDate < fromDate) {
      setIsValid(false);
      return setValidationError(TO_DATE_AFTER_FROM_DATE_ERROR_MESSAGE);
    }

    if (startOfDay(toDate) - startOfDay(fromDate) >= MILLISECONDS_IN_A_MONTH) {
      setIsValid(false);
      return setValidationError(MONTH_RANGE_ERROR_MESSAGE);
    }

    setIsValid(true);
    return setValidationError('');
  }, [fromDate, toDate, sensor.id, sensor]);

  const response = useQuery(
    ['overviewDay', sensor.id, formattedFromDate, formattedToDate],
    () =>
      axios.get(
        `/sensors/${sensorId}/stats/period?from=${formattedFromDate}&to=${formattedToDate}`
      ),
    {
      enabled: isValid,
      retry: 0,
    }
  );

  const getGraphData = (data) => {
    const dataArray = data && data.data && data.data.dailyMetrics;
    return (
      dataArray &&
      dataArray.map((item) => {
        return {
          x: moment(item.date).format('DD MMM yyyy'),
          Consumption: item.consumption / 1000,
          Cost: item.cost,
        };
      })
    );
  };

  const disableForward = moment().isSame(toDate, 'month');

  const updateDate = (dir) => {
    if (dir === 'backwards') {
      setFromDate(old =>
        moment(old)
        .subtract(1, 'month')
        .startOf('month')
        .toDate());
      setToDate(old =>
        moment(old)
        .subtract(1, 'month')
        .endOf('month')
        .toDate());
    } else {
      const isSameMonth = moment().isSame(moment(toDate).add(1, 'month'), 'month');
      const endDate = isSameMonth
                      ? moment().toDate()
                      : moment(toDate).add(1, 'month').endOf('month').toDate();
      setFromDate(old =>
        moment(old)
        .add(1, 'month')
        .startOf('month')
        .toDate());
      setToDate(endDate);
    }
  }

  if (isValid && isPageUnavailable) {
    return <Unavailable pageName={isPageUnavailable} />
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle
          filter={
            <div>
              <DateArrow
                dir='backward'
                disabled={response.isLoading}
                onClick={() => updateDate('backwards')}
              />
              <DatePicker
                autoOk
                id="from-date"
                label="From"
                value={fromDate}
                onChange={setFromDate}
                disableFuture
                variant="inline"
                format="dd MMM yyyy"
                error={Boolean(validationError)}
              />
              <DatePicker
                autoOk
                id="to-date"
                label="To"
                value={toDate}
                onChange={setToDate}
                disableFuture
                variant="inline"
                format="dd MMM yyyy"
                error={Boolean(validationError)}
                last="true"
              />
              <DateArrow
                dir='forward'
                disabled={response.isLoading || disableForward}
                onClick={() => updateDate('forwards')}
              />
            </div>
          }
        >
          Overview by day
        </PageTitle>
        <Typography paragraph>
          View total cost and consumption metrics for a given period of days.
        </Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {!validationError && response.isSuccess && (
          <CardContainer container spacing={2}>
            <Card
              xs={12}
              sm={6}
              title="Consumption"
              data={`${new Intl.NumberFormat(sensor.isoLocaleCode).format(
                response.data.data.consumption / 1000
              )} kWh`}
            />
            <Card
              xs={12}
              sm={6}
              title="Cost"
              data={CurrencyFormatter.format(response.data.data.cost, {
                locale: sensor.isoLocaleCode,
              })}
            />
          </CardContainer>
        )}
        {!validationError && response.isSuccess && (
          <Graph
            data={getGraphData(response.data)}
            y1="Consumption"
            y2="Cost"
            y1UnitFormatter={(value) =>
              `${new Intl.NumberFormat(sensor.isoLocaleCode).format(value)} kWh`
            }
            y2UnitFormatter={(value) =>
              CurrencyFormatter.format(parseFloat(value), {
                locale: sensor.isoLocaleCode,
              })
            }
            interval={2}
          />
        )}
        {response.isError && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${sensorId}/stats/period?from=${formattedFromDate}&to=${formattedToDate}`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default OverviewDay;
