import { createGlobalStyle } from 'styled-components';
import { createTheme } from '@material-ui/core/styles';

export const GlobalStyle = createGlobalStyle`
  body {
    min-height: 100vh;
    display: flex;
  };

  #root {
    flex: 1;
    display: flex;
  }

  * {
    font-family: Inter, Roboto, "Helvetica Neue", Arial, sans-serif !important;
  }

  a {
    color: inherit;
  }

  button {
    outline: none;
    background: inherit;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  @media print {
    .noprint {
      display: none;
    }

    .print {
      display: block;
    }

    .page-break-before {
      page-break-before: always;
    }

    .adjust-color {
      -webkit-print-color-adjust: exact;
    }

    .recharts-wrapper,
    .recharts-surface{
      width: calc(100%)!important;
    }

    @page {
      margin: 0.5cm 0.5cm 0;
    }
  }
`;

const primary = '#388e3c';
const secondary = '#388e3c';

export const MuiTheme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
});

export const theme = {
  primary,
  secondary,
  background: '#f0f2f5',
};
