import { useContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CurrencyFormatter from 'currency-formatter';

import Unavailable from '../../components/Unavailable';
import { SensorContext } from '../../utils/context';
import usePageUnavailable from '../../hooks/usePageUnavailable';
import ContentWrapper from '../../components/ContentWrapper';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import Card from '../../components/Card';
import TariffBar from './TariffBar';
import axios from '../../api/setup';
import CenteredLoader from '../../components/CenteredLoader';
import {
  SENSOR_ID_PLACEHOLDER,
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  NO_DATA_FOUND_ERROR_MESSAGE,
} from '../../utils/constants';
import { capitalizeFirstLetter } from '../../utils/functions';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const CardContainer = styled(Grid)`
  margin-bottom: 10px;
`;

function Tariffs() {
  const sensor = useContext(SensorContext);
  const isPageUnavailable = usePageUnavailable();
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    setIsValid(true);
    return setValidationError('');
  }, [sensor.id, sensor]);

  const response = useQuery(
    ['tariffs', sensor.id],
    () => axios.get(`/sensors/${id}/tariff`),
    {
      enabled: isValid,
      retry: 0,
    }
  );

  const id = sensor.id || SENSOR_ID_PLACEHOLDER;
  const isDualTariff =
    (response &&
      response.data &&
      response.data.data &&
      response.data.data.composition.peak) ||
    false;
  
  if (isValid && isPageUnavailable) {
    return <Unavailable pageName={isPageUnavailable} />
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle>View tariff</PageTitle>
        <Typography paragraph>View tariff.</Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {!validationError && response.isSuccess && (
          <>
            <CardContainer container spacing={2}>
              <Card
                xs={12}
                sm={6}
                title="Tariff Type"
                data={capitalizeFirstLetter(response.data.data.chargeType)}
              />
              <Card
                xs={12}
                sm={6}
                title="Standing Charge"
                data={CurrencyFormatter.format(
                  response.data.data.standingChargePencePerDay,
                  {
                    locale: sensor.isoLocaleCode,
                  }
                )}
              />
              <Card
                xs={12}
                sm={isDualTariff ? 6 : 12}
                title="Off-peak Rate"
                data={CurrencyFormatter.format(
                  response.data.data.composition.offPeak.rate,
                  {
                    locale: sensor.isoLocaleCode,
                  }
                )}
              />
              {isDualTariff && (
                <Card
                  xs={12}
                  sm={6}
                  title="Peak Rate"
                  data={CurrencyFormatter.format(
                    response.data.data.composition.peak.rate,
                    {
                      locale: sensor.isoLocaleCode,
                    }
                  )}
                />
              )}
            </CardContainer>
            <TariffBar schedule={response.data.data.schedule} />
          </>
        )}
        {response.isError && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${id}/tariff`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default Tariffs;
