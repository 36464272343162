import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import ContentWrapper from '../../components/ContentWrapper';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

function AllSensors({ sensors }) {
  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle>List all sensors</PageTitle>
        <Typography>
          Retrieves a list of the Ids of all available sensors.
        </Typography>
      </ContentWrapper>
      <JsonTab
        endpoint="GET /sensors"
        isLoading={sensors.isLoading}
        isError={sensors.isError}
        isSuccess={sensors.isSuccess}
        isIdle={sensors.isIdle}
        response={sensors && sensors.data && sensors.data.data}
      />
    </Wrapper>
  );
}

AllSensors.propTypes = {
  sensors: PropTypes.object.isRequired,
};

export default AllSensors;
