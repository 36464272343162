import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { SensorContext } from '../utils/context';
import { SENSOR_PAGES_BY_TYPE } from '../utils/constants';

function usePageUnavailable() {
  const sensor = useContext(SensorContext);
  const location = useLocation();

  return !SENSOR_PAGES_BY_TYPE[location.pathname].types.includes(sensor.type) ? SENSOR_PAGES_BY_TYPE[location.pathname].title : false;
}

export default usePageUnavailable;
