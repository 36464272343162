import styled from 'styled-components';

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 33px 48px;

  @media (max-width: 600px) {
    padding: 5px 20px;
  }
`;

export default ContentWrapper;
