import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import MuiDivider from '@material-ui/core/Divider';

const Content = styled.div`
  min-height: 53px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    margin: 10px 0;
  }
`;

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;

  @media print {
    font-size: 2rem;
    justify-content: center;
  }
`;

const Divider = styled(MuiDivider)`
  margin-bottom: 20px;
`;

function PageTitle({ children, filter = null }) {
  return (
    <div>
      <Content>
        <StyledTypography variant="h5">{children}</StyledTypography>
        {filter}
      </Content>
      <Divider className="noprint" />
    </div>
  );
}

PageTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  filter: PropTypes.element,
};

export default PageTitle;
