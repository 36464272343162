import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import Menu from '../Menu';

const Wrapper = styled.div`
  width: ${({ isSidebarOpen }) => (isSidebarOpen ? '258px' : '56px')};
  transition: width 0.2s;
  overflow-x: hidden;
  display: flex;
  height: 100vh;
`;

const FixedComponent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ isSidebarOpen }) => (isSidebarOpen ? '258px' : '56px')};
  transition: width 0.2s;
  overflow-x: hidden;
  display: flex;
  height: 100vh;
`;

function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    localStorage.getItem('sidebar')
      ? JSON.parse(localStorage.getItem('sidebar'))
      : true
  );

  const persistSidebarState = useCallback(() => {
    localStorage.setItem('sidebar', JSON.stringify(isSidebarOpen));
  }, [isSidebarOpen]);

  useEffect(() => {
    window.addEventListener('beforeunload', persistSidebarState);

    return () => {
      window.removeEventListener('beforeunload', persistSidebarState);
    };
  }, [persistSidebarState]);

  return (
    <Wrapper isSidebarOpen={isSidebarOpen}>
      <FixedComponent isSidebarOpen={isSidebarOpen}>
        <Menu
          isSidebarOpen={isSidebarOpen}
          handleOpenSidebar={setIsSidebarOpen}
        />
      </FixedComponent>
    </Wrapper>
  );
}

export default Sidebar;
