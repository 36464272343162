import React from 'react';
import { Bar } from 'recharts';

import HighlightBar from './HighlightBar';

export default function CustomBar({ yAxisId, dataKey, fill, highlights }) {
  if (highlights) {
    return (
      <Bar
        yAxisId={yAxisId}
        dataKey={dataKey}
        fill={fill}
        shape={(props) => <HighlightBar {...props} highlights={highlights} />}
      />
    )
  }

  return (
    <Bar
      yAxisId={yAxisId}
      dataKey={dataKey}
      fill={fill}
    />
  )
}
