import { useState, useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { startOfMonth } from 'date-fns';

import { SensorContext } from '../../utils/context';
import usePageUnavailable from '../../hooks/usePageUnavailable';
import Unavailable from '../../components/Unavailable';
import DonutChart from '../../components/DonutChart';
import ContentWrapper from '../../components/ContentWrapper';
import DateArrow from '../../components/DateArrow';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import DatePicker from '../../components/DatePicker';
import ApplianceBars from '../../components/ApplianceBars';
import CenteredLoader from '../../components/CenteredLoader';
import {
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  MINIMUM_MONTH_ERROR_MESSAGE,
  REPORT_LAST_MONTH_ERROR_MESSAGE,
  SENSOR_ID_PLACEHOLDER,
  NO_DATA_FOUND_ERROR_MESSAGE,
  LOW_RES_APPLIANCES,
} from '../../utils/constants';
import { sortAppliancesDescending } from '../../utils/functions';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

function DisaggregationMonthly() {
  const sensor = useContext(SensorContext);
  const isPageUnavailable = usePageUnavailable();
  const [selectedDate, handleDateChange] = useState(
    moment().subtract(1, 'month').toDate()
  );
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const sensorId = sensor.id || SENSOR_ID_PLACEHOLDER;
  const month = moment(selectedDate).format('MM');
  const year = moment(selectedDate).format('yyyy');

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    if (
      startOfMonth(selectedDate) >
      startOfMonth(moment().subtract(1, 'month').toDate())
    ) {
      setIsValid(false);
      return setValidationError(REPORT_LAST_MONTH_ERROR_MESSAGE);
    }
 
    if (
      sensor.body &&
      sensor.body.data &&
      sensor.body.data.data.first_event &&
      startOfMonth(selectedDate) <
        startOfMonth(moment(sensor.body.data.data.first_event).toDate())
    ) {
      setIsValid(false);
      return setValidationError(
        MINIMUM_MONTH_ERROR_MESSAGE(
          moment(sensor.body.data.data.first_event).format('MMM yyyy')
        )
      );
    }

    setIsValid(true);
    return setValidationError('');
  }, [selectedDate, sensor.id, sensor]);

  const response = useQuery(
    ['disaggregationMonthly', sensor.id, month, year],
    () =>
      axios.get(
        `/sensors/${sensorId}/sm/disag/month?month=${month}&year=${year}`
      ),
    {
      enabled: isValid,
      retry: 0,
    }
  );

  const disagData = useMemo(() => {
    if (!isValid || !response.data?.data?.appliances?.length) {
      return null;
    }

    const appliances = response.data.data.appliances;
    const totalConsumption = response.data.data.total_consumption ?? 0;

    const dataArray = appliances.map((item) => {
      return {
        key: item.appliance_key,
        title: LOW_RES_APPLIANCES[item.appliance_key]?.label ?? item.appliance_key,
        color: LOW_RES_APPLIANCES[item.appliance_key]?.color ?? LOW_RES_APPLIANCES.others.color,
        icon: LOW_RES_APPLIANCES[item.appliance_key]?.icon ?? LOW_RES_APPLIANCES.others.icon,
        consumption: +((item.consumption/1000).toFixed(2)),
        consumptionPercentage: Math.floor(item.consumption/(totalConsumption||1)*100),
      };
    });

    return {
      appliances: sortAppliancesDescending(dataArray),
      totalConsumption: +((totalConsumption/1000).toFixed(2)),
    };
  }, [isValid, response.data]);

  const disableForward = moment().subtract(1, 'month').isSame(selectedDate, 'month');

  if (isValid && isPageUnavailable) {
    return <Unavailable pageName={isPageUnavailable} />
  }

  return (
    <Wrapper>
      <ContentWrapper>
      <PageTitle
          filter={
            <div>
              <DateArrow
                dir='backward'
                disabled={response.isLoading}
                onClick={() => handleDateChange(old => moment(old).subtract(1, 'month').toDate())}
              />
              <DatePicker
                autoOk
                id="date-picker"
                label="Month"
                value={selectedDate}
                onChange={handleDateChange}
                disableFuture
                variant="inline"
                format="MMM yyyy"
                views={['year', 'month']}
                openTo="month"
                error={Boolean(validationError)}
                last="true"
              />
              <DateArrow
                dir='forward'
                disabled={response.isLoading || disableForward}
                onClick={() => handleDateChange(old => moment(old).add(1, 'month').toDate())}
              />
            </div>
          }
        >
          Disaggregation by month
        </PageTitle>
        <Typography paragraph>
          Returns the total consumption by appliances for a specific month.
        </Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {!validationError && response.isSuccess && disagData && (
          <DonutChart data={disagData} dataKey="consumption" margin={{ bottom: 15 }} />
        )}
        {!validationError && response.isSuccess && disagData?.appliances && (
          <ApplianceBars data={disagData.appliances} />
        )}
        {(response.isError || (!validationError && !disagData)) && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${sensorId}/sm/disag/month?month=${month}&year=${year}`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default DisaggregationMonthly;
