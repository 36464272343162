import { Route } from 'react-router-dom';

import Main from './pages/Main';
import AllSensors from './pages/AllSensors';
import SingleSensor from './pages/SingleSensor';
import OverviewDay from './pages/OverviewDay';
import OverviewThirtyMinutes from './pages/OverviewThirtyMinutes';
import BreakdownPeriod from './pages/BreakdownPeriod';
import BreakdownDay from './pages/BreakdownDay';
import LiveConsumption from './pages/LiveConsumption';
import CarbonFootprint from './pages/CarbonFootprint';
import Tariffs from './pages/Tariffs';
import PropertyDetails from './pages/PropertyDetails';
import Appliances from './pages/Appliances';
import ReportMonthly from './pages/ReportMonthly';
import Disaggregation from './pages/Disaggregation';
import DisaggregationDay from './pages/DisaggregationDay';
import DisaggregationPeriod from './pages/DisaggregationPeriod';
import DisaggregationThirtyMinutes from './pages/DisaggregationThirtyMinutes';
import ApplianceCycles from './pages/ApplianceCycles';
import DisaggregationMonthly from './pages/DisaggregationMonthly';
import DisaggregationWeekly from './pages/DisaggregationWeekly';
import VoltaScoreMonthly from './pages/VoltaScoreMonthly';

const getRoutes = (sensors) => [
  <Route path="/" key="/" exact component={Main} />,
  <Route
    path="/sensors"
    key="/sensors"
    render={(props) => <AllSensors {...props} sensors={sensors} />}
  />,
  <Route path="/single-sensor" key="/single-sensor" component={SingleSensor} />,
  <Route
    path="/cost-consumption/overview-day"
    key="/cost-consumption/overview-day"
    component={OverviewDay}
  />,
  <Route
    path="/cost-consumption/overview-thirty-minutes"
    key="/cost-consumption/overview-thirty-minutes"
    component={OverviewThirtyMinutes}
  />,
  <Route
    path="/cost-consumption/breakdown-period"
    key="/cost-consumption/breakdown-period"
    component={BreakdownPeriod}
  />,
  <Route
    path="/cost-consumption/breakdown-day"
    key="/cost-consumption/breakdown-day"
    component={BreakdownDay}
  />,
  <Route
    path="/cost-consumption/live-consumption"
    key="/cost-consumption/live-consumption"
    component={LiveConsumption}
  />,
  <Route
    path="/cost-consumption/carbon-footprint"
    key="/cost-consumption/carbon-footprint"
    component={CarbonFootprint}
  />,
  <Route path="/tariffs" key="/tariffs" component={Tariffs} />,
  <Route
    path="/property-details"
    key="/property-details"
    component={PropertyDetails}
  />,
  <Route path="/appliances" key="/appliances" component={Appliances} />,
  <Route
    path="/reports/monthly"
    key="/reports/monthly"
    component={ReportMonthly}
  />,
  <Route
    path="/disaggregation"
    key="/disaggregation"
    exact
    component={Disaggregation}
  />,
  <Route
    path="/disaggregation/day"
    key="/disaggregation/day"
    component={DisaggregationDay}
  />,
  <Route
    path="/disaggregation/period"
    key="/disaggregation/period"
    component={DisaggregationPeriod}
  />,
  <Route
    path="/disaggregation/thirty-minutes"
    key="/disaggregation/thirty-minutes"
    component={DisaggregationThirtyMinutes}
  />,
  <Route
    path="/disaggregation/cycles"
    key="/disaggregation/cycles"
    component={ApplianceCycles}
  />,
  <Route
    path="/disaggregation/monthly"
    key="/disaggregation/monthly"
    component={DisaggregationMonthly}
  />,
  <Route
    path="/disaggregation/weekly"
    key="/disaggregation/weekly"
    component={DisaggregationWeekly}
  />,
  <Route
    path="/voltascore/monthly"
    key="/voltascore/monthly"
    component={VoltaScoreMonthly}
  />,
];

export default getRoutes;
