import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import MuiRefreshIcon from '@material-ui/icons/Refresh';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAppBar from '@material-ui/core/AppBar';
import MuiMenu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { green, red, grey, orange } from '@material-ui/core/colors';
import { useContext } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import MuiCloseIcon from '@material-ui/icons/Close';
import { useQueryClient } from 'react-query';

import { SensorContext } from '../../utils/context';

import NavigationArrow from '../DateArrow';

const AppBar = styled(MuiAppBar)`
  background: #fff;
  box-shadow: none;

  & > div {
    justify-content: flex-end;

    & button {
      opacity: 0.7;
    }
  }
`;

const Menu = styled(MuiMenu)`
  & > .MuiPaper-root {
    & a,
    li {
      font-size: 0.9em;
      opacity: 0.87;
    }
  }
`;

const Autocomplete = styled(MuiAutocomplete)`
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: 500px;
  }
`;

const StatusIcon = styled.div`
  ${({ status }) => status === 'connected' && `background: ${green[500]};`}
  ${({ status }) => status === 'disconnected' && `background: ${red[500]};`}
  ${({ status }) => status === 'never_connected' && `background: ${grey[500]};`}
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: 0 10px 0 6px;

  @media (max-width: 600px) {
    width: 12px;
    height: 12px;
  }
`;

const StatusContainer = styled(Grid)`
  display: flex;
  align-items: center;
  color: #000;

  @media (max-width: 1380px) {
    margin-bottom: 10px;
  }
`;

const CloseIcon = styled(MuiCloseIcon)`
  margin-right: 5px;
`;

const RefreshButton = styled(IconButton)`
  padding: 8px;
`;
const RefreshIcon = styled(MuiRefreshIcon)`
  color: #444;
`;

const RefreshTooltip = withStyles({
  tooltip: {
    marginTop: '-0.3rem'
  }
})(Tooltip);

const SensorSelection = styled.div`
  display: flex;
  align-items: center;
`;

const SensorType = styled.div`
  display: flex;
  margin: ${({ type }) => type !== 'LOW_RESOLUTION_METER' ? '0 10px' : 0};
`;
const SensorIcon = styled(OfflineBoltIcon)`
  color: ${({ type }) => type !== 'VOLTAWARE_SENSOR' ? orange[600] : green[700]};
  margin-right: 5px;
`;

const sensorLabel = (type) => ({
  VOLTAWARE_SENSOR: 'Voltaware Sensor',
  LOW_RESOLUTION_METER: 'Smart Meter',
  HIGH_RESOLUTION_METER: 'High Resolution Meter',
}[type || 'VOLTAWARE_SENSOR']);
const sensorIdLabel = {
  VOLTAWARE_SENSOR: 'Sensor',
  LOW_RESOLUTION_METER: 'Smart Meter ID',
  HIGH_RESOLUTION_METER: 'Smart Meter ID',
  NONE: '',
};

function Header({
  openMobilebar,
  sensors = [],
  isLoading = false,
  selectSensor,
  resetSensor,
  searching,
  sensorId = null,
}) {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const history = useHistory();

  const sensor = useContext(SensorContext);

  const navigateSensors = useMemo(() => {
    if (sensorId) {
      const id = sensors.findIndex(sensor => sensor === sensorId);

      return {
        prev: sensors[id-1] ?? null,
        next: sensors[id+1] ?? null,
      };
    }

    return {
      prev: null,
      next: null,
    };
  }, [sensorId, sensors]);

  const [powerAnchorEl, setPowerAnchorEl] = useState(null);
  const isPowerMenuOpen = Boolean(powerAnchorEl);
  const handlePowerMenuOpen = (event) => setPowerAnchorEl(event.currentTarget);
  const handlePowerMenuClose = () => {
    setPowerAnchorEl(null);
  };

  const handleLogout = () => {
    history.push('/login');
    queryClient.invalidateQueries({
      queryKey: ['getAllSensors', 'getSingleSensor', 'getStartSensors'],
    });
  };

  const handleSelectSensor = (_, value) => {
    selectSensor(value);
  };

  const parseStatus = (status) => {
    return status.charAt(0).toUpperCase() + status.replace('_', ' ').slice(1);
  };

  return (
    <AppBar position="sticky" className="noprint">
      <Toolbar>
        <Grid container spacing={1} style={{ paddingTop: 10 }} alignItems="center">
          <Grid item>
            {!sm && (
              <IconButton aria-label="menu" onClick={openMobilebar}>
                <MenuIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item>
            <RefreshTooltip
              title="Look for a random connected sensor"
              placement="bottom"
              >
              <div>
                <RefreshButton
                  aria-label="refresh-sensor"
                  disabled={searching}
                  onClick={resetSensor}>
                  <RefreshIcon />
                </RefreshButton>
              </div>
            </RefreshTooltip>
          </Grid>
          <Grid item>
            <SensorSelection>
              <NavigationArrow
                dir='backward'
                disabled={!sensorId || !Boolean(navigateSensors.prev)}
                onClick={() => selectSensor(navigateSensors.prev)}
                color="#444"
              />
              <div>
                <Autocomplete
                  id="sensor"
                  options={sensors}
                  getOptionLabel={(option) => option}
                  getOptionSelected={(option, _) => option}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label={searching ? 'Searching...' : sensorIdLabel[sensor?.type || 'NONE']} variant="outlined" />
                  )}
                  disableClearable
                  onChange={handleSelectSensor}
                  disabled={isLoading || searching}
                  value={sensorId}
                />
              </div>
              <NavigationArrow
                dir='forward'
                disabled={!sensorId || !Boolean(navigateSensors.next)}
                onClick={() => selectSensor(navigateSensors.next)}
                color="#444"
              />
            </SensorSelection>
          </Grid>
          {sensor.body.isLoading && (
            <Skeleton width={sm ? 170 : 30} height={30} animation="wave" />
          )}
          {sensor.body.isSuccess && (
            <StatusContainer item>
              {sensor.type !== 'LOW_RESOLUTION_METER' && (
              <>
                <StatusIcon status={sensor.body.data.data.connection_state} />
                {sm && (
                  <Typography>
                    {parseStatus(
                      sensor.body.isSuccess &&
                        sensor.body.data.data.connection_state
                    )}
                  </Typography>
                )}
              </>
              )}
              <SensorType type={sensor.type} title={sensorLabel(sensor.type)}>
                <SensorIcon type={sensor.type} />
                {sm && (
                  <Typography>
                    {sensorLabel(sensor.type)}
                  </Typography>
                )}
              </SensorType>
            </StatusContainer>
          )}
          {sensor.body.isIdle && (
            <StatusContainer item>
              {sm && <Typography>Select a sensor</Typography>}
            </StatusContainer>
          )}
          {sensor.body.isError && (
            <StatusContainer item>
              <CloseIcon />
              {sm && <Typography>Device not found</Typography>}
            </StatusContainer>
          )}
        </Grid>
        <IconButton
          aria-label="power menu"
          aria-controls="power-menu"
          aria-haspopup="true"
          onClick={handlePowerMenuOpen}
        >
          <PowerSettingsNewIcon />
        </IconButton>
      </Toolbar>
      <Menu
        anchorEl={powerAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="power-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isPowerMenuOpen}
        onClose={handlePowerMenuClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </AppBar>
  );
}

Header.propTypes = {
  openMobilebar: PropTypes.func.isRequired,
  sensors: PropTypes.array,
  isLoading: PropTypes.bool,
  selectSensor: PropTypes.func.isRequired,
  sensorId: PropTypes.string,
};

export default Header;
