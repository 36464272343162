import { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { SensorContext } from '../../utils/context';
import usePageUnavailable from '../../hooks/usePageUnavailable';
import Unavailable from '../../components/Unavailable';
import ContentWrapper from '../../components/ContentWrapper';
import PageTitle from '../../components/PageTitle';
import JsonTab from '../../components/JsonTab';
import axios from '../../api/setup';
import CenteredLoader from '../../components/CenteredLoader';
import GaugeChart from '../../components/GaugeChart';
import {
  SENSOR_NOT_SELECTED_ERROR_MESSAGE,
  SENSOR_ID_PLACEHOLDER,
  NO_DATA_FOUND_ERROR_MESSAGE,
} from '../../utils/constants';
import houseAlwaysOn from '../../images/house-alwaysOn.gif';
import houseNormal from '../../images/house-normal.gif';
import houseExcess from '../../images/house-excess.gif';

import AlwaysOn from '../../images/alwaysOn.svg';
import Normal from '../../images/normal.svg';
import Excess from '../../images/excess.svg';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const CardContainer = styled(Grid)`
  margin-bottom: 10px;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  min-height: 145px;
`;

const LegendWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const LegendGroup = styled.div`
  display: flex;
  margin-right: 20px;
  & > img {
    margin-right: 5px;
  }
`;

const ImageContainer = styled.div`
  width: 210px;
  min-height: 132px;

  & > img {
    width: 100%;
  }
`;

function LiveConsumption() {
  const sensor = useContext(SensorContext);
  const isPageUnavailable = usePageUnavailable();
  const [validationError, setValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (!sensor.id) {
      setIsValid(false);
      return setValidationError(SENSOR_NOT_SELECTED_ERROR_MESSAGE);
    }

    setIsValid(true);
    return setValidationError('');
  }, [sensor.id, sensor]);

  const response = useQuery(
    ['liveConsumption', sensor.id],
    () => axios.get(`/sensors/${id}/stats/live`),
    {
      enabled: isValid,
      retry: 0,
      refetchInterval: 5000,
    }
  );

  const id = sensor.id || SENSOR_ID_PLACEHOLDER;

  const getThresholds = () => [
    0,
    response.data.data.categories.alwaysOn.threshold.power,
    response.data.data.categories.normal.threshold.power,
    response.data.data.categories.excess.threshold.power,
  ];

  const getHouseStatus = () => {
    const consumption = response.data.data.consumption.actualRaw;
    const alwaysOnThreshold =
      response.data.data.categories.alwaysOn.threshold.power;
    const normalThreshold =
      response.data.data.categories.normal.threshold.power;

    if (consumption <= alwaysOnThreshold) {
      return 'alwaysOn';
    } else if (consumption <= normalThreshold) {
      return 'normal';
    } else {
      return 'excess';
    }
  };

  if (isValid && isPageUnavailable) {
    return <Unavailable pageName={isPageUnavailable} />
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <PageTitle>Live consumption</PageTitle>
        <Typography paragraph>View current live consumption values.</Typography>
        {validationError && (
          <Typography paragraph color="error">
            {validationError}
          </Typography>
        )}
        {response.isLoading && <CenteredLoader />}
        {response.isSuccess && (
          <LegendWrapper>
            <LegendGroup>
              <img src={AlwaysOn} alt="test" style={{ width: '20px' }} /> asleep
            </LegendGroup>
            <LegendGroup>
              <img src={Normal} alt="test" style={{ width: '20px' }} /> awake
            </LegendGroup>
            <LegendGroup>
              <img
                src={Excess}
                alt="test"
                style={{ width: '20px', height: '20px' }}
              />{' '}
              busy
            </LegendGroup>
          </LegendWrapper>
        )}
        {response.isSuccess && (
          <CardContainer container spacing={2}>
            <Grid item xs={12} sm={6}>
              <GaugeChart
                value={response.data.data.consumption.actualRaw}
                percentage={response.data.data.percentage}
                thresholds={getThresholds()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledPaper>
                <ImageContainer>
                  {getHouseStatus() === 'alwaysOn' && (
                    <img src={houseAlwaysOn} alt="House always on" />
                  )}
                  {getHouseStatus() === 'normal' && (
                    <img src={houseNormal} alt="House normal" />
                  )}
                  {getHouseStatus() === 'excess' && (
                    <img src={houseExcess} alt="House excess" />
                  )}
                </ImageContainer>
                {getHouseStatus() === 'alwaysOn' && (
                  <Typography>Your house is asleep</Typography>
                )}
                {getHouseStatus() === 'normal' && (
                  <Typography>Your house is awake</Typography>
                )}
                {getHouseStatus() === 'excess' && (
                  <Typography>Your house is busy</Typography>
                )}
              </StyledPaper>
            </Grid>
          </CardContainer>
        )}
        {response.isError && (
          <Typography paragraph color="error">
            {NO_DATA_FOUND_ERROR_MESSAGE}
          </Typography>
        )}
      </ContentWrapper>
      <JsonTab
        endpoint={`GET /sensors/${id}/stats/live`}
        response={response.data && response.data.data}
        isLoading={response.isLoading}
        isError={response.isError}
        isSuccess={response.isSuccess}
        isIdle={response.isIdle}
      />
    </Wrapper>
  );
}

export default LiveConsumption;
